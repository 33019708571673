import { TextSelection } from "@tiptap/pm/state";
import {
  BubbleMenu as BubbleMenuReact,
  Editor,
  isNodeSelection,
} from "@tiptap/react";
import { RefObject } from "react";
import { AppExtensions } from "../TextEditor";
import { SelectionMenu } from "./SelectionMenu";

export type SelectionMenuType = "link" | null | "reviseMark" | "color";

export interface BubbleMenuProps {
  editor: Editor;
  containerRef: RefObject<HTMLDivElement>;
  appExtensions: AppExtensions[];
  readOnly: boolean;
}

export const BubbleMenu = ({
  editor,
  containerRef,
  appExtensions,
  readOnly,
}: BubbleMenuProps) => {
  const onClose = () => {
    editor.chain().focus().setTextSelection(-1);
  };

  if (
    !editor ||
    !containerRef.current ||
    !(editor.state.selection instanceof TextSelection) ||
    readOnly
  )
    return (
      <BubbleMenuReact
        pluginKey="bubble-menu"
        editor={editor}
        className="bubble-menu"
        tippyOptions={{
          appendTo: containerRef.current,
        }}
      >
        <></>
      </BubbleMenuReact>
    );

  return (
    <BubbleMenuReact
      pluginKey="bubble-menu"
      editor={editor}
      className="bubble-menu"
      tippyOptions={{
        appendTo: containerRef.current,
        moveTransition: "transform 0.15s ease-out",
      }}
      shouldShow={({ state, editor }) => {
        const { selection } = state;
        const { empty } = selection;

        // don't show bubble menu if:
        // - the selected node is an image
        // - the selection is empty
        // - the selection is a node selection (for drag handles)
        if (editor.isActive("image") || empty || isNodeSelection(selection)) {
          return false;
        }
        return true;
      }}
    >
      <SelectionMenu
        editor={editor}
        appExtensions={appExtensions}
        onClose={onClose}
      />
    </BubbleMenuReact>
  );
};
