import { Grid, Inbox, List, Star } from "lucide-react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuthStore } from "../../store/auth";
import { Button } from "../../ui-components/Button";
import { LucideIcon } from "../../ui-components/LucideIcon";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../ui-components/Tabs";
import { APP_PAGE_HEIGHT } from "../../utils/constants";
import { cn } from "../../utils/utils";
import { TNotesList } from "../api/fetchNotes";
import { NotesGridRenderer, NotesListRenderer } from "../global-helpers";
import {
  TNotesResponseWithChildren,
  transformHeirarchicalNotesInFlatList,
  useNotesList,
} from "../hooks/queries/useNotesList";
import { constructUrlForNote } from "../notes-sidebar/services";

export enum ViewType {
  GRID = "GRID",
  LIST = "LIST",
}

export const ViewTypeMap = {
  [ViewType.LIST]: {
    label: "list",
    Icon: <List size={14} />,
  },
  [ViewType.GRID]: {
    label: "grid",
    Icon: <Grid size={14} />,
  },
};

export const FavoriteNotes = () => {
  const { userId } = useAuthStore();
  const [viewType, setViewType] = useState<keyof typeof ViewTypeMap>(
    ViewType.LIST
  );

  const history = useHistory();

  const {
    data: notes = [],
    isLoading: isLoadingNotes,
    isError: isErrorNotes,
  } = useNotesList({ userId });

  if (isLoadingNotes) return <StarredNotesLoader />;

  const starredNotes = transformHeirarchicalNotesInFlatList({
    notesResponse: notes,
  }).filter((note) => note.pinned);

  if (isErrorNotes || !starredNotes.length)
    return (
      <div
        className={cn(
          "grid place-items-center justify-center",
          APP_PAGE_HEIGHT
        )}
      >
        <div className="flex flex-col gap-y-1.5 items-center text-center">
          <LucideIcon
            icon={Inbox}
            size={64}
            strokeWidth="1px"
            className="text-text-tertiary-light dark:text-text-tertiary-dark"
          />
          <p className="text-textColors-muted-light dark:text-textColors-muted-dark w-48">
            No favorites
          </p>
        </div>
      </div>
    );

  return (
    <div className="flex flex-col gap-y-4">
      <Tabs defaultValue={ViewType.LIST}>
        <div className="flex flex-row items-start justify-between">
          <div className="flex flex-row items-center gap-x-1">
            <div className="flex flex-col">
              <div className="text-xl font-medium">Favorites</div>
              <div className="text-textColors-muted-light dark:text-textColors-muted-dark text-sm">
                View and manage your favorites
              </div>
            </div>
          </div>
          <TabsList className="flex flex-row gap-x-2 h-auto py-0.5">
            {Object.keys(ViewTypeMap).map((view) => {
              const isActive = viewType === view;

              return (
                <TabsTrigger
                  key={view}
                  className="p-0"
                  value={view}
                  onClick={() => setViewType(view as keyof typeof ViewTypeMap)}
                >
                  <Button
                    key={view}
                    variant={isActive ? "outline" : "ghost"}
                    size="icon"
                    className={`w-6 h-6`}
                    onClick={() =>
                      setViewType(view as keyof typeof ViewTypeMap)
                    }
                  >
                    {ViewTypeMap[view as keyof typeof ViewTypeMap].Icon}
                  </Button>
                </TabsTrigger>
              );
            })}
          </TabsList>
        </div>
        <TabsContent value={ViewType.LIST} className="mt-3.5">
          <NotesListRenderer
            notes={starredNotes}
            handleNoteClick={({ note }: { note: TNotesList[0] }) => {
              history.push(
                constructUrlForNote({
                  note: note as unknown as TNotesResponseWithChildren,
                })
              );
            }}
          />
        </TabsContent>
        <TabsContent value={ViewType.GRID} className="mt-4">
          <NotesGridRenderer
            notes={starredNotes}
            handleNoteClick={({ note }: { note: TNotesList[0] }) => {
              history.push(
                constructUrlForNote({
                  note: note as unknown as TNotesResponseWithChildren,
                })
              );
            }}
          />
        </TabsContent>
      </Tabs>
    </div>
  );
};

const StarredNotesLoader = () => {
  return (
    <div className="p-4 flex flex-col gap-y-4">
      <div className="flex flex-row gap-x-4 items-center">
        <div className="flex flex-row items-center gap-x-1">
          <Star size={16} />
          <div className="text-md">Favorites</div>
        </div>
        <div className="bg-bg-tertiary-light dark:bg-bg-tertiary-dark w-6 h-6 rounded-lg"></div>
      </div>
      <div>
        {Array(3)
          .fill(0)
          .map((_, i) => {
            return (
              <div className="animate-pulse">
                <div className="flex flex-row gap-x-6 px-1 py-2 rounded-sm items-center">
                  <div className="bg-bg-tertiary-light dark:bg-bg-tertiary-dark w-6 h-6 rounded-full"></div>
                  <div className="flex flex-row justify-between w-full">
                    <div className="h-4 bg-bg-tertiary-light dark:bg-bg-tertiary-dark w-3/5 rounded-sm"></div>
                    <div className="h-4 bg-bg-tertiary-light dark:bg-bg-tertiary-dark w-1/5 rounded-sm"></div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
