export function checkCollisionAndReposition() {
  const badges = document.querySelectorAll(".reflect-marker");

  let badgesGroupedByTop: Record<number, Set<HTMLElement>> = {};

  (badges as NodeListOf<HTMLElement>).forEach(
    (badge: HTMLElement, index: number) => {
      const currentRect = badge.getBoundingClientRect();

      // Check against all previous badges
      for (let i = 0; i < index; i++) {
        const previousBadge = badges[i] as HTMLElement;
        const previousRect = previousBadge.getBoundingClientRect();

        // Check if the current badge overlaps with the previous one
        if (previousRect.top === currentRect.top) {
          const topKey = currentRect.top;

          // Initialize a new set for badges with the same top value if not present
          if (!badgesGroupedByTop[topKey]) {
            badgesGroupedByTop[topKey] = new Set();
          }

          // Add the current and previous badges to the group (avoid duplicates with Set)
          badgesGroupedByTop[topKey].add(badge);
          badgesGroupedByTop[topKey].add(previousBadge);
        }
      }
    }
  );

  // Adjust positions for badges that share the same top value
  Object.entries(badgesGroupedByTop).forEach(([topKey, badgeSet]) => {
    const badgesArray = Array.from(badgeSet);
    badgesArray.forEach((badge, index) => {
      // Offset the badges horizontally to prevent overlap
      (badge as HTMLElement).style.left = `${0 + index * 0.9}rem`;
    });
  });
}
