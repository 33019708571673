import { zodResolver } from "@hookform/resolvers/zod";
import { Loader } from "lucide-react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import * as z from "zod";
import { AvatarSelect } from "../../components/visual/AvatarSelect";
import { useAuthStore } from "../../store/auth";
import { Button } from "../../ui-components/Button";
import { Input } from "../../ui-components/Input";
import { Label } from "../../ui-components/Label";
import { Textarea } from "../../ui-components/Textarea";
import { useUpdateUser } from "../hooks/mutations/useUpdateUser";
import { useSupabaseUser } from "../hooks/queries/useSupabaseUser";
import { useUser } from "../hooks/queries/useUser";
import { getUserQueryKey } from "../hooks/query-keys";

const profileFormSchema = z.object({
  username: z
    .string()
    .min(2, {
      message: "Username must be at least 2 characters.",
    })
    .max(30, {
      message: "Username must not be longer than 30 characters.",
    }),
  bio: z.string().max(160).optional(),
  url: z
    .string()
    .optional()
    .refine((url) => {
      if (url) {
        return url.startsWith("http://") || url.startsWith("https://");
      }
      return true;
    }),
  avatar: z.string(),
});

type ProfileFormValues = z.infer<typeof profileFormSchema>;

// This can come from your database or API.
const defaultValues: Partial<ProfileFormValues> = {
  bio: "",
  url: "",
};

export function ProfileForm() {
  const {
    data: { user: supabaseUser } = { supabaseUser: null },
    isLoading: isLoadingUser,
  } = useSupabaseUser();

  const { data: [user] = [] } = useUser({
    userId: supabaseUser?.id,
  });

  const form = useForm<ProfileFormValues>({
    resolver: zodResolver(profileFormSchema),
    defaultValues,
    mode: "onChange",
  });

  const { mutateAsync: updateUser, isLoading: isUpdatingUser } = useUpdateUser({
    userId: supabaseUser?.id,
  });

  const {
    handleSubmit,
    register,
    setValue,
    // formState: { errors },
  } = form;
  const { username, bio, url, avatar } = form.watch();

  const queryClient = useQueryClient();
  const { userId } = useAuthStore();

  useEffect(() => {
    if (user) {
      setValue("username", user.username);
      setValue("bio", user.bio);
      setValue("url", user.url);
      setValue("avatar", user.avatar);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  async function onSubmit(data: ProfileFormValues) {
    const { username, bio, url, avatar } = data;

    await updateUser({
      id: supabaseUser?.id,
      username,
      bio,
      url,
      avatar,
    });

    queryClient.refetchQueries(getUserQueryKey({ userId }));
  }

  if (isLoadingUser)
    return (
      <div className="flex flex-col gap-y-48 h-full">
        <div className="flex-1 lg:max-w-2xl">
          <h2 className="text-2xl font-bold tracking-tight">Preferences</h2>
          <p className="text-textColors-muted-light dark:text-textColors-muted-dark">
            Manage your preferences.
          </p>
        </div>
        <Loader
          size={16}
          className="animate-spin transition-all duration-1200 mx-auto"
        />
      </div>
    );

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-row items-center flex-wrap gap-4">
          <div className="flex-1 lg:max-w-2xl">
            <h2 className="text-2xl font-bold tracking-tight">Preferences</h2>
            <p className="text-textColors-muted-light dark:text-textColors-muted-dark">
              Manage your preferences.
            </p>
          </div>
        </div>
        <div className="relative flex flex-col gap-y-8 mt-6 p-6 md:pr-32 md:mr-20 pb-24 border border-border-primary-light dark:border-border-primary-dark rounded-md">
          <Button
            type="submit"
            loading={isUpdatingUser}
            className="absolute top-4 right-4"
            variant="outline"
          >
            Save changes
          </Button>
          <div className="flex flex-col gap-y-4 items-left">
            <Label htmlFor="username">Change Avatar</Label>
            <AvatarSelect
              value={avatar}
              onSelect={(avatar) => setValue("avatar", avatar)}
            />
          </div>
          <div className="flex flex-col gap-y-2 items-left">
            <Label htmlFor="username">Change Username</Label>
            <Input
              id="username"
              placeholder="Your username"
              className="col-span-3 w-72"
              value={username}
              name="username"
              {...register("username", { required: true })}
            />
          </div>
          <div className="flex flex-col gap-y-2 items-left">
            <Label htmlFor="username">Email</Label>
            <Input
              id="username"
              placeholder="Your username"
              className="col-span-3"
              value={supabaseUser?.email}
              name="username"
              disabled
            />
          </div>
          <div className="flex flex-col gap-y-2 items-left">
            <Label htmlFor="bio">Add bio</Label>
            <Textarea
              id="bio"
              placeholder="Add bio"
              className="col-span-3"
              value={bio}
              name="bio"
              {...register("bio")}
            />
          </div>
          <div className="flex flex-col gap-y-2 items-left">
            <Label htmlFor="url">Add website</Label>
            <p className="text-sm text-textColors-muted-light dark:text-textColors-muted-dark -mt-1">
              Add links to your website, blog, or social media profiles.
            </p>
            <Input
              id="url"
              placeholder="Add url"
              className="col-span-3"
              value={url}
              name="url"
              {...register("url")}
            />
          </div>
        </div>
      </form>
    </div>
  );
}
