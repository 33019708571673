import { User } from "lucide-react";
import { BASE_URL } from "../pages-v2/NotesDashboardWrapper";
import { PersistedNotes } from "../pages-v2/types";
import { Button } from "../ui-components/Button";
import { LucideIcon } from "../ui-components/LucideIcon";
import { AvatarsMap } from "./constants";
import { cn } from "./utils";

export const isNotesPath = (path: string) => {
  return path.startsWith(`${BASE_URL}/notes`);
};

export const getCurrentNoteFromStorage = ({
  storage,
}: {
  storage: PersistedNotes;
}): PersistedNotes["currentNote"] => {
  if (storage && storage.currentNote) {
    return storage.currentNote;
  }

  return null;
};

export const getLastNoteFromStorage = ({
  storage,
}: {
  storage: PersistedNotes;
}): PersistedNotes["lastNote"] => {
  if (storage && storage.lastNote) {
    return storage.lastNote;
  }

  return null;
};

export const getNewCurrentNoteInStorage = ({
  storage,
  newCurrentNote,
}: {
  storage: string;
  newCurrentNote: PersistedNotes;
}) => {
  const parsedStorage = JSON.parse(storage || `{}`);

  parsedStorage.currentNote = newCurrentNote;

  return JSON.stringify(parsedStorage);
};

export const getAvatarIcon = ({
  className,
  avatar,
}: {
  className: string;
  avatar: keyof typeof AvatarsMap;
}) => {
  return (
    <Button
      variant="link"
      size="icon"
      className={cn("w-10 h-10 rounded-full grayscale", className)}
    >
      {AvatarsMap[avatar] ?? <LucideIcon icon={User} size={14} />}
    </Button>
  );
};

export const injectTextInEditorContent = ({ text }: { text: string }) => {
  return JSON.stringify({
    type: "doc",
    content: [
      {
        type: "paragraph",
        attrs: { indent: null },
        content: [{ type: "text", text }],
      },
    ],
  });
};
