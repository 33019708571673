import { useColorMode, useColorModeValue } from "@chakra-ui/react";
import { ArrowBigUp, ArrowRight, ChevronUp, Moon, Sun } from "lucide-react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { useSupabaseSession } from "../pages-v2/hooks/queries/useSupabaseSession";
import { APP_NAME, BASE_URL } from "../pages-v2/NotesDashboardWrapper";
import { useAuthStore } from "../store/auth";
import { Button } from "../ui-components/Button";
import { LucideIcon } from "../ui-components/LucideIcon";
import { ShortcutKey } from "../ui-components/Shortcut";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui-components/Tooltip";
import Logo from "../utils/logo";
import { cn } from "../utils/utils";

export const RootLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex flex-col h-screen overflow-auto px-2 md:p-0">
      <Navbar />
      <main className={cn("h-screen")}>
        {children} <Footer />
      </main>
    </div>
  );
};

const Footer = () => {
  const router = useHistory();
  return (
    <section
      id="footer"
      className="border-t border-border-secondary-light dark:border-border-secondary-dark px-4 md:px-14 py-6 dark:bg-transparent flex justify-between items-start"
    >
      <div className="flex flex-row gap-4 items-center">
        <div className="flex flex-col gap-6">
          <div className="flex flex-row items-center gap-2 capitalize">
            <Logo /> {APP_NAME}
          </div>
          <span className="text-sm text-textColors-muted-light dark:text-textColors-muted-dark">
            © 2024. All rights reserved.
          </span>
        </div>
      </div>
      <div className="flex flex-row flex-wrap gap-6">
        <Button variant="hoverLink" onClick={() => router.push("/")}>
          Home
        </Button>
        <Button variant="hoverLink" onClick={() => router.push("/blog")}>
          Blog
        </Button>
        <Button variant="hoverLink" onClick={() => router.push("/privacy")}>
          Privacy
        </Button>
      </div>
    </section>
  );
};

const Navbar = () => {
  const history = useHistory();
  const location = useLocation();
  const { userId } = useAuthStore();

  const showLogin =
    history.location.pathname === "/" ||
    history.location.pathname.startsWith("/blog") ||
    history.location.pathname === "/privacy";

  const { data: session } = useSupabaseSession();

  const { toggleColorMode } = useColorMode();
  const mode = useColorModeValue("light", "dark");

  const hasSession = Boolean(userId) && Boolean(session?.session);

  if (location.hash.startsWith("#access_token")) {
    return (
      <Redirect
        to={{
          pathname: "/complete-profile",
          hash: location.hash,
          state: {
            access_token: new URLSearchParams(location.hash).get(
              "#access_token"
            ),
          },
        }}
      />
    );
  }

  return (
    <>
      <header className="ease-in-out mx-auto max-w-[var(--page-width)] w-full px-[1.25rem] md:px-[1.875rem] lg:px-0 flex flex-row items-center justify-between py-4 border-b-border-secondary-light dark:border-b-border-secondary-dark">
        <div className="flex flex-row items-center gap-x-2">
          <Logo onClick={() => history.push(`/`)} className="hidden md:flex" />
          <span className="text-textColors-background-light lowercase dark:text-textColors-background-dark font-medium tracking-tight sm:text-md text-lg">
            {APP_NAME}
          </span>
        </div>

        <div className="flex flex-row items-center space-x-4">
          <TooltipProvider>
            <Tooltip defaultOpen={false}>
              <TooltipTrigger asChild onFocus={(e) => e.preventDefault()}>
                <Button
                  variant="hoverLink"
                  size="icon"
                  onClick={() => {
                    toggleColorMode();
                  }}
                >
                  {mode === "dark" ? (
                    <LucideIcon icon={Sun} size={14} />
                  ) : (
                    <LucideIcon icon={Moon} size={14} />
                  )}
                </Button>
              </TooltipTrigger>
              <TooltipContent
                className={cn(
                  "flex flex-row gap-2 items-center bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark",
                  "text-textColors-background-light dark:text-textColors-background-dark",
                  "border border-border-primary-light/80 dark:border-border-primary-dark/80"
                )}
              >
                Switch theme
                <div className="flex items-center gap-1">
                  <ShortcutKey tooltipLabel="ctrl">
                    <ChevronUp size={14} className="stroke-[#999999]" />
                  </ShortcutKey>
                  <ShortcutKey tooltipLabel="shift">
                    <ArrowBigUp size={14} className="stroke-[#999999]" />
                  </ShortcutKey>
                  <ShortcutKey tooltipLabel="T">T</ShortcutKey>
                </div>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <Button
            variant="link"
            className={cn(
              "font-medium hover:no-underline",
              "text-textColors-secondary-light dark:text-textColors-secondary-dark hover:text-textColors-background-light dark:hover:text-textColors-background-dark"
            )}
            onClick={() => {
              history.push("/blog");
            }}
          >
            Blog
          </Button>
          {hasSession && showLogin ? (
            <Button
              className="gap-1 text-[14px]"
              variant="outline"
              size="sm"
              onClick={() => {
                history.push(`${BASE_URL}/notes`);
              }}
            >
              Continue
              <LucideIcon icon={ArrowRight} />
            </Button>
          ) : (
            <Button
              variant="outline"
              onClick={() => {
                history.push("/login");
              }}
            >
              Log in
            </Button>
          )}
        </div>
      </header>
    </>
  );
};
