import { useQuery } from "react-query";
import { fetchMistralModels } from "./fetchMistralModels";

export type MistralModel = {
  id: string;
  object: "model";
  created: 0;
  owned_by: "mistralai";
  name: string;
  description: string;
  max_context_length: number;
  aliases: [];
  deprecation: string;
  capabilities: {
    completion_chat: boolean;
    completion_fim: boolean;
    function_calling: boolean;
    fine_tuning: boolean;
    vision: boolean;
  };
  type: string;
};

export const useMistralModelsList = ({
  apiKey,
  enabled,
}: {
  apiKey: string;
  enabled: boolean;
}) => {
  return useQuery(
    ["mistral-models", apiKey],
    () => fetchMistralModels({ apiKey }),
    {
      enabled: !!apiKey && enabled,
      select: (data: { data: MistralModel[] }) => data.data,
    }
  );
};
