import { useMutation, useQueryClient } from "react-query";
import { useAuthStore } from "../../../store/auth";
import { updateIntegration } from "../../api/updateIntegration";
import { AIProviders } from "../ai/useFetchAiCompletions";
import { getIntegrationsQueryKey } from "../query-keys";

type IntegrationPayload = {
  metadata: Record<string, string> & {
    integrationType: (typeof AIProviders)[keyof typeof AIProviders];
  };
  secret: string;
  id: number;
};

export const useUpdateIntegration = () => {
  const { userId } = useAuthStore();
  const queryClient = useQueryClient();

  return useMutation(
    (
      payload: Partial<IntegrationPayload> & {
        id: number;
      }
    ) => {
      return updateIntegration({
        user_id: userId,
        ...payload,
      });
    },
    {
      onSettled: () => {
        queryClient.refetchQueries(getIntegrationsQueryKey({ userId }));
      },
    }
  );
};
