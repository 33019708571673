import { Info } from "lucide-react";
import { RootLayout } from "../../layouts/Root.layout";
import { LucideIcon } from "../../ui-components/LucideIcon";
import { Separator } from "../../ui-components/Separator";
import { Prose } from "../../ui-components/templates/Prose";

export const Privacy = () => {
  return (
    <RootLayout>
      <div className="flex flex-col items-center py-12">
        <Prose classNames="[&>*]:leading-6">
          <h3>Privacy</h3>
          <p>
            Please read this Privacy Policy to learn how we treat your personal
            data. By using or accessing our Services in any manner, you
            acknowledge that you accept the practices and policies outlined
            below, and you hereby consent that we will collect, use and share
            your information as described in this Privacy Policy.
          </p>
          <Separator className="mt-12" />
          <h3>Personal Data</h3>
          <div className="flex flex-col gap-12">
            <div>
              <h5>Registration information</h5>
              We collect personal and/or business information that you provide
              when you register for an account on the Site.
              <ul>
                <li>
                  This information may include your <strong>name</strong>,{" "}
                  <strong>email address</strong>,{" "}
                  <strong>GitHub username</strong>,{" "}
                  <strong>Google email</strong>. We use this information to
                  administer your account, provide you with the relevant
                  services and information, communicate with you regarding your
                  account, the Site.{" "}
                </li>
                <li>
                  We do not also use this information to send you marketing
                  communications, promotional messages, and other information.
                  We take measures to ensure that only you can access your API
                  keys.
                </li>
              </ul>
            </div>
            <div>
              <h5>AI</h5>
              If you have integrated the AI feature, then we will require your
              <strong>OpenAI API key</strong> which we store in our database. We
              will use this key to make requests to the OpenAI API from your
              dashboard and not for any other purposes.
            </div>
            <div>
              <h5>Communication</h5>
              We only send emails to the registered account for password reset
              and account recovery. We do not send any marketing emails or
              promotional messages.
            </div>
            <div>
              <h5>Product usage and Logging</h5> We collect product usage data
              through <strong>Posthog</strong> and monitor the usage for bugs or
              errors.
              <br />
              <div className="mt-4">
                <span>
                  <LucideIcon icon={Info} className="-mt-1" /> We do not collect
                  any personal information through this process and all the
                  sessions are anonymous.
                </span>
              </div>
            </div>
            <div>
              All of your notes and data are stored in our database and are not
              shared with any third-party services. We do not use your data for
              any other purposes other than to provide you with the services we
              offer.
            </div>
          </div>
          <Separator className="my-12" />
          <h5>Payment information</h5>
          <p>
            Envise is a side project and we do not charge for the services we
            provide or collect any payment information from you.
          </p>
          <section>
            <Separator className="my-12" />
            <h5>Team</h5>
            <p>
              Envise is created by a team of two developers,{" "}
              <a
                href="https://github.com/aquibbaig"
                target="_blank"
                rel="noreferrer"
              >
                Aquib Baig{" "}
              </a>
              and{" "}
              <a
                href="https://github.com/ani-sha"
                target="_blank"
                rel="noreferrer"
              >
                Anisha Mohanty
              </a>
              .
            </p>
            <p>
              We are both proffessional developers and are passionate about
              building products that help people learn and grow. We are always
              looking for feedback and suggestions to improve the platform. If
              you have any feedback or suggestions, please feel free to reach
              out to us at <strong>enviseapp@gmail.com</strong>.
            </p>
          </section>
        </Prose>
      </div>
    </RootLayout>
  );
};
