import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { useDebounce } from "../../../hooks/utility/useDebounce";
import { TNotesList } from "../../../pages-v2/api/fetchNotes";
import { convertLabelToId } from "../../../pages-v2/global-helpers";
import { useUpdateNote } from "../../../pages-v2/hooks/mutations/useUpdateNote";
import { getNoteDetailsQueryKey } from "../../../pages-v2/hooks/queries/useNoteDetails";
import { useAuthStore } from "../../../store/auth";
import { AppExtensions, TextEditor } from "../../../text-editor/TextEditor";
import { AccessType } from "../../../utils/constants";
import { getAvatarIcon } from "../../../utils/helpers";
import { DAYJS_DEFAULT_FORMAT } from "../../../utils/utils";
import { Separator } from "../../Separator";
import { Prose } from "../Prose";
import { checkCollisionAndReposition } from "./helper";
import { ReflectCards } from "./reflect-cards/ReflectCards";
import { TOC } from "./TOC";

export const BasicTemplate = ({ note }: { note: TNotesList[0] }) => {
  const { userId } = useAuthStore();
  const accessType =
    userId === note.users.id ? AccessType.EDIT : AccessType.READ;

  const [noteContentState, setNoteContentState] = useState(note?.body);
  const newNoteState = useDebounce(noteContentState, 500);

  const queryClient = useQueryClient();

  const { mutate: updateNote } = useUpdateNote({
    showToast: false,
    onSuccess: false,
  });

  const editorRef = useRef();

  useEffect(() => {
    const repositionBadges = () => {
      checkCollisionAndReposition();
    };

    const animationFrameId = requestAnimationFrame(repositionBadges);

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [note.body]);

  useEffect(() => {
    if (newNoteState && typeof newNoteState === "string") {
      updateNote(
        {
          body: newNoteState,
          id: note.id,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(
              getNoteDetailsQueryKey({
                noteId: note.id,
              })
            );
          },
        }
      );
    }

    // refetch reflections
    queryClient.invalidateQueries(["reflections"]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newNoteState]);

  // const noteReadingTime = readingTime(note.body).minutes || 0

  return (
    <div className="lg:grid grid-cols-9 px-2 md:p-10">
      <Prose classNames="flex flex-col gap-y-2 col-span-7 min-w-full lg:px-8 2xl:px-24 pb-8">
        <div className="flex flex-col mb-4">
          <h2
            className="tracking-tight m-0 items-center gap-x-1 flex flex-wrap font-bold !text-2xl"
            id={convertLabelToId({ label: note.title })}
          >
            {note.title}{" "}
          </h2>
          <span>{note.description}</span>
          <div className="mt-6 flex flex-row flex-wrap items-center gap-8 md:gap-16 justify-between">
            <div className="flex flex-row items-center flex-wrap gap-8">
              {note.users.name && (
                <div className="flex flex-col">
                  <span className="text-sm text-textColors-muted-light dark:text-textColors-muted-dark">
                    Written by
                  </span>

                  <div className="flex flex-row items-center">
                    <span>
                      {getAvatarIcon({
                        avatar: note.users.avatar as any,
                        className: "size-6 [&>svg]:size-4 mt-0.5",
                      })}
                    </span>
                    {note.users.name}
                  </div>
                </div>
              )}
              {note.created_at && (
                <div className="flex flex-col">
                  <span className="text-sm text-textColors-muted-light dark:text-textColors-muted-dark">
                    Published on
                  </span>
                  <div>
                    {dayjs(note.created_at).format(DAYJS_DEFAULT_FORMAT)}
                  </div>
                </div>
              )}
            </div>
            {accessType === AccessType.EDIT && (
              <ReflectCards
                note={note}
                editor={(editorRef?.current as any)?.getEditorInstance()}
              />
            )}
          </div>
        </div>

        {/* Actions */}
        <Separator className="mb-4" />

        <TextEditor
          // HACK: This is a hack to make the editor re-render when the note changes
          key={note.id}
          initialData={note?.body}
          onUpdate={({ data }: { data: string }) => setNoteContentState(data)}
          readOnly={accessType === AccessType.READ}
          appExtensions={[
            AppExtensions.REVISE_MARK,
            AppExtensions.HYPERLINK,
            AppExtensions.DRAG_AND_DROP,
          ]}
          ref={editorRef}
        />
      </Prose>
      <div className="hidden lg:block col-span-2">
        <TOC body={note.body} top={note.title} />
      </div>
    </div>
  );
};
