import { useQuery } from "react-query";
import supabase from "../../utils/supabase";

const fetchNoteMetadata = async (topicId: string) => {
  const { data, error } = await supabase
    .from("notes")
    .select(
      `
        id,
        created_at,
        visibility,
        user_id,
        template,
        status
      `
    )
    .eq("id", topicId);

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

export const useNoteMetadata = (noteId: string, enabled?: boolean) => {
  return useQuery(["note-metadata", noteId], () => fetchNoteMetadata(noteId), {
    enabled,
  });
};
