import { useQuery } from "react-query";
import { ZodType, ZodTypeDef } from "zod";
import { getAiCompletions } from "../../api/getAiCompletions";
import { AICompletionParams } from "./types";

export type AIParams = OpenAIParams;

export type OpenAIParams = {
  model: string;
  prompt: string;
  schema?: ZodType<unknown, ZodTypeDef, unknown>;
};

export const AIProviders = { openai: "openai", mistral: "mistralai" } as const;

export const useFetchAICompletions = ({
  provider,
  params,
  instance,
}: AICompletionParams) => {
  return useQuery(
    ["ai", provider],
    () => {
      if (provider === "openai") {
        return getAiCompletions({ provider, params, instance });
      } else if (provider === "mistralai") {
        return getAiCompletions({ provider, params, instance });
      }
    },
    {
      enabled: !!params.prompt && Boolean(instance),
      cacheTime: 0,
      retry: 0,
    }
  );
};
