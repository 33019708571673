import { useQuery } from "react-query";
import { GptIntegrationModel } from "../../../integrations/forms/GptIntegrationForm";
import { fetchModelList } from "./api/fetchModelList";

export const useOpenAIModelList = ({
  apiKey,
  enabled,
}: {
  apiKey: string;
  enabled: boolean;
}) => {
  return useQuery(["openai-models", apiKey], () => fetchModelList({ apiKey }), {
    enabled: !!apiKey && enabled,
    select: (data: { data: GptIntegrationModel[] }) => data.data,
  });
};
