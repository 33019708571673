import dayjs from "dayjs";
import { MoreVertical, X } from "lucide-react";
import { FC, useState } from "react";
import { useAuthStore } from "../../store/auth";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../ui-components/Alert";
import { Button } from "../../ui-components/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../ui-components/DropdownMenu";
import { Sheet, SheetContent } from "../../ui-components/Sheet";
import { Database } from "../../utils/database.types";
import { cn, DAYJS_MINI_FORMAT } from "../../utils/utils";
import { AppLoader } from "../global-helpers";
import { AIProviders } from "../hooks/ai/useFetchAiCompletions";
import { useDeleteIntegration } from "../hooks/mutations/useDeleteIntegration";
import { useGetConnectedIntegrations } from "../hooks/queries/useGetConnectedIntegrations";
import { GptIcon } from "./gpt.svg";
import { IntegrationPreview } from "./IntegrationPreview";
import { MistralIcon } from "./mistral.svg";

export const integrationsMap = [
  {
    id: AIProviders.openai,
    label: "Open AI",
    description: "Generate in-app reflections using Open AI models.",
    icon: <GptIcon className="size-4" />,
  },
  {
    id: AIProviders.mistral,
    label: "Mistral",
    description: "Create in-app reflections using Mistral's AI models.",
    icon: <MistralIcon className="size-3" />,
  },
] as const;

export const Integrations: FC = () => {
  const [openIntegrationsModal, setOpenIntegrationsModal] =
    useState<
      Omit<
        Database["public"]["Tables"]["connected-integrations"]["Row"],
        "created_at"
      >
    >(null);

  const { userId } = useAuthStore();

  const { mutate: deleteIntegration } = useDeleteIntegration();

  const { data: connectedIntegrations = [], isLoading: isLoadingIntegrations } =
    useGetConnectedIntegrations({
      userId,
    });

  const [deleteIntegrationDialog, setDeleteIntegrationDialog] = useState(null);

  if (isLoadingIntegrations) {
    return <AppLoader />;
  }

  return (
    <div className="flex flex-col gap-10 p-6">
      <div className="flex flex-row items-center gap-x-1">
        <div className="flex flex-col">
          <div className="text-xl font-medium">Integrations</div>
          <div className="text-textColors-muted-light dark:text-textColors-muted-dark text-sm">
            Integrate with third-party tools, services and add-ons
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-10">
        <div className="flex flex-col gap-4">
          <div>Available</div>
          <div className={cn("grid grid-cols-1 md:grid-cols-3 gap-6")}>
            {integrationsMap.map((integration) => {
              return (
                <div
                  key={integration.id}
                  className="p-4 relative cursor-pointer rounded-md border border-border-primary-light dark:border-border-primary-dark flex flex-col gap-y-4 hover:bg-bg-secondary-light dark:hover:bg-bg-secondary-dark"
                  onClick={() => {
                    setOpenIntegrationsModal({
                      metadata: {
                        integrationType: integration.id,
                        model: null,
                      },
                      user_id: userId,
                      secret: "",
                      id: null,
                      name: "",
                    });
                  }}
                >
                  <div className="flex flex-row items-center justify-between">
                    <div className="text-sm font-medium truncate flex flex-row items-center gap-1.5">
                      {integration.icon}
                      {integration.label}
                    </div>
                  </div>
                  <p className="text-sm line-clamp-2 h-11 text-textColors-muted-light dark:text-textColors-muted-dark">
                    {integration.description}
                  </p>
                </div>
              );
            })}
          </div>
        </div>

        {Boolean(connectedIntegrations.length) && (
          <div className="flex flex-col gap-4">
            <div>Connected</div>
            <div className={cn("grid grid-cols-1 md:grid-cols-3 gap-6")}>
              {connectedIntegrations.map((connectedIntegration) => {
                const integrationType =
                  connectedIntegration.metadata["integrationType"];

                const integrationMeta = integrationsMap.find(
                  (integration) => integration.id === integrationType
                );

                if (
                  !integrationMeta ||
                  !integrationType ||
                  !connectedIntegration
                )
                  return null;

                return (
                  <div key={connectedIntegration.id}>
                    <div
                      className="p-4 relative cursor-pointer rounded-md border border-border-primary-light dark:border-border-primary-dark flex flex-col gap-y-4 hover:bg-bg-secondary-light dark:hover:bg-bg-secondary-dark"
                      onClick={() => {
                        setOpenIntegrationsModal({
                          metadata: {
                            integrationType,
                            model: (connectedIntegration?.metadata as any)
                              ?.model,
                          },
                          user_id: userId,
                          secret: connectedIntegration?.secret || "",
                          id: connectedIntegration.id,
                          name: connectedIntegration.name,
                        });
                      }}
                    >
                      <div className="flex flex-row items-center justify-between">
                        <div className="text-sm font-medium truncate flex flex-row items-center gap-1.5">
                          {integrationMeta.icon}
                          {connectedIntegration.name}
                        </div>
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button variant="spirit" size="xs" className="w-5">
                              <MoreVertical size={14} />
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent className="mr-2">
                            <DropdownMenuItem
                              onClick={(e) => {
                                e.stopPropagation();

                                setDeleteIntegrationDialog(
                                  connectedIntegration
                                );
                              }}
                            >
                              Delete integration
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </div>
                      <span className="text-sm text-textColors-muted-light dark:text-textColors-muted-dark">
                        {dayjs(connectedIntegration.created_at).format(
                          DAYJS_MINI_FORMAT
                        )}
                      </span>
                    </div>
                    {Boolean(deleteIntegrationDialog) && (
                      <AlertDialog
                        open={Boolean(deleteIntegrationDialog)}
                        onOpenChange={setDeleteIntegrationDialog}
                      >
                        <AlertDialogContent>
                          <AlertDialogHeader>
                            <AlertDialogTitle>
                              Delete integration
                            </AlertDialogTitle>
                            <AlertDialogDescription>
                              Are you sure to delete "
                              {deleteIntegrationDialog.name}" ? This action is
                              irreversible.
                            </AlertDialogDescription>
                          </AlertDialogHeader>
                          <AlertDialogFooter>
                            <AlertDialogCancel>Cancel</AlertDialogCancel>
                            <AlertDialogAction
                              onClick={async () => {
                                deleteIntegration(
                                  {
                                    integrationId: connectedIntegration.id,
                                  },
                                  {
                                    onSettled: () => {
                                      setDeleteIntegrationDialog(null);
                                    },
                                  }
                                );
                              }}
                            >
                              Delete
                            </AlertDialogAction>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialog>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <Sheet
          open={Boolean(openIntegrationsModal)}
          onOpenChange={() => {
            if (Boolean(openIntegrationsModal)) setOpenIntegrationsModal(null);
          }}
        >
          <SheetContent
            side="right"
            className="pr-0 border h-[95%] rounded-md my-auto mr-4"
            autoClose={false}
          >
            <>
              <Button
                variant="ghost"
                size="icon"
                className="h-6 w-6 absolute right-4 top-6"
                onClick={() => {
                  setOpenIntegrationsModal(null);
                }}
              >
                <X className="h-4 w-4" />
              </Button>
              <IntegrationPreview
                integration={{
                  id: openIntegrationsModal?.id,
                  user_id: userId,
                  metadata: openIntegrationsModal?.metadata,
                  secret: openIntegrationsModal?.secret,
                  name: openIntegrationsModal?.name,
                }}
                onClose={() => setOpenIntegrationsModal(null)}
              />
            </>
          </SheetContent>
        </Sheet>
      </div>
    </div>
  );
};
