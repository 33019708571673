import { createMistral, MistralProvider } from "@ai-sdk/mistral";
import { createOpenAI, OpenAIProvider } from "@ai-sdk/openai";
import { z } from "zod";
import { Database } from "../utils/database.types";
import { AIProviderInstance } from "./hooks/ai/types";
import { AIProviders } from "./hooks/ai/useFetchAiCompletions";

export const getIsActiveIntegration = ({
  integrations = [],
}: {
  integrations: Database["public"]["Tables"]["connected-integrations"]["Row"][];
}) => {
  return integrations?.[0];
};

export const getIsOpenAIIntegration = ({
  integration,
}: {
  integration: Partial<
    Database["public"]["Tables"]["connected-integrations"]["Row"]
  >;
}) => {
  if (!integration) return false;

  return (
    (integration.metadata as Record<string, unknown>)?.integrationType ===
      AIProviders.openai && integration
  );
};

export const getIsMistralIntegration = ({
  integration,
}: {
  integration: Partial<
    Database["public"]["Tables"]["connected-integrations"]["Row"]
  >;
}) => {
  if (!integration) return false;

  return (
    (integration.metadata as Record<string, unknown>)?.integrationType ===
      AIProviders.mistral && integration
  );
};

export const getAIInstance = ({
  activeIntegration,
}: {
  activeIntegration: Partial<
    Database["public"]["Tables"]["connected-integrations"]["Row"]
  >;
}) => {
  if (getIsOpenAIIntegration({ integration: activeIntegration })) {
    const openai = createOpenAI({ apiKey: activeIntegration.secret });

    return openai;
  } else if (getIsMistralIntegration({ integration: activeIntegration })) {
    return createMistral({ apiKey: activeIntegration.secret });
  }

  return null;
};

const generateRevisionMarkerPrompt = ({
  selectedText,
}: {
  selectedText: string;
}) => {
  return `You're an AI designed to encourage deep reflection and understanding of textual content. 
  Your task is to generate a meaningful, thought-provoking question from the provided paragraph to aid in comprehension and retention.
  Frame the question to cover key ideas, underlying themes, or subtle implications in the text. Additionally, provide a concise answer
  that directly responds to the question without referencing the text or paragraph.

  Here is the paragraph for which you'll create the question:

  [Paragraph: ${selectedText}]`;
};

export const getAiCompletionParams = ({
  integrationType,
  modelId,
  selectedText,
  aiInstance,
}: {
  integrationType: (typeof AIProviders)[keyof typeof AIProviders];
  modelId: string;
  selectedText: string;
  aiInstance: AIProviderInstance;
}) => {
  if (integrationType === "openai") {
    return {
      provider: integrationType,
      params: {
        model: modelId,
        prompt: generateRevisionMarkerPrompt({ selectedText }),
        schema: z.object({
          results: z
            .object({
              question: z.string(),
              answer: z.string(),
            })
            .array(),
        }),
      },
      instance: aiInstance as OpenAIProvider,
    };
  } else if (integrationType === "mistralai") {
    return {
      provider: integrationType,
      params: {
        model: modelId,
        prompt: generateRevisionMarkerPrompt({ selectedText }),
        schema: z.object({
          results: z
            .object({
              question: z.string(),
              answer: z.string(),
            })
            .array(),
        }),
      },
      instance: aiInstance as MistralProvider,
    };
  }

  return null;
};
