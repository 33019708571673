export const fetchMistralModels = async ({ apiKey }: { apiKey: string }) => {
  return await fetch(`https://api.mistral.ai/v1/models`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${apiKey}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      throw new Error("There was a problem with your fetch operation");
    });
};
