import dayjs from "dayjs";
import { ArrowLeft } from "lucide-react";
import { FC, PropsWithChildren } from "react";
import ReactMarkdown from "react-markdown";
import { useHistory, useParams } from "react-router-dom";
import { RootLayout } from "../layouts/Root.layout";
import { Button } from "../ui-components/Button";
import { LucideIcon } from "../ui-components/LucideIcon";
import { NotFound } from "../ui-components/NotFound";
import { Separator } from "../ui-components/Separator";
import { Prose } from "../ui-components/templates/Prose";
import { DAYJS_DEFAULT_FORMAT } from "../utils/utils";
import { usePosts } from "./usePosts";

const Layout: PropsWithChildren<FC> = ({ children }) => {
  return <RootLayout>{children}</RootLayout>;
};

export const Post: FC = () => {
  const { id: slug } = useParams<{ id: string }>();
  const { posts, loading } = usePosts();
  const router = useHistory();

  const post = posts.find((post) => post.frontmatter.slug === slug);

  if (loading)
    return (
      <Layout>
        <div className="h-full"></div>
      </Layout>
    );

  if (!post && posts.length > 0) {
    return (
      <Layout>
        <NotFound
          header={
            <div className="flex flex-row flex-wrap gap-4">
              <span>404</span>
              <Separator orientation="vertical" className="h-7" />
              <p className="text-textColors-muted-light dark:text-textColors-muted-dark">
                This page does not exist
              </p>
            </div>
          }
          action={
            <Button
              variant="outline"
              onClick={() => {
                router.goBack();
              }}
            >
              Go back
            </Button>
          }
        />
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="w-full flex flex-col items-center transition-all ease-out pb-24">
        <div className="lg:w-[50%] w-full p-4 lg:p-12 flex flex-col gap-6">
          <div className="flex flex-col gap-4">
            <Button
              variant="link"
              className="gap-1 w-fit pl-0 text-xs"
              onClick={() => {
                router.push(`/blog`);
              }}
            >
              <LucideIcon icon={ArrowLeft} size={12} />
              Blog
            </Button>
            <div className="text-sm text-textColors-muted-light dark:text-textColors-muted-dark">
              {post.frontmatter.tag}
            </div>
            <span className="text-2xl font-medium">
              {post.frontmatter.title}
            </span>
            <span className="text-sm font-medium text-textColors-muted-light dark:text-textColors-muted-dark">
              Published on{" "}
              {dayjs(post.frontmatter.date).format(DAYJS_DEFAULT_FORMAT)}
            </span>
          </div>
          <Separator />
          <Prose>
            <ReactMarkdown>{post.content}</ReactMarkdown>
          </Prose>
        </div>
      </div>
    </Layout>
  );
};
