import { Editor } from "@tiptap/react";
import { ChevronRight } from "lucide-react";
import { FC, useCallback } from "react";
import { useQueryClient } from "react-query";
import { v4 } from "uuid";
import { z } from "zod";
import { TodoIcon } from "../../../assets/svg/todo.svg";
import { SelectedAiInstance } from "../../../pages-v2/hooks/ai/types";
import {
  Dialog,
  DialogContent,
  DialogPortal,
  DialogTitle,
} from "../../../ui-components/Dialog";
import { LucideIcon } from "../../../ui-components/LucideIcon";
import { RevisionCardColorsType } from "../../extensions/revise-marks/Revise.mark";
import { revisionItemSchema } from "../SelectionMenu";
import { MarkerForm } from "./MarkerForm";
import { AIMarkerGeneratorWrapper } from "./OpenaiMarkerGenerator";

export const RevisionDialogs: FC<{
  dialog: "create" | "preview" | "ai" | null;
  setDialog: (dialog: "create" | "preview" | "ai" | null) => void;
  editor: Editor;
  setSelectionType: (type: "reviseMark" | null) => void;
  selectedText: string;
  aiInstance: SelectedAiInstance;
}> = ({
  dialog,
  setDialog,
  editor,
  setSelectionType,
  selectedText,
  aiInstance,
}) => {
  const queryClient = useQueryClient();

  const onSubmitForm = useCallback(
    (values: z.infer<typeof revisionItemSchema>) => {
      const { to } = editor.state.selection;

      editor
        .chain()
        .setReflectNode({
          reflectId: v4(),
          data: {
            type: "revision",
            data: {
              [values.question]: {
                answer: values.answer,
                id: v4(),
                retained: "0",
                forgotten: "0",
              },
            },
          },
        })
        .focus(to)
        .run();

      queryClient.refetchQueries(["reflections"]);

      setSelectionType(null);
    },
    [editor, setSelectionType, queryClient]
  );

  const addNewMarker = ({
    question,
    answer,
    color,
  }: {
    question: string;
    answer: string;
    color: RevisionCardColorsType;
  }) => {
    onSubmitForm({ question, answer, color });
  };

  const onSubmitAiMarkers = useCallback(
    (values: { question: string; answer: string }[]) => {
      const { to } = editor.state.selection;

      editor
        .chain()
        .setReflectNode({
          reflectId: v4(),
          data: {
            type: "revision",
            data: values.reduce((acc, item) => {
              return {
                ...acc,
                [item.question]: {
                  answer: item.answer,
                  id: v4(),
                  retained: "0",
                  forgotten: "0",
                },
              };
            }, {}),
          },
        })
        .focus(to)
        .run();

      setSelectionType(null);
      queryClient.refetchQueries(["reflections"]);
    },
    [editor, setSelectionType, queryClient]
  );

  return (
    <>
      {dialog === "create" && (
        <Dialog
          open
          onOpenChange={(open) => {
            if (!open) {
              setDialog(null);
              setSelectionType(null);

              // cleanup the editor
              editor.chain().focus().setTextSelection(-1);
            }
          }}
        >
          <DialogContent
            className="h-[300px] p-4 pr-0"
            onOpenAutoFocus={(e) => e.preventDefault()}
          >
            <DialogTitle>
              <div className="flex flex-row flex-wrap items-center gap-1">
                <div className="flex flex-row gap-1 items-center text-xs font-normal px-2 py-1 rounded-md bg-backgroundColors-gray-light dark:bg-backgroundColors-gray-dark text-textColors-secondary-light dark:text-textColors-secondary-dark">
                  <TodoIcon className="size-3 ease-out stroke-[3]" />
                  Reflect
                </div>
                <LucideIcon icon={ChevronRight} size={16} />
                <span className="text-xs font-normal">New</span>
              </div>
            </DialogTitle>
            <MarkerForm
              onSubmit={addNewMarker}
              onDelete={() => {
                setDialog(null);
                setSelectionType(null);
                editor
                  .chain()
                  .extendMarkRange("reviseMark")
                  .unsetReviseMark()
                  .setTextSelection({
                    from: 1,
                    to: 1,
                  })
                  .run();
              }}
              selectedText={selectedText}
            />
          </DialogContent>
        </Dialog>
      )}
      {dialog === "ai" && (
        <Dialog
          open
          onOpenChange={(open) => {
            if (!open) {
              setDialog(null);
              setSelectionType(null);

              // cleanup the editor
              editor.chain().focus().setTextSelection(-1);
            }
          }}
        >
          <DialogPortal>
            <DialogContent
              className="p-4 pr-0 w-[90vw] md:w-[50vw] lg:w-[33vw] rounded-md"
              onOpenAutoFocus={(e) => e.preventDefault()}
            >
              <DialogTitle>
                <div className="flex flex-row flex-wrap items-center gap-1">
                  <div className="flex flex-row gap-1 items-center text-xs font-normal px-2 py-1 rounded-md bg-backgroundColors-gray-light dark:bg-backgroundColors-gray-dark text-textColors-secondary-light dark:text-textColors-secondary-dark">
                    <TodoIcon className="size-3 ease-out stroke-[3]" />
                    Reflect
                  </div>
                  <LucideIcon icon={ChevronRight} size={16} />
                  <span className="text-xs font-normal">AI</span>
                </div>
              </DialogTitle>
              <div
                className="h-[400px] flex-grow overflow-auto"
                id="ai-scroll-ref"
              >
                <AIMarkerGeneratorWrapper
                  onSubmit={(values) => onSubmitAiMarkers(values)}
                  onDelete={() => {
                    // setIsOpen(false);
                    setDialog(null);
                    setSelectionType(null);
                    editor
                      .chain()
                      .extendMarkRange("reviseMark")
                      .unsetReviseMark()
                      .setTextSelection({
                        from: 1,
                        to: 1,
                      })
                      .run();
                  }}
                  selectedText={selectedText}
                  onClose={() => {
                    setDialog(null);
                    setSelectionType(null);
                  }}
                  aiInstance={aiInstance}
                />
              </div>
            </DialogContent>
          </DialogPortal>
        </Dialog>
      )}
    </>
  );
};
