import { ChakraProvider, useColorMode } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  useHistory,
} from "react-router-dom";
import CreateProfile from "./pages/auth/createprofile";
import EmailVerification from "./pages/auth/emailverification";
import Login from "./pages/auth/login";
import ProviderSignin from "./pages/auth/providersignin";
import Signup from "./pages/auth/signup";
import EmailVerificationSuccess from "./pages/auth/verificationsuccess";
import theme from "./theme/theme";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { FC, useCallback, useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { Toaster } from "sonner";
import "./App.scss";
import { Blog } from "./blog/Blog";
import { Post } from "./blog/Post";
import { Auth } from "./layouts/Auth.layout";
import { NotesDashboardWrapper } from "./pages-v2";
import { AppLoader } from "./pages-v2/global-helpers";
import { BASE_URL } from "./pages-v2/NotesDashboardWrapper";
import ChangePassword from "./pages/auth/changepassword";
import ForgotPassword from "./pages/auth/forgotpassword";
import { CustomError } from "./pages/customerror";
import { LandingPage } from "./pages/landing/landing";
import { Privacy } from "./pages/privacy/privacy";
import { UserProfile } from "./pages/userprofile";
import { useAuthStore } from "./store/auth";
import { Database } from "./utils/database.types";
import supabase from "./utils/supabase";

dayjs.extend(relativeTime);

const AppRoutes = () => {
  // navigation shortcuts
  const router = useHistory();
  const { userId } = useAuthStore();
  useHotkeys("alt+S", () => router.push(`${BASE_URL}/settings/profile`));
  useHotkeys("alt+P", () => router.push(`/user/${userId.replaceAll("-", "")}`));

  return (
    <Switch>
      <Route exact path="/">
        <LandingPage />
      </Route>
      <Route path="/signin-success">
        <ProviderSignin />
      </Route>
      <Route path="/blog" exact>
        <Blog />
      </Route>
      <Route path="/blog/:id">
        <Post />
      </Route>
      <Route path="/privacy">
        <Privacy />
      </Route>
      <Route path="/complete-profile">
        <CreateProfileWrapper />
      </Route>
      <Route path="/login" exact>
        <Login />
      </Route>
      <Route path="/forgot-password">
        <ForgotPassword />
      </Route>
      <Route path="/change-password">
        <ChangePassword />
      </Route>
      <Route path="/error">
        <CustomError />
      </Route>
      <Route path="/signup">
        <Signup />
      </Route>
      <Route path="/email-verify">
        <EmailVerification />
      </Route>
      <Route path="/verification-success">
        <EmailVerificationSuccess />
      </Route>
      <Route path="/user/:userId" exact>
        <Auth>
          <UserProfile />
        </Auth>
      </Route>
      {/* Protected routes */}
      <Route path="/">
        <Auth>
          <NotesDashboardWrapper />
        </Auth>
      </Route>
    </Switch>
  );
};

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 3,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      <ChakraProvider theme={theme}>
        <AppRoutesWithToast />
      </ChakraProvider>
    </QueryClientProvider>
  );
}

const AppRoutesWithToast = () => {
  const { colorMode, setColorMode } = useColorMode();

  useHotkeys("alt+T", () =>
    colorMode === "light" ? setColorMode("dark") : setColorMode("light")
  );

  return (
    <>
      <Toaster position="bottom-right" theme={colorMode} />
      <Router>
        <AppRoutes />
      </Router>
    </>
  );
};

export default App;

const CreateProfileWrapper: FC = () => {
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const { hydrate } = useAuthStore();

  const processUserProfile = useCallback(
    async ({
      userId,
    }: {
      userId: Database["public"]["Tables"]["users"]["Row"]["id"];
    }) => {
      const { data, error } = await supabase
        .from("users")
        .select()
        .eq("id", userId);

      if (error || !data || !data.length) {
        // no profile has been created. redirect to create profile.
        setLoading(false);
        return;
      } else {
        hydrate();
        history.push(`${BASE_URL}/`);
      }

      setLoading(false);
    },
    [history, hydrate]
  );

  useEffect(() => {
    const { data: listener } = supabase.auth.onAuthStateChange(
      (event, session) => {
        if (event === "SIGNED_IN" || event === "INITIAL_SESSION") {
          processUserProfile({
            userId: session?.user?.id,
          });
        }
      }
    );

    return () => {
      listener?.subscription.unsubscribe();
    };
  }, [processUserProfile]);

  if (loading) {
    return <AppLoader description="" />;
  }

  return <CreateProfile />;
};
