import { useMutation, useQueryClient } from "react-query";
import { useAuthStore } from "../../../store/auth";
import { createIntegration } from "../../api/createIntegration";
import { AIProviders } from "../ai/useFetchAiCompletions";
import { getIntegrationsQueryKey } from "../query-keys";

type IntegrationPayload = {
  metadata: Record<string, string> & {
    integrationType: (typeof AIProviders)[keyof typeof AIProviders];
  };
  secret: string;
  name: string;
};

export const useCreateIntegration = () => {
  const { userId } = useAuthStore();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: IntegrationPayload) => {
      return createIntegration({
        user_id: userId,
        metadata: payload.metadata,
        secret: payload.secret,
        name: payload.name,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getIntegrationsQueryKey({ userId }));
      },
    }
  );
};
