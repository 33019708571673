import { FC, ReactNode } from "react";
import Logo from "../utils/logo";

export const NotFound: FC<{
  header: ReactNode;
  action: ReactNode;
  logo?: boolean;
}> = ({ header, action, logo = true }) => {
  return (
    <div className="grid place-items-center justify-center h-full">
      <div className="flex flex-col gap-12 items-center text-center">
        {logo && <Logo className="[&>svg]:size-12" />}
        <div className="flex flex-col gap-6">
          {header}
          {action}
        </div>
      </div>
    </div>
  );
};
