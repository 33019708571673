import { Node, mergeAttributes } from "@tiptap/core";
import { Plugin, PluginKey, TextSelection } from "@tiptap/pm/state";
import { ReactNodeViewRenderer } from "@tiptap/react";
import { escapeForHTML } from "./helper";
import { ReflectView } from "./view";

export type ReflectAttributes = {
  reflectId: string;
  data:
    | {
        type: "revision";
        data: {
          [question: string]: {
            answer: string;
            retained: string;
            forgotten: string;
            id: string;
          };
        };
      }
    | {
        type: "summary";
        data: [];
      };
};

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    reflect: {
      /**
       * Set a reflect node
       * @param attributes Reflect node attributes
       * @example editor.commands.setReflectNode({ reflectId: 'abc', data: {lorem: "ipsum"} })
       */
      setReflectNode: (attributes: ReflectAttributes) => ReturnType;
    };
  }
}

// schema: { id: string, data: { type:"revision" | "summary", data: {} }}
// <span id={id} data={schema}></span>

export const ReflectExtension = Node.create({
  name: "reflect",

  group: "inline",
  inline: true,
  atom: true,

  defining: true,

  addAttributes() {
    return {
      data: {
        default: null,
        renderHTML: (attributes) => {
          return {
            data: attributes.data,
          };
        },
        parseHTML: (element) => element.getAttribute("data"),
      },
      reflectId: {
        default: null,
        renderHTML: (attributes) => {
          return {
            reflectId: attributes.reflectId,
          };
        },
        parseHTML: (element) => element.getAttribute("reflectId"),
      },
    };
  },

  // treat all span elements with reflectId as reflect extension
  parseHTML() {
    return [
      {
        tag: "span",
        getAttrs: (element: HTMLElement | string) => {
          if (typeof element === "string") return false;
          element.hasAttribute("reflectId");
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "span",
      mergeAttributes(HTMLAttributes, { class: "reflect-element" }),
    ];
  },

  addCommands() {
    return {
      setReflectNode:
        (attributes: ReflectAttributes) =>
        ({ commands, editor }) => {
          const { from } = editor.state.selection;

          let dataString = JSON.stringify(attributes.data);
          dataString = escapeForHTML(dataString);

          return commands.insertContentAt(
            { from, to: from },
            `<span className="reflect-element" reflectId="${attributes.reflectId}" data="${dataString}"></span>`
          );
        },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(ReflectView);
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey("reflect"),
        props: {
          handleKeyDown: (view, event) => {
            if (event.key === "Backspace") {
              const { state, dispatch } = view;
              const { selection, doc, tr } = state;
              const { $from, from } = selection;

              // Check if the cursor is directly before or after a `reflect` node
              const nodeBefore = $from.nodeBefore;

              if (nodeBefore && nodeBefore.type.name === "reflect") {
                const newStartingPos = from - nodeBefore.nodeSize - 1;
                const newEndingPos = newStartingPos + 1;

                dispatch(view.state.tr.delete(newStartingPos, newEndingPos));

                const $start = doc.resolve(newStartingPos - 1);
                const $end = doc.resolve(newStartingPos - 1);
                const transaction = tr.setSelection(
                  new TextSelection($start, $end)
                );

                view.dispatch(transaction);

                return true;
              }
            }

            return false;
          },
        },
      }),
    ];
  },
});
