import { NodeViewProps, NodeViewWrapper } from "@tiptap/react";
import { ChangeEvent, FC, useState } from "react";
import { TodoIcon } from "../../../assets/svg/todo.svg";
import { Button } from "../../../ui-components/Button";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "../../../ui-components/Dialog";
import { NotFound } from "../../../ui-components/NotFound";
import { Separator } from "../../../ui-components/Separator";
import { ViewAnswer } from "../../../ui-components/templates/basic/reflect-cards/ViewAnswer";
import { cn } from "../../../utils/utils";
import { ReflectAttributes } from "./extension";

export const ReflectView: FC<NodeViewProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const reflectionData = props?.node?.attrs as {
    reflectId: ReflectAttributes["reflectId"];
    data: string;
  };

  const reflectId =
    Boolean(reflectionData) && (reflectionData.reflectId as string);
  const data = Boolean(reflectionData) && (reflectionData.data as string);

  if (!reflectId || !data) return null;

  return (
    <NodeViewWrapper className={cn("absolute -left-20 reflect-marker-wrapper")}>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogTrigger asChild onClick={(e) => e.preventDefault()}>
          <div
            onDoubleClick={() => {
              setIsOpen(true);
            }}
            // size="icon"
            className={cn(
              "inline-flex items-center justify-center whitespace-nowrap",
              "cursor-default relative -top-[26px] left-0 hover:z-10 z-0 reflect-marker bg-transparent size-fit h-4",
              "group"
            )}
          >
            <div className="h-[3px] bg-[#E0E0E0] rounded-[2rem] dark:bg-[#2C2D2E] w-[0.8rem] group-hover:w-[1.2rem] group-hover:bg-reflect-light dark:group-hover:bg-reflect-dark transition-all duration-300 ease-out"></div>
          </div>
        </DialogTrigger>
        <DialogContent className="p-8 pr-0">
          <ViewReflection
            data={reflectionData}
            onClose={() => setIsOpen(false)}
          />
        </DialogContent>
      </Dialog>
    </NodeViewWrapper>
  );
};

export const ViewReflection: FC<{
  data: {
    reflectId: ReflectAttributes["reflectId"];
    data: string;
  };
  onClose: () => void;
}> = ({ data, onClose }) => {
  let parsedReflectionData: ReflectAttributes["data"] | "" = "";

  try {
    parsedReflectionData = JSON.parse(data.data || "{}");
  } catch (err) {
    parsedReflectionData = "";
  }

  if (!parsedReflectionData)
    return (
      <div className="p-12">
        <NotFound
          header={
            <div className="flex flex-col gap-2 items-center justify-center">
              Unable to find this reflection.
              <span className="text-sm font-normal w-64">
                You can safely delete and create a new one instead.
              </span>
            </div>
          }
          action={
            <div className="flex flex-row items-center gap-2 justify-center mt-4">
              <Button variant="ghost" onClick={() => onClose()}>
                Cancel
              </Button>
              {/* <Button variant="outline">Delete</Button> */}
            </div>
          }
        />
      </div>
    );

  if (parsedReflectionData.type === "revision") {
    return (
      <ViewRevisionReflection data={parsedReflectionData} id={data.reflectId} />
    );
  }

  return null;
};

const ViewRevisionReflection: FC<{
  data: Exclude<ReflectAttributes["data"], { type: "summary" }>;
  id: ReflectAttributes["reflectId"];
}> = ({ data, id }) => {
  const question = Object.keys(data.data)?.[0];
  const answer = data?.data?.[question]?.answer;

  const [reflectionData, setReflectionData] =
    useState<Exclude<ReflectAttributes["data"], { type: "summary" }>>(data);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const rest = reflectionData?.data?.[question];

    const newData = { [value]: rest };

    setReflectionData({
      ...reflectionData,
      data: newData,
    });
  };

  return (
    <div className="flex flex-col gap-4 h-[270px] overflow-auto">
      <div className="flex flex-row items-center justify-between">
        <TodoIcon className="size-4 ease-out stroke-[3]" />
      </div>
      <div className="flex flex-col justify-center gap-1">
        <span
          className="text-xl"
          // className="text-xl border-transparent hover:border-inherit shadow-none focus:ring-0"
          // value={Object.keys(reflectionData.data)?.[0]}
          onChange={handleInputChange}
        >
          {question}
        </span>
        <Separator className="w-4 my-2" />
        <div className="font-normal text-sm border-transparent hover:border-inherit shadow-none leading-6 pr-8">
          <ViewAnswer answer={answer} />
        </div>
      </div>
    </div>
  );
};
