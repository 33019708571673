import {
  Code,
  Command,
  Heading2,
  ListChecks,
  ListTree,
  Lock,
  PenTool,
  Quote,
} from "lucide-react";
import { FC } from "react";
import { ConfidenceSvg } from "../../../assets/svg/confidence.svg";
import { LucideIcon } from "../../../ui-components/LucideIcon";
import { cn } from "../../../utils/utils";

const toolbarIcons = [Heading2, Quote, ListChecks, Code, ListTree];

const featuresList = [
  {
    id: "Confidence",
    description: "Measure retained information over time.",
    icon: (
      <div
        className={cn(
          "p-2 w-fit flex flex-col items-center justify-center rounded-lg",
          "border border-solid"
        )}
        style={{
          background: "var(--hl-green)",
          borderColor: "var(--green)",
        }}
      >
        <ConfidenceSvg
          className={cn("size-4 stroke-[9] mt-[3px]", "stroke-[var(--green)]")}
        />
      </div>
    ),
  },
  {
    id: "Privacy Control",
    description: "Keep your notes private and share them when you choose.",
    icon: (
      <div
        className={cn(
          "p-2 w-fit flex flex-col items-center justify-center rounded-lg",
          "border border-solid"
        )}
        style={{
          background: "var(--hl-yellow)",
          borderColor: "var(--yellow)",
        }}
      >
        <LucideIcon
          icon={Lock}
          className="size-4 stroke-[2.5]"
          style={{
            stroke: "var(--yellow)",
          }}
        />
      </div>
    ),
  },
  {
    id: "Snippets",
    description: "Save your commonly used phrases and write faster.",
    icon: (
      <div
        className={cn(
          "p-2 w-fit flex flex-col items-center justify-center rounded-lg",
          "border border-solid"
        )}
        style={{
          background: "var(--hl-blue)",
          borderColor: "var(--blue)",
        }}
      >
        <LucideIcon
          icon={ListTree}
          className="size-4 stroke-[2.5]"
          style={{
            stroke: "var(--blue)",
          }}
        />
      </div>
    ),
    available: false,
  },
  {
    id: "Keyboard Shortcuts",
    description: "Fly through the app with rapid-fire shortcuts.",
    icon: (
      <div
        className={cn(
          "p-2 w-fit flex flex-col items-center justify-center rounded-lg",
          "border border-solid"
        )}
        style={{
          background: "var(--hl-purple)",
          borderColor: "var(--purple)",
        }}
      >
        <LucideIcon
          icon={Command}
          className="size-4 stroke-[2.5] stroke-textColors-foreground-light dark:stroke-textColors-foreground-dark"
          style={{
            stroke: "var(--purple)",
          }}
        />
      </div>
    ),
  },
  {
    id: "Highlights",
    description: "Mark key points to make important information stand out.",
    icon: (
      <div
        className={cn(
          "p-2 w-fit flex flex-col items-center justify-center rounded-lg",
          "border border-solid"
        )}
        style={{
          background: "var(--hl-blue)",
          borderColor: "var(--blue)",
        }}
      >
        <LucideIcon
          icon={PenTool}
          className="size-4 stroke-[2.5] stroke-textColors-foreground-light dark:stroke-textColors-foreground-dark"
        />
      </div>
    ),
  },
  {
    id: "Toolbar",
    description: "Access essential writing tools to compose your notes.",
    icon: (
      <div className="relative flex flex-row gap-2">
        {Array(1)
          .fill(0)
          .map((item, index) => {
            return (
              <div
                className={cn(
                  "p-2 w-fit flex flex-col items-center justify-center rounded-lg",
                  "border border-solid"
                )}
                style={{
                  background: "var(--hl-purple)",
                  zIndex: 50 - index * 2,
                  borderColor: "var(--purple)",
                }}
                key={index}
              >
                <LucideIcon
                  icon={toolbarIcons[index]}
                  className="size-4 stroke-[2.5]"
                  style={{
                    stroke: "var(--purple)",
                  }}
                />
              </div>
            );
          })}
      </div>
    ),
    available: false,
  },
];

export const Features = () => {
  return (
    <div className="features flex flex-col items-center justify-center gap-8">
      <div className="flex flex-col gap-4 items-center z-10">
        <h2 className="text-3xl sm:text-3xl md:text-[40px] md:w-[400px] font-semibold !leading-[110%] text-center">
          Utilities, seamlessly integrated.
        </h2>
        <p className="max-w-[300px] md:max-w-[60%] text-textColors-muted-light dark:text-textColors-secondary-dark leading-6 text-center">
          Unlock the full potential of writing with a suite of integrated
          features.
        </p>
      </div>

      <FeaturesList />
    </div>
  );
};

export const FeaturesList: FC = () => {
  return (
    <div className="flex flex-row flex-wrap items-center justify-center place-items-center text-left gap-4 md:px-24">
      {featuresList.map((feature, index) => {
        return (
          <div
            key={feature.id}
            className={cn(
              "flex flex-col text-left gap-2 w-[250px] lg:w-[250px] relative",
              "p-4 h-48 grow",
              "border border-border-primary-light dark:border-border-primary-dark group rounded-lg"
            )}
          >
            <div
              className={cn(
                "absolute size-full top-0 left-0",
                "group-hover:block hidden"
              )}
            >
              <div className="relative size-full">
                <CustomSeparator
                  orientation="vertical"
                  className="absolute left-[90%] size-full"
                />
                <CustomSeparator
                  orientation="vertical"
                  className="absolute left-[80%] size-full"
                />
                <CustomSeparator
                  orientation="horizontal"
                  className="absolute size-full top-[20%] -z-5"
                />
              </div>
            </div>
            <div className="z-10">{feature.icon}</div>
            <div className="flex flex-col gap-1">
              <div className="font-medium text-lg flex items-center gap-1.5">
                {feature.id}
              </div>
              <p className=" text-text-textColors-muted-light dark:text-textColors-muted-dark leading-6 text-balance w-[90%]">
                {feature.description}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const CustomSeparator = ({
  className = "",
  orientation = "horizontal",
}: {
  className?: string;
  orientation?: "vertical" | "horizontal";
}) => {
  return (
    <hr
      className={cn(
        "border-dashed border-border-primary-light/50 dark:border-border-primary-dark",
        orientation === "horizontal"
          ? "h-[0.5px] w-full border-t border-b-0 border-l-0 border-r-0"
          : "border-l h-full w-[1px] border-b-0 border-t-0 border-r-0",
        className
      )}
    />
  );
};
