import { Button } from "../../ui-components/Button";
import { AvatarsMap } from "../../utils/constants";

export const AvatarSelect = ({
  value,
  onSelect,
}: {
  value: string;
  onSelect: (selectedAvatar: string) => void;
}) => {
  return (
    <div className="flex flex-row flex-wrap gap-4">
      {Object.entries(AvatarsMap).map(([avatarKey, avatarIcon]) => {
        const isSelected = value === avatarKey;
        return (
          <Button
            variant="link"
            size="icon"
            key={avatarKey}
            className="size-8 relative"
            onClick={(e) => {
              e.preventDefault();
              onSelect(avatarKey);
            }}
          >
            {avatarIcon}
            {isSelected && (
              <div className="absolute -bottom-4 rounded-full size-2 bg-green-400"></div>
            )}
          </Button>
        );
      })}
    </div>
  );
};
