import { motion } from "framer-motion";
import { CornerDownLeft } from "lucide-react";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { TNotesResponseWithChildren } from "../../../pages-v2/hooks/queries/useNotesList";
import { BlockItem } from "../../../ui-components/BlockItem";
import { LucideIcon } from "../../../ui-components/LucideIcon";

export type NoteHyperlink = Pick<
  TNotesResponseWithChildren,
  "title" | "id" | "template" | "parentPaths"
>;

export const NoteHyperlinkList = forwardRef((props: any, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = (index: number) => {
    const item = props.items[index];

    if (item) {
      props.command({ id: item });
    }
  };

  const upHandler = () => {
    setSelectedIndex(
      (selectedIndex + props.items.length - 1) % props.items.length
    );
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }: { event: KeyboardEvent }) => {
      if (event.key === "ArrowUp") {
        upHandler();
        return true;
      }

      if (event.key === "ArrowDown") {
        downHandler();
        return true;
      }

      if (event.key === "Enter") {
        enterHandler(event);
        return true;
      }

      return false;
    },
  }));

  return (
    <div className="z-50 rounded-md border max-h-72 overflow-auto bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark p-1 text-popover-foreground shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2">
      {props.items.length ? (
        <div className="flex flex-col gap-0.5">
          {props.items.map(
            (item: TNotesResponseWithChildren, index: number) => (
              <BlockItem
                variant={index === selectedIndex ? "active" : "default"}
                key={index}
                className="rounded-sm w-48 justify-between"
                onClick={() => selectItem(index)}
              >
                <span className="truncate">{item.title}</span>
                {index === selectedIndex && (
                  <motion.div
                    initial={{
                      opacity: 0,
                    }}
                    animate={{
                      opacity: 1,
                    }}
                    transition={{
                      duration: 0.3,
                    }}
                  >
                    <LucideIcon
                      icon={CornerDownLeft}
                      size={14}
                      className="stroke-text-textColors-muted-light dark:stroke-text-muted-dark"
                    />
                  </motion.div>
                )}
              </BlockItem>
            )
          )}
        </div>
      ) : (
        <div className="item text-textColors-muted-light dark:text-textColors-muted-dark">
          No result
        </div>
      )}
    </div>
  );
});
