import { SelectedAiInstance } from "../../../pages-v2/hooks/ai/types";
import { AIResults } from "./AIResults";
import { AIResultSingleView } from "./AIResultSingleView";

export const AiMarkerForm = ({
  aiRevisionMarkers,
  onSubmit,
  onDelete,
  onRetry,
  aiInstance,
}: {
  aiRevisionMarkers: {
    question: string;
    answer: string;
  }[];
  onSubmit: (results: { question: string; answer: string }[]) => void;
  onDelete: () => void;
  onRetry: () => void;
  aiInstance: SelectedAiInstance;
}) => {
  if (aiRevisionMarkers.length > 1) {
    return (
      <AIResults
        aiRevisionMarkers={aiRevisionMarkers}
        onDelete={onDelete}
        onSubmit={(results) => {
          onSubmit(
            results.map(({ question, answer }) => ({
              question,
              answer,
            }))
          );
        }}
      />
    );
  }

  return (
    <AIResultSingleView
      aiRevisionMarkers={aiRevisionMarkers}
      onDelete={onDelete}
      onSubmit={(results) => {
        onSubmit(
          results.map(({ question, answer }) => ({
            question,
            answer,
          }))
        );
      }}
      onRetry={() => onRetry()}
      aiInstance={aiInstance}
    />
  );
};
