import { Archive } from "lucide-react";
import { useParams } from "react-router-dom";
import { useNoteMetadata } from "../../hooks/queries/useNoteMetadata";
import { useAuthStore } from "../../store/auth";
import { LucideIcon } from "../../ui-components/LucideIcon";
import { BasicTemplate } from "../../ui-components/templates/basic/Basic";
import { CheatsheetTemplate } from "../../ui-components/templates/cheatsheet/Cheatsheet";
import { APP_PAGE_HEIGHT } from "../../utils/constants";
import { TemplateEnum } from "../../utils/types";
import { cn } from "../../utils/utils";
import { AppLoader } from "../global-helpers";
import { useFetchNoteDetails } from "../hooks/queries/useNoteDetails";
import {
  transformHeirarchicalNotesInFlatList,
  useNotesList,
} from "../hooks/queries/useNotesList";

export const NoteDetailsWrapper = () => {
  const { noteId } = useParams<{ noteId: string }>();
  const { userId } = useAuthStore();

  const { data: [note] = [], isLoading: isLoadingNoteMetadata } =
    useNoteMetadata(noteId);

  if (isLoadingNoteMetadata) return null;

  if (!note) {
    return null;
  }

  const isNoteOwner = note.user_id === userId;

  if (!isNoteOwner && note.status !== "published") return null;

  return <NoteDetails noteId={noteId} />;
};

export const NoteDetails = ({ noteId }: { noteId: string }) => {
  const { data: [note] = [], isLoading: isLoadingNote } = useFetchNoteDetails(
    noteId,
    Boolean(noteId)
  );

  const { data: notes = [], isLoading: isLoadingNotes } = useNotesList({
    userId: note?.users.id,
  });

  if (isLoadingNote || isLoadingNotes) {
    return <AppLoader description="" className="h-[calc(100vh-50px-96px)]" />;
  }

  const noteWithChildren = transformHeirarchicalNotesInFlatList({
    notesResponse: notes,
  }).find((n) => n.id === noteId);

  if (!note) return null;

  // handle archived notes.
  if (note.status === "archived") {
    return (
      <div
        className={cn(
          "grid place-items-center justify-center",
          APP_PAGE_HEIGHT
        )}
      >
        <div className="flex flex-col gap-y-1.5 items-center text-center">
          <LucideIcon
            icon={Archive}
            size={64}
            strokeWidth="1px"
            className="text-text-tertiary-light dark:text-text-tertiary-dark"
          />
          <span className="text-textColors-muted-light dark:text-textColors-muted-dark">
            This note has been archived, <br /> please restore it in the
            settings.
          </span>
        </div>
      </div>
    );
  }

  if (note.template === TemplateEnum.CHEATSHEET) {
    return <CheatsheetTemplate note={noteWithChildren} />;
  } else if (note.template === TemplateEnum.STANDARD) {
    return <BasicTemplate note={note} />;
  }

  return null;
};
