import { LucideIcon as LucideIconType, LucideProps } from "lucide-react";
import { createElement } from "react";
import { cn } from "../utils/utils";

export const LucideIcon = ({
  icon,
  className,
  ...props
}: {
  icon: LucideIconType;
  className?: string;
} & LucideProps) => {
  if (!icon) {
    return null;
  }

  return (
    <>
      {createElement(icon, {
        size: 16,
        className: cn(
          "text-textColors-secondary-light dark:text-textColors-secondary-dark",
          className
        ),
        ...props,
      })}
    </>
  );
};
