import { twMerge } from "tailwind-merge";

export const Prose = ({
  children,
  classNames = "",
}: {
  children: React.ReactNode;
  classNames?: string;
}) => {
  return (
    <article
      className={twMerge(
        "prose dark:prose-invert text-[0.9375rem]",
        "prose-headings:text-textColors-background-light dark:prose-headings:text-textColors-background-dark",
        "prose-h1:scroll-m-20 prose-h1:text-4xl prose-h1:font-extrabold",
        "prose-h2:scroll-m-20 prose-h2:pb-1 prose-h2:text-3xl prose-h2:font-semibold prose-h2:first:mt-0",
        "prose-h3:scroll-m-20 prose-h3:text-2xl prose-h3:font-semibold",
        "prose-h5:scroll-m-20 prose-h5:text-xl prose-h5:font-semibold",
        "leading-6",
        "prose-img:rounded-sm prose-img:border prose-img:border-border-primary-light dark:prose-img:opacity-85 dark:prose-img:border-border-primary-dark",
        "prose-a:font-medium prose-a:underline prose-a:decoration-1 prose-a:decoration-textColors-secondary-light/20 dark:prose-a:decoration-textColors-secondary-dark/40 prose-a:underline-offset-4 hover:prose-a:underline hover:prose-a:opacity-70 dark:hover:prose-a:opacity-80 prose-a:cursor-pointer prose-a:text-[#395BC7] dark:prose-a:text-[#7eb5ff]",
        "prose-pre:bg-bg-secondary-light/70 dark:prose-pre:bg-bg-secondary-dark prose-pre:rounded-md prose-pre:border prose-pre:border-border-primary-light/50 dark:prose-pre:border-border-primary-dark",
        "prose-pre:px-6 prose-pre:py-0",
        "prose-code:border prose-code:border-border-primary-light dark:prose-code:border-border-primary-dark",
        "prose-code:rounded-md prose-code:bg-bg-secondary-light dark:prose-code:bg-bg-secondary-dark",
        'prose-code:text-textColors-background-light dark:prose-code:text-textColors-background-dark prose-code:after:content-[""] prose-code:before:content-[""]',
        "prose-code:px-1.5 prose-code:py-0.5",
        "prose-code:font-normal prose-code:leading-6",
        "prose-code:overflow-x-auto prose-code:overflow-y-hidden prose-code:scrollbar-thin prose-code:scrollbar-thumb-gray-400",
        "prose-p:leading-6 prose-p:mt-[0.5em] prose-p:mb-0",
        "prose-ol:pl-4",
        "prose-li:my-0 prose-li:py-0 prose-li:[&>li]:mt-2",
        `prose-blockquote:mt-6 prose-blockquote:border-l-4 prose-blockquote:font-normal prose-blockquote:pl-4 prose-blockquote:rounded-sm prose-blockquote:not-italic prose-blockquote:border-backgroundColors-interactive-light dark:prose-blockquote:border-backgroundColors-interactive-dark`,
        "prose-code:border-none",
        "prose-hr:border-border-secondary-light dark:prose-hr:border-border-secondary-dark",
        "prose-ul:p-0 prose-ul:flex prose-ul:flex-col prose-ul:gap-[0.5em] prose-ul:my-[0.75rem]",
        "prose-img:rounded-md",
        classNames
      )}
    >
      {children}
    </article>
  );
};
