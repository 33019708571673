import { motion } from "framer-motion";
import { ReactTyped } from "react-typed";
import { TextEditor } from "../../../../text-editor/TextEditor";
import { Prose } from "../../Prose";

export const ViewAnswer = ({ answer }: { answer: string }) => {
  return (
    <>
      {!answer.startsWith(`{"type":"doc",`) ? (
        <ReactTyped
          backSpeed={50}
          onBegin={function noRefCheck() {}}
          onComplete={function noRefCheck() {}}
          onDestroy={function noRefCheck() {}}
          onLastStringBackspaced={function noRefCheck() {}}
          onReset={function noRefCheck() {}}
          onStart={function noRefCheck() {}}
          onStop={function noRefCheck() {}}
          onStringTyped={function noRefCheck() {}}
          onTypingPaused={function noRefCheck() {}}
          onTypingResumed={function noRefCheck() {}}
          startWhenVisible
          strings={[answer]}
          typeSpeed={10}
          showCursor={false}
          typedRef={function noRefCheck() {}}
          className="text-sm animate-in transition-all duration-500 leading-6"
        />
      ) : (
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          transition={{
            duration: 2,
          }}
        >
          <Prose classNames="pl-0">
            <TextEditor
              initialData={answer}
              onUpdate={({ data }: { data: string }) => {}}
              readOnly={true}
              appExtensions={[]}
              ref={null}
            />
          </Prose>
        </motion.div>
      )}
    </>
  );
};
