import {
  Bell,
  Bold,
  ChevronDown,
  ChevronRight,
  Combine,
  Copy,
  File,
  Inbox,
  Italic,
  Link,
  Plus,
  RefreshCcw,
  Star,
  Strikethrough,
  ThumbsUp,
} from "lucide-react";
import { TodoIcon } from "../../../assets/svg/todo.svg";
import { AvatarCap } from "../../../business/icons/Icons";
import { BlockItem } from "../../../ui-components/BlockItem";
import { LucideIcon } from "../../../ui-components/LucideIcon";
import { Separator } from "../../../ui-components/Separator";
import Logo from "../../../utils/logo";
import { cn } from "../../../utils/utils";

export const Showcase = () => {
  return (
    <div className="border z-10 bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark border-border-primary-light dark:border-border-primary-dark rounded-lg shadow-lg">
      <div className="gap-y-2 grid grid-cols-9">
        <div className="col-span-2 h-[600px] rounded-tl-lg border-r border-border-primary-light dark:border-border-primary-dark">
          <div
            className={`h-full bg-backgroundColors-gray-light dark:bg-backgroundColors-gray-dark relative flex flex-col rounded-tl-lg rounded-bl-lg`}
          >
            <div className="flex flex-col p-1 pointer-events-none">
              <BlockItem
                variant="default"
                className="px-3 text-textColors-muted-light dark:text-textColors-muted-dark text-sm"
              >
                <Logo />
              </BlockItem>
              <BlockItem
                variant="default"
                className="px-3 text-textColors-muted-light dark:text-textColors-muted-dark text-sm"
              >
                <LucideIcon
                  icon={Star}
                  className="size-3 stroke-[2.5] stroke-textColors-muted-light dark:stroke-textColors-muted-dark"
                />
                Favorites
              </BlockItem>
              <BlockItem
                variant="default"
                className="px-3 relative text-textColors-muted-light dark:text-textColors-muted-dark text-sm"
              >
                <LucideIcon
                  icon={Inbox}
                  className="size-3 stroke-[2.5] stroke-textColors-muted-light dark:stroke-textColors-muted-dark"
                />
                Archived
              </BlockItem>
              <BlockItem
                variant="default"
                className="px-3 text-textColors-muted-light dark:text-textColors-muted-dark text-sm"
              >
                <LucideIcon
                  icon={Combine}
                  className="size-3 stroke-[2.5] stroke-textColors-muted-light dark:stroke-textColors-muted-dark"
                />
                Integrations
              </BlockItem>
            </div>
            <Separator className="my-2" />
            <div className="h-full flex flex-col justify-between">
              <div className="overflow-auto">
                <div className="flex flex-col gap-y-2">
                  <div className="px-3 flex flex-row items-center justify-between text-textColors-muted-light dark:text-textColors-muted-dark sticky top-0 bg-backgroundColors-gray-light dark:bg-backgroundColors-gray-dark z-5">
                    <span className="text-sm text-textColors-muted-light dark:text-textColors-muted-dark">
                      Notes
                    </span>
                    <LucideIcon
                      icon={Plus}
                      className="size-3 stroke-[2.5] stroke-textColors-muted-light dark:stroke-textColors-muted-dark"
                    />
                  </div>
                  <div className="flex flex-col gap-y-0.5">
                    {mockNotes.map((note, index) => {
                      return (
                        <div key={index} className="flex flex-col">
                          <div
                            className={cn(
                              "flex flex-row items-center gap-1 !text-sm leading-normal px-3 py-1 text-textColors-muted-light dark:text-textColors-muted-dark"
                            )}
                          >
                            <LucideIcon
                              icon={File}
                              className="size-3 stroke-[2.5] stroke-textColors-muted-light dark:stroke-textColors-muted-dark"
                            />
                            <span className="truncate text-textColors-muted-light dark:text-textColors-muted-dark text-sm">
                              {note.label}
                            </span>
                          </div>
                          {index === 0 && (
                            <div className="flex flex-col gap-1 p-1 pl-6 before:content-[''] before:absolute before:left-4 before:h-16 before:w-[0.75px] before:bg-border-primary-light dark:before:bg-border-primary-dark">
                              <BlockItem
                                variant={
                                  index === 0 ? "interactive" : "default"
                                }
                                className={cn(
                                  "px-3 pointer-events-none rounded-md bg-opacity-80"
                                )}
                                key={index}
                              >
                                <span
                                  className={cn(
                                    "truncate !text-sm leading-normal font-medium",
                                    index === 0
                                      ? ""
                                      : "text-textColors-muted-light dark:text-textColors-muted-dark"
                                  )}
                                >
                                  Atomic Orbitals
                                </span>
                              </BlockItem>
                              <span
                                className={cn(
                                  "truncate !text-sm leading-normal px-3 py-1 text-textColors-muted-light dark:text-textColors-muted-dark"
                                )}
                              >
                                P-block
                              </span>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col col-span-7">
          <nav className="border-b px-2 border-border-primary-light dark:border-border-primary-dark rounded-tl-lg rounded-tr-lg">
            <div className="flex items-center justify-between px-4 py-2">
              <div className="flex gap-4 items-center">
                <div className="flex items-center">
                  <LucideIcon
                    icon={File}
                    className="size-3 stroke-[2.5] stroke-textColors-muted-light dark:stroke-textColors-muted-dark"
                  />
                  <span className="text-sm font-medium text-textColors-muted-light dark:text-textColors-muted-dark ml-1">
                    Inorganic Chemistry
                  </span>
                  <LucideIcon
                    icon={ChevronRight}
                    className="size-3 stroke-[2.5] mx-2 stroke-textColors-muted-light dark:stroke-textColors-muted-dark"
                  />
                  <span className="text-sm font-medium text-textColors-muted-light dark:text-textColors-muted-dark">
                    Atomic Orbitals
                  </span>
                  <svg
                    className="ml-3"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="#9c9da1"
                    role="img"
                    focusable="false"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M3 6.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm5 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm5 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z"></path>
                  </svg>
                </div>
              </div>
              <div className="flex flex-row items-center gap-4">
                <LucideIcon
                  icon={Bell}
                  className="size-3 stroke-[2.5] stroke-textColors-muted-light dark:stroke-textColors-muted-dark"
                />
                <AvatarCap className="size-4 grayscale" />
              </div>
            </div>
          </nav>
          <div className="grid grid-cols-7">
            <NoteShowcase />
            <div className="col-span-2 flex flex-col gap-4 p-4 -ml-14 border-border-primary-light dark:border-border-primary-dark h-full">
              <div className="border border-border-primary-light dark:border-border-primary-dark rounded-md z-5 bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark">
                <div className="p-4 flex flex-col gap-2">
                  <div className="flex flex-row items-center gap-1">
                    <div className="h-2 w-3.5 rounded-lg bg-ai-light dark:bg-ai-dark"></div>
                  </div>
                  <ul>
                    <li className="flex flex-col gap-1">
                      <span className="text-sm">Define atomic orbital</span>
                      <span className="text-sm leading-6 text-textColors-muted-light dark:text-textColors-muted-dark">
                        An atomic orbital is a region where an electron is
                        likely found.
                      </span>
                      <div className="mt-6 flex flex-row items-center gap-4">
                        <LucideIcon
                          icon={Copy}
                          className="size-3 stroke-[2.5] stroke-textColors-muted-light dark:stroke-textColors-muted-dark"
                        />
                        <LucideIcon
                          icon={ThumbsUp}
                          className="size-3 stroke-[2.5] stroke-textColors-muted-light dark:stroke-textColors-muted-dark"
                        />
                        <LucideIcon
                          icon={RefreshCcw}
                          className="size-3 stroke-[2.5] stroke-textColors-muted-light dark:stroke-textColors-muted-dark"
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="border border-border-primary-light dark:border-border-primary-dark rounded-md z-10 bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark">
                <div className="flex overflow-hidden items-center justify-around gap-2 rounded-md bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark">
                  <div className="flex items-center gap-3 py-1 pl-2">
                    <LucideIcon icon={Bold} size={15} />
                    <LucideIcon icon={Italic} size={15} />
                    <LucideIcon icon={Link} size={15} />
                    <LucideIcon icon={Strikethrough} size={15} />
                  </div>
                  <Separator
                    orientation="vertical"
                    className="border-red-500 h-8"
                  />
                  <div className="py-1 flex items-center gap-1 text-sm">
                    <div className="flex flex-row items-center gap-1">
                      <TodoIcon className="size-3" />
                    </div>
                    Reflect
                  </div>
                  <Separator
                    orientation="vertical"
                    className="border-red-500 h-8"
                  />
                  <div className="px-2 py-1 flex items-center gap-1">
                    A<LucideIcon icon={ChevronDown} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const NoteShowcase = () => {
  return (
    <div className="h-full px-2 md:p-6 w-11/12 col-span-5">
      <div className="w-fit px-3 py-2 rounded-lg bg-backgroundColors-interactive-light dark:bg-backgroundColors-interactive-dark">
        <LucideIcon
          icon={File}
          className="size-5 stroke-[2.5] stroke-textColors-foreground-light dark:stroke-textColors-foreground-dark"
        />
      </div>
      <div className="font-medium mt-4 text-xl tracking-[-0.37px]">
        Atomic Orbitals
      </div>
      <div className="mt-4">
        <div className="mt-4 relative">
          <span className="border px-0.5 rounded-sm border-textColors-foreground-light/20 dark:border-textColors-foreground-dark/20 bg-backgroundColors-interactive-light dark:bg-backgroundColors-interactive-dark text-textColors-background-light dark:text-textColors-background-dark">
            An atomic orbital
          </span>{" "}
          <span className="leading-6 text-textColors-muted-light dark:text-textColors-muted-dark text-balance">
            is a region around an atom's nucleus where the probability of
            finding an electron is highest.
          </span>
          <Separator className="my-4 w-[90%] bg-border-primary-light/70" />
          <p className="mt-4 relative text-textColors-muted-light dark:text-textColors-muted-dark">
            The electron configuration that forms the electron cloud of a
            multi-electron atom is the result of simpler{" "}
            <span className="border px-0.5 rounded-sm border-textColors-foreground-light/20 dark:border-textColors-foreground-dark/20 bg-backgroundColors-interactive-light dark:bg-backgroundColors-interactive-dark text-textColors-background-light dark:text-textColors-background-dark">
              hydrogen-like
            </span>{" "}
            <span className="leading-6 text-textColors-muted-light dark:text-textColors-muted-dark">
              atomic orbitals.
            </span>
          </p>
        </div>
        <Separator className="my-4 w-[90%] bg-border-primary-light/70" />
        <div className="[&>*]:leading-6 [&>*]:text-textColors-muted-light flex flex-col gap-4">
          <span className="text-balance">
            There are various atomic models, called models of an atom.{" "}
          </span>
          <ul className="list-disc pl-6 leading-6 [&>*]:leading-6 [&>*]:text-textColors-muted-light flex flex-col gap-2">
            <li>Plum pudding model</li>
            <li>Bohr's model</li>
          </ul>
          <Separator className="my-4 w-[90%] bg-border-primary-light/70" />
          <span className="-mt-2">
            Niels Bohr proposed a new model, wherein electrons orbited the
            nucleus with classical periods.
          </span>
        </div>
      </div>
    </div>
  );
};

const mockNotes = [
  {
    label: `Inorganic Chemistry`,
    icon: `📚`,
  },
  {
    label: `Databases`,
    icon: `📝`,
  },
  {
    label: `vim cheatsheet`,
    icon: `📄`,
  },
];
