import { useColorMode } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { RootLayout } from "../../layouts/Root.layout";
import { Button } from "../../ui-components/Button";
import { cn } from "../../utils/utils";
import { Features } from "./components/FeaturesList";
import { Flashcards } from "./components/Flashcard";
import { Reflect } from "./components/Reflect";
import { Showcase } from "./components/Showcase";

export const LandingPage = () => {
  const router = useHistory();
  const { colorMode } = useColorMode();
  const isLight = colorMode === "light";

  const subTextClasses =
    "max-w-[48ch] mt-2 tracking-normal text-balance text-textColors-muted-light dark:text-textColors-secondary-dark text-lg leading-[125%] text-center";

  return (
    <RootLayout>
      <>
        <section className={cn("m-0 p-0 border-0 relative overflow-hidden")}>
          <div className="section-content px-[1.25rem] md:px-[1.875rem] lg:px-[2.5rem]">
            <div className="container w-full max-w-[var(--page-width)] mx-auto">
              <div
                className={cn(
                  "flex flex-col md:items-start gap-4 w-full",
                  "pt-[3rem] pb-[2.5rem]",
                  "md:pt-[4rem] md:pb-[5rem]",
                  "lg:pt-[10rem] lg:pb-[7rem]"
                )}
              >
                <div className="flex flex-col items-start gap-4">
                  {isLight ? (
                    <a
                      href="https://www.producthunt.com/posts/envise-2?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-envise&#0045;2"
                      target="_blank"
                    >
                      <img
                        src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=629061&theme=light"
                        alt="Envise - Turn&#0032;complex&#0032;notes&#0032;into&#0032;lasting&#0032;memory | Product Hunt"
                        style={{
                          width: "250px",
                          height: "54px",
                        }}
                        width="250"
                        height="54"
                      />
                    </a>
                  ) : (
                    <a
                      href="https://www.producthunt.com/posts/envise-2?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-envise&#0045;2"
                      target="_blank"
                    >
                      <img
                        src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=629061&theme=dark"
                        alt="Envise - Turn&#0032;complex&#0032;notes&#0032;into&#0032;lasting&#0032;memory | Product Hunt"
                        style={{
                          width: "250px",
                          height: "54px",
                        }}
                        width="250"
                        height="54"
                      />
                    </a>
                  )}
                  <span className="text-3xl md:text-[48px] max-w-[20ch] font-semibold !leading-[110%]">
                    Turn complex notes into lasting memory
                  </span>
                  <p
                    className={cn(
                      subTextClasses,
                      "text-left md:max-w-[90ch] leading-7"
                    )}
                  >
                    Envise helps you reflect on ideas and retain them for the
                    long term.
                  </p>
                  <Button
                    className="h-[40px] py-0 px-8 text-lg w-fit mt-8"
                    onClick={() => router.push(`/signup`)}
                  >
                    Get started
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={cn("m-0 p-0 border-0 relative overflow-hidden")}>
          <div className="section-content px-[1.25rem] md:px-[1.875rem] lg:px-[2.5rem]">
            <div className="container w-full max-w-[var(--page-width)] mx-auto">
              <div
                className={cn(
                  "padding-medium",
                  "py-[2.25rem] md:py-[3.25rem] lg:py-[3.75rem] hidden md:block"
                )}
              >
                <Showcase />
              </div>
            </div>
          </div>
        </section>
        <section
          className={cn(
            "m-0 p-0 border-0 relative overflow-hidden pb-40 lg:pb-28 -mt-8"
          )}
        >
          <div className="section-content px-[1.25rem] md:px-[1.875rem] lg:px-[2.5rem]">
            <div className="container w-full max-w-[var(--page-width)] mx-auto">
              <div
                className={cn(
                  "padding-medium",
                  "py-[2.25rem] md:py-[3.25rem] lg:py-[3.75rem]"
                )}
              >
                <Reflect />
              </div>
            </div>
          </div>
        </section>
        <section className={cn("m-0 p-0 border-0 relative overflow-hidden")}>
          <div className="section-content px-[1.25rem] md:px-[1.875rem] lg:px-[2.5rem]">
            <div className="container w-full max-w-[var(--page-width)] mx-auto">
              <div
                className={cn(
                  "padding-medium",
                  "py-[2.25rem] md:py-[3.25rem] lg:py-[3.75rem]"
                )}
              >
                <Features />
              </div>
            </div>
          </div>
        </section>
        <section
          className={cn(
            "m-0 p-0 border-0 relative overflow-hidden py-40 lg:py-32"
          )}
        >
          <div className="section-content px-[1.25rem] md:px-[1.875rem] lg:px-[2.5rem]">
            <div className="container w-full max-w-[var(--page-width)] mx-auto">
              <div
                className={cn(
                  "padding-medium",
                  "py-[2.25rem] md:py-[3.25rem] lg:py-[3.75rem]"
                )}
              >
                <Flashcards />
              </div>
            </div>
          </div>
        </section>
      </>
    </RootLayout>
  );
};
