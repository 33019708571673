import { getAIInstance } from "../../../pages-v2/helpers";
import { SelectedAiInstance } from "../../../pages-v2/hooks/ai/types";
import { useGetConnectedIntegrations } from "../../../pages-v2/hooks/queries/useGetConnectedIntegrations";
import { integrationsMap } from "../../../pages-v2/integrations/Integrations";
import { useAuthStore } from "../../../store/auth";
import { Button } from "../../../ui-components/Button";

export const AiRevisionOptions = ({
  onSelectAiInstance,
}: {
  onSelectAiInstance: (selectedAiInstance: SelectedAiInstance) => void;
}) => {
  const { userId } = useAuthStore();
  const {
    data: connectedIntegrations = [],
    isLoading: isLoadingConnectedIntegrations,
  } = useGetConnectedIntegrations({
    userId,
  });

  return (
    <>
      {isLoadingConnectedIntegrations && (
        <div className="flex flex-col gap-2 px-4">
          <div className="animate-pulse h-5 w-full rounded bg-backgroundColors-gray-light dark:bg-backgroundColors-gray-dark"></div>
          <div className="animate-pulse h-5 w-full rounded bg-backgroundColors-gray-light dark:bg-backgroundColors-gray-dark"></div>
        </div>
      )}
      {connectedIntegrations.map((connectedIntegration) => {
        const integrationMeta = integrationsMap.find(
          (integration) =>
            integration.id === connectedIntegration.metadata.integrationType
        );

        return (
          <Button
            key={connectedIntegration.id}
            className="flex items-center gap-2 justify-start"
            variant="ghost"
            onClick={() => {
              // create ai instance.
              const instance = getAIInstance({
                activeIntegration: connectedIntegration,
              });

              onSelectAiInstance({
                instance,
                integration: connectedIntegration,
              });
            }}
          >
            <div className="shrink-0 size-5">{integrationMeta.icon}</div>
            <span className="truncate">{connectedIntegration.name}</span>
          </Button>
        );
      })}
    </>
  );
};
