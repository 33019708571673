import dayjs from "dayjs";
import { ChevronDown, Plus, SlidersHorizontal } from "lucide-react";
import React, { useState } from "react";
import { toast } from "sonner";
import { z } from "zod";
import { TNotesList } from "../../../pages-v2/api/fetchNotes";
import { convertLabelToId, EmptyState } from "../../../pages-v2/global-helpers";
import { useCreateNote } from "../../../pages-v2/hooks/mutations/useCreateNote";
import { TNotesResponseWithChildren } from "../../../pages-v2/hooks/queries/useNotesList";
import { noteCreationFormSchema } from "../../../pages-v2/notes-sidebar/NoteCreationForm";
import { ERROR_MESSAGE } from "../../../utils/constants";
import { getAvatarIcon } from "../../../utils/helpers";
import { TemplateEnum } from "../../../utils/types";
import { DAYJS_DEFAULT_FORMAT } from "../../../utils/utils";
import { Button } from "../../Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "../../DropdownMenu";
import { LucideIcon } from "../../LucideIcon";
import { Popover, PopoverContent, PopoverTrigger } from "../../Popover";
import { Separator } from "../../Separator";
import { CheatsheetEditorDialog } from "./CheatsheetEditor";
import { CheatsheetFinder } from "./CheatsheetFinder";
import { CheatsheetList } from "./CheatsheetList";
import { CheatsheetTemplateDisplayOptions } from "./CheatsheetTemplateDisplayOptions";

export const breakpointColumnsObj = {
  default: 3,
  1100: 3,
  700: 2,
  500: 1,
};

export const CheatsheetTemplate = ({
  note,
}: {
  note: TNotesResponseWithChildren;
}) => {
  const [defaultMasonryColumns, setDefaultMasonryColumns] = useState(3);

  return (
    <div className="p-2 lg:p-10">
      <div className="flex flex-col gap-y-4 h-full transition-all duration-100 lg:px-8">
        <div className="flex flex-col mb-4">
          <h2
            className="tracking-tight m-0 items-center gap-x-1 flex flex-wrap font-bold !text-2xl"
            id={convertLabelToId({ label: note.title })}
          >
            {note.title}{" "}
          </h2>
          <span>{note.description}</span>
          <div className="mt-6 flex flex-row flex-wrap items-center gap-8 md:gap-16">
            {note.users.name && (
              <div className="flex flex-col">
                <span className="text-sm text-textColors-muted-light dark:text-textColors-muted-dark">
                  Written by
                </span>

                <div className="flex flex-row items-center">
                  <span>
                    {getAvatarIcon({
                      avatar: note.users.avatar as any,
                      className: "size-6 [&>svg]:size-4 mt-0.5",
                    })}
                  </span>
                  {note.users.name}
                </div>
              </div>
            )}
            {note.created_at && (
              <div className="flex flex-col">
                <span className="text-sm text-textColors-muted-light dark:text-textColors-muted-dark">
                  Published on
                </span>
                <div>{dayjs(note.created_at).format(DAYJS_DEFAULT_FORMAT)}</div>
              </div>
            )}
          </div>
        </div>
        <Separator className="mb-4" />
        <CheatsheetTemplateActions
          notes={note.contentNotes}
          columnsView={defaultMasonryColumns}
          setColumnsView={setDefaultMasonryColumns}
          parentNote={note}
        />
        {note.contentNotes.length ? (
          <CheatsheetList note={note} layoutColumns={defaultMasonryColumns} />
        ) : (
          <div className="grid place-items-center grow">
            <EmptyState
              iconComponent={<></>}
              cta={`There are currently no cheatsheets.
          Please start a new one.`}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export const CHEATSHEET_LEVEL = -1;

const CheatsheetTemplateActions = ({
  notes,
  parentNote,
  columnsView,
  setColumnsView,
}: {
  notes: TNotesList;
  parentNote: TNotesResponseWithChildren;
  columnsView: number;
  setColumnsView: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const [openNoteCreator, setOpenNoteCreator] = useState(false);
  const [openCheatsheetFinder, setOpenCheatsheetFinder] = useState(false);

  const { mutateAsync: createNote, isLoading: isLoadingCreateNote } =
    useCreateNote();

  const onCreateNoteFormSubmit = async ({
    values,
    body = ``,
  }: {
    values: z.infer<typeof noteCreationFormSchema>;
    body: string;
  }) => {
    const { title } = values;

    await createNote(
      {
        title,
        body: body ?? ``,
        template: TemplateEnum.STANDARD,
        description: null,
        level: CHEATSHEET_LEVEL,
        userId: parentNote.users.id,
        parentId: parentNote.id,
        isContentNote: true,
        icon: ``,
      },
      {
        onError: () => {
          toast.error(ERROR_MESSAGE);
        },
      }
    );

    setOpenNoteCreator(false);
  };

  return (
    <>
      <div className="flex flex-row items-center justify-between gap-4">
        <div className="flex flex-row gap-x-2">
          <DropdownMenu
            open={openCheatsheetFinder}
            onOpenChange={setOpenCheatsheetFinder}
          >
            <DropdownMenuTrigger asChild>
              <Button
                variant="outline"
                size="sm"
                className="gap-1 border-dashed flex"
              >
                Find
                <LucideIcon icon={ChevronDown} size={14} />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start">
              <CheatsheetFinder notes={notes} />
            </DropdownMenuContent>
          </DropdownMenu>
          {/* <Button
            variant="outline"
            size="sm"
            className="gap-1 pointer-events-none border-dashed"
          >
            {parentNote.users.name}
            <Separator orientation="vertical" className="mx-2 h-4" />
            {dayjs(parentNote.created_at).format(DAYJS_MINI_FORMAT)}
          </Button> */}
        </div>
        <div className="flex flex-row items-center gap-4">
          <Popover>
            <PopoverTrigger
              asChild
              className="hidden md:flex flex-row gap-1 items-center"
            >
              <Button variant="outline" size="sm" className="gap-1">
                <LucideIcon icon={SlidersHorizontal} size={14} />
                Display
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[250px]" align="end">
              <CheatsheetTemplateDisplayOptions
                onColumsViewChange={({ column }) => {
                  setColumnsView(column);
                }}
                columnView={columnsView}
              />
            </PopoverContent>
          </Popover>
          <Button
            variant="outline"
            size="sm"
            className="gap-1"
            onClick={() => {
              setOpenNoteCreator(true);
            }}
          >
            <LucideIcon icon={Plus} size={14} />
            Add new
          </Button>
        </div>
      </div>
      <CheatsheetEditorDialog
        title={`Create`}
        cta={`Create`}
        note={null}
        defaultValues={null}
        open={openNoteCreator}
        setOpen={setOpenNoteCreator}
        description={
          <>
            Add a new cheatsheet for <span>"{parentNote.title}"</span>
          </>
        }
        onSubmit={({ values, body }) => {
          onCreateNoteFormSubmit({ values, body });
        }}
        isLoading={isLoadingCreateNote}
      />
    </>
  );
};
