import { useHistory } from "react-router-dom";
import { RootLayout } from "../../layouts/Root.layout";
import { Button } from "../../ui-components/Button";
import Logo from "../../utils/logo";

const EmailVerificationSuccess = () => {
  const router = useHistory();

  return (
    <RootLayout>
      <div className="grid place-items-center justify-center h-[calc(100vh-200px)]">
        <div className="flex flex-col gap-12 items-center text-center">
          <Logo className="[&>svg]:size-12" />
          <div className="flex flex-col gap-6">
            <div className="flex flex-col flex-wrap gap-4">
              <span>Your email has been verified successfully.</span>
            </div>
            <Button
              variant="outline"
              onClick={() => {
                router.push("/login");
              }}
              className="w-fit mx-auto"
            >
              Login
            </Button>
          </div>
        </div>
      </div>
    </RootLayout>
  );
};

export default EmailVerificationSuccess;
