import { useColorMode } from "@chakra-ui/react";
import { motion, useInView } from "framer-motion";
import { ArrowLeft, Command, CornerDownLeft, Leaf } from "lucide-react";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { ReactTyped } from "react-typed";
import { TodoIcon } from "../../../assets/svg/todo.svg";
import { GptIcon } from "../../../pages-v2/integrations/gpt.svg";
import { AIReflectIcon } from "../../../text-editor/components/revision-marker-generator/AIReflectIcon";
import { Button } from "../../../ui-components/Button";
import { LucideIcon } from "../../../ui-components/LucideIcon";
import { cn } from "../../../utils/utils";

const TOOLBAR_DELAY = 2;
const BUTTON_HIGHLIGHT_DELAY = 4;

export const Reflect = () => {
  return (
    <div className="ai flex flex-col gap-8 items-center relative pt-8">
      <div
        className={cn(
          "inset-0 -z-10 absolute top-0 bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:32px_32px]",
          "size-full left-[50%] top-[50%] -translate-x-1/2 -translate-y-1/2"
        )}
      >
        <div
          className={cn(
            "absolute -z-10 inset-0 bg-[radial-gradient(circle,rgba(255,255,255,0)_40%,rgba(255,255,255,0.8)_75%,rgba(255,255,255,1)_100%)]",
            "dark:bg-[radial-gradient(circle,rgba(16,16,16,0)_20%,rgba(16,16,16,0.5)_70%,rgba(16,16,16,0.8)_100%)]"
          )}
        />
      </div>
      <AIReflectIcon />
      <div className="flex flex-col gap-4 items-center z-10">
        <h2 className="text-3xl sm:text-3xl md:text-[40px] md:w-[400px] font-semibold !leading-[110%] text-center">
          Synthesise your notes with AI
        </h2>
        <p className="max-w-[300px] md:max-w-[60%] text-textColors-muted-light dark:text-textColors-secondary-dark leading-6 text-center">
          Integrate your own AI service to reflect on your notes and summarise
          complex topics.
        </p>
      </div>
      <ReflectUI />
    </div>
  );
};

export const ReflectUI = () => {
  /**
   * 1. display a paragraph
   * 2. select the paragraph
   * 3. open the toolbar
   * 4. select ai from the toolbar -- all of this happens like after 2s
   * 5. open a new dialog and show reflections
   *
   * we can either add delay for each of the animations, which will be like 2, 4, 6, 8, 10
   * and have one state to trigger all the required animations.
   *
   **/

  const paragraph = `In Redux we used to connect and hydrate our consumer components. With zustand, we can import the store using a simple react hook.`;
  const { colorMode } = useColorMode();
  const [showDialog, setShowDialog] = useState(false);

  const isLight = colorMode === "light";

  const [animation, triggerAnimation] = useState(false);

  const ref = useRef(null);
  const isInView = useInView(ref, { amount: 0.5 });

  let timerIdRef: MutableRefObject<NodeJS.Timeout> = useRef(null);
  const animationEndTimerRef: MutableRefObject<NodeJS.Timeout> = useRef(null);

  const stopAnimation = () => {
    setShowDialog(false);
    triggerAnimation(false);

    const animationTimer = animationEndTimerRef?.current;

    if (animationTimer) {
      clearTimeout(animationTimer);
    }

    if (timerIdRef.current) {
      clearTimeout(timerIdRef.current);
    }
  };

  useEffect(() => {
    if (isInView) {
      timerIdRef.current = setTimeout(() => {
        triggerAnimation(true);
      }, 1000);
    }

    const animationTimer = animationEndTimerRef?.current;

    return () => {
      if (timerIdRef.current) {
        clearTimeout(timerIdRef.current);
      }

      if (animationTimer) {
        clearTimeout(animationTimer);
      }
    };
  }, [isInView]);

  return (
    <div
      className={cn(
        "bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark -mt-[10px] p-2.5 pb-[18px] w-full  md:w-[36rem] ml-1",
        "border border-border-primary-light dark:border-border-primary-dark rounded-3xl"
      )}
      ref={ref}
    >
      <div
        className={cn(
          "flex bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark flex-col gap-4 relative text-start z-10",
          "border border-border-primary-light dark:border-border-primary-dark rounded-2xl p-6 px-8 pb-12"
        )}
      >
        <div className="flex flex-row gap-4">
          <div
            onClick={() => {
              if (!animation) {
                triggerAnimation(true);
              }
            }}
            className="flex cursor-default flex-row items-center gap-1 bg-backgroundColors-gray-light dark:bg-backgroundColors-gray-dark px-1 py-0.5 rounded text-sm border-[0.1px] border-border-primary-light dark:border-border-primary-dark"
          >
            <TodoIcon className="size-3 ease-out stroke-[3]" />
            Reflect
          </div>
          <div
            onClick={() => {
              if (!animation) {
                triggerAnimation(true);
              }
            }}
            className="flex cursor-default flex-row items-center gap-1 bg-backgroundColors-gray-light dark:bg-backgroundColors-gray-dark px-1 py-0.5 rounded text-sm border-[0.1px] border-border-primary-light dark:border-border-primary-dark"
          >
            <LucideIcon
              icon={Leaf}
              className="size-3 stroke-[3] stroke-summary-light dark:stroke-summary-dark"
            />
            Summarise
          </div>
        </div>
        <span>
          <motion.span
            onClick={() => {
              clearTimeout(timerIdRef.current);
              triggerAnimation(true);
              setShowDialog(false);
            }}
            className={cn(
              "text-sm cursor-default px-0.5 rounded-sm border",
              animation
                ? "border-border-primary-light dark:border-border-primary-dark"
                : "border-transparent"
            )}
            animate={
              animation
                ? {
                    background: isLight ? "#E8E8E8" : "#CECFCF",
                    color: isLight ? "inherit" : "#111",
                  }
                : {
                    background: isLight ? "#FFF" : "#101010",
                    color: "inherit",
                  }
            }
            transition={{
              duration: 0.4,
            }}
          >
            {paragraph}{" "}
            <span className="text-textColors-muted-light dark:text-textColors-muted-dark">
              {/* -- Click to reflect */}
            </span>
          </motion.span>
        </span>
        {animation && (
          <motion.div
            className={cn(
              "toolbar absolute top-6 md:left-52 bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark border border-border-primary-light dark:border-border-primary-dark p-0.5 rounded-lg",
              "shadow-lg blur-[0.1px]"
            )}
            initial={{
              opacity: 0,
            }}
            animate={
              showDialog
                ? {
                    opacity: 0,
                    transition: {
                      duration: 0.8,
                    },
                    cursor: "default",
                    pointerEvents: "none",
                  }
                : {
                    opacity: 1,
                  }
            }
            transition={{
              duration: 0.4,
              delay: TOOLBAR_DELAY,
            }}
          >
            <div className="flex flex-row gap-0.5">
              <Button variant="ghost" size="icon">
                h1
              </Button>
              <Button variant="ghost" size="icon">
                h2
              </Button>
              <Button variant="ghost" size="icon">
                h3
              </Button>
              <motion.div
                animate={{
                  background: isLight ? "#F7FAFE" : "#212223",
                }}
                transition={{
                  duration: 0.4,
                  delay: BUTTON_HIGHLIGHT_DELAY,
                }}
                onAnimationComplete={() => {
                  setTimeout(() => {
                    setShowDialog(true);
                  }, 2000);
                }}
                exit={{
                  transition: {
                    duration: 1,
                  },
                }}
                className="flex flex-col items-center justify-center size-8 rounded"
              >
                <div className="[&>div]:size-3.5 [&>div>svg]:size-2 [&>div]:shadow-none dark:[&>div]:shadow-none [&>div]:rounded">
                  <AIReflectIcon />
                </div>
              </motion.div>
            </div>
          </motion.div>
        )}
        {showDialog && (
          <div className="absolute top-[84px] md:left-16 -left-0">
            <div className="relative">
              {/* Dummy background */}
              <motion.div
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 1,
                    delay: 1,
                  },
                }}
                className="absolute -left-6 sm:left-8 md:left-0 rounded-2xl -z-10 bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark h-52 w-72 md:w-96 backdrop-blur-xl"
              >
                <p className="pt-4">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
              </motion.div>
              <motion.div
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 1,
                  },
                }}
                className={cn(
                  "shadow-lg absolute flex flex-col justify-between border border-border-primary-light dark:border-border-primary-dark rounded-2xl",
                  "w-72 md:w-96 h-64 md:h-52",
                  "bg-backgroundColors-gray-light/70 dark:bg-backgroundColors-gray-dark/50 backdrop-blur-xl",
                  "-left-6 sm:left-8 md:left-0"
                )}
              >
                <div className="flex flex-row items-center gap-2 border-b px-4 py-3">
                  <LucideIcon icon={ArrowLeft} className="size-5 p-1" />
                  <div className="flex flex-col">
                    <span className="text-sm text-textColors-muted-light dark:text-textColors-muted-dark">
                      Reflect on this paragraph...
                    </span>
                  </div>
                </div>
                <div className="flex flex-col gap-1 m-5 -mt-3 pb-2">
                  <motion.div
                    initial={{
                      opacity: 0,
                    }}
                    animate={{
                      opacity: 1,
                    }}
                    transition={{
                      duration: 1,
                      delay: 2,
                    }}
                    className="text-sm font-medium"
                  >
                    What is main advantage of zustand over redux?
                  </motion.div>
                  <motion.div className="grow h-8 rounded flex">
                    <ReactTyped
                      backSpeed={50}
                      onBegin={function noRefCheck() {}}
                      onComplete={function noRefCheck() {}}
                      onDestroy={function noRefCheck() {}}
                      onLastStringBackspaced={function noRefCheck() {}}
                      onReset={function noRefCheck() {}}
                      onStart={function noRefCheck() {}}
                      onStop={function noRefCheck() {}}
                      onStringTyped={function noRefCheck() {
                        animationEndTimerRef.current = setTimeout(() => {
                          stopAnimation();
                        }, 10000);
                      }}
                      onTypingPaused={function noRefCheck() {}}
                      onTypingResumed={function noRefCheck() {}}
                      startWhenVisible
                      strings={[
                        "The main advantage of zustand appears to be simplicity, being able to directly import the store using a simple react hook.",
                      ]}
                      startDelay={3000}
                      typeSpeed={10}
                      showCursor={false}
                      typedRef={function noRefCheck() {}}
                      className="[&>span]:text-sm [&>span]:leading-6"
                    />
                  </motion.div>
                </div>
                <div className="flex flex-row items-center gap-3 px-4 py-3 justify-between">
                  <div className="flex flex-row items-center gap-1">
                    <GptIcon className="size-4" />
                  </div>
                  <div className="flex flex-row items-center gap-4">
                    <Button
                      size="sm"
                      className="gap-1 h-7 cursor-default"
                      onClick={() => {
                        stopAnimation();
                      }}
                    >
                      <LucideIcon
                        icon={Command}
                        className="size-3 stroke-white"
                      />
                      <LucideIcon
                        icon={CornerDownLeft}
                        className="size-3 stroke-white"
                      />
                    </Button>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
