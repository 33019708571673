import { FC, PropsWithChildren } from "react";
import { useHistory } from "react-router-dom";
import {
  getAiCompletionParams,
  getIsActiveIntegration,
} from "../../../pages-v2/helpers";
import { SelectedAiInstance } from "../../../pages-v2/hooks/ai/types";
import { useFetchAICompletions } from "../../../pages-v2/hooks/ai/useFetchAiCompletions";
import { useGetConnectedIntegrations } from "../../../pages-v2/hooks/queries/useGetConnectedIntegrations";
import { useAuthStore } from "../../../store/auth";
import { Button } from "../../../ui-components/Button";
import { getAvatarIcon } from "../../../utils/helpers";
import { cn } from "../../../utils/utils";
import { AiMarkerForm } from "./AiMarkerForm";
import { AIReflectIcon } from "./AIReflectIcon";

type AIMarkerGeneratorProps = {
  selectedText: string;
  onDelete: () => void;
  onSubmit: (values: { question: string; answer: string }[]) => void;
  onClose: () => void;
  aiInstance: SelectedAiInstance;
};

export const AILayout: PropsWithChildren<FC> = ({ children }) => {
  return (
    <div className="mt-2 flex flex-col gap-10 pr-8 items-center justify-start pt-6 h-full">
      <AIReflectIcon />
      {children}
    </div>
  );
};

export const OpenaiMarkerGenerator: FC<AIMarkerGeneratorProps> = ({
  selectedText,
  onDelete,
  onSubmit,
  aiInstance,
  onClose,
}) => {
  const router = useHistory();

  const {
    data: openAIResponse,
    isLoading: isLoadingOpenAIResponse,
    isError: isErrorOpenAIResponse,
    isFetching: isFetchingOpenAIResponse,
    error: errorOpenAIResponse,
    refetch: refetchOpenAIResponse,
  } = useFetchAICompletions(
    getAiCompletionParams({
      integrationType: aiInstance.integration?.metadata?.integrationType as
        | "openai"
        | "mistralai",
      modelId: aiInstance.integration?.metadata?.model?.id,
      aiInstance: aiInstance.instance,
      selectedText,
    })
  );

  if (isLoadingOpenAIResponse || isFetchingOpenAIResponse) {
    return (
      <AILayout>
        <div className="w-full flex flex-col md:px-8 gap-4 text-left">
          <div className="flex flex-col items-start gap-1">
            {getAvatarIcon({ avatar: "avatar-work", className: "size-5" })}

            <p className="text-sm leading-6">
              Create a reflection based on{" "}
              <span className="font-medium">{`{{ selectedText }}`}</span>
            </p>
          </div>
          <div className={cn("flex flex-row gap-2 items-start pt-1")}>
            <div className="flex flex-col gap-2 w-full">
              <div className="flex flex-col gap-4 animate-pulse w-full">
                <div className="bg-backgroundColors-interactive-light dark:bg-backgroundColors-interactive-dark h-4 rounded w-4/5"></div>
                <div className="bg-backgroundColors-interactive-light dark:bg-backgroundColors-interactive-dark h-4 rounded w-2/5"></div>
              </div>
            </div>
          </div>
        </div>
      </AILayout>
    );
  }

  if (!aiInstance || !aiInstance?.instance || !aiInstance?.integration) {
    return (
      <AILayout>
        <div className="flex flex-col items-center gap-6 w-7/12">
          <div className="rounded-md text-center leading-6 flex flex-col gap-1">
            <span className="font-medium">No AI integration</span>
            <div className="text-textColors-muted-light dark:text-text-muted-dark text-sm">
              Plase connect an AI integration to continue
            </div>
          </div>

          <div className="flex flex-row items-center gap-2">
            <Button
              onClick={() => {
                router.push("/integrations");
              }}
              variant="outline"
            >
              Create new
            </Button>
          </div>
        </div>
      </AILayout>
    );
  }

  if (isErrorOpenAIResponse) {
    return (
      <AILayout>
        <div className="flex flex-col items-center gap-6 w-7/12">
          <div className="rounded-md text-center leading-6 flex flex-col gap-1">
            <span className="font-medium">Something's wrong</span>
            <div className="text-textColors-muted-light dark:text-text-muted-dark text-sm text-left mt-4 h-32 rounded overflow-auto p-4 bg-backgroundColors-interactive-light dark:bg-backgroundColors-interactive-dark">
              {(errorOpenAIResponse as any).message ||
                `Failed to reach the servers. Please try again later`}
            </div>
          </div>
          <div className="flex flex-row items-center gap-2">
            <Button
              onClick={() => {
                onClose();
              }}
              variant="ghost"
              className="gap-1"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (aiInstance?.integration) {
                  refetchOpenAIResponse();
                }
              }}
              variant="outline"
              className="gap-1"
            >
              Refresh
            </Button>
          </div>
        </div>
      </AILayout>
    );
  }

  if (!openAIResponse) {
    return (
      <AILayout>
        <div className="flex flex-col items-center gap-6 w-7/12">
          <div className="rounded-md text-center leading-6 flex flex-col gap-1">
            <span className="font-medium">No response</span>
            <div className="text-textColors-muted-light dark:text-text-muted-dark text-sm">
              Failed to reach the servers. Please try again later
            </div>
          </div>
          <div className="flex flex-row items-center gap-2">
            <Button
              onClick={() => {
                onClose();
              }}
              variant="ghost"
              className="gap-1"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (aiInstance?.integration) {
                  refetchOpenAIResponse();
                }
              }}
              variant="outline"
              className="gap-1"
            >
              Refresh
            </Button>
          </div>
        </div>
      </AILayout>
    );
  }

  return (
    <div className="my-2 h-full">
      <AiMarkerForm
        aiRevisionMarkers={
          openAIResponse
            ? (
                (openAIResponse as any).object as {
                  results: Array<{
                    question: string;
                    answer: string;
                  }>;
                }
              )?.results || []
            : []
        }
        onSubmit={onSubmit}
        onDelete={onDelete}
        onRetry={refetchOpenAIResponse}
        aiInstance={aiInstance}
      />
    </div>
  );
};

export const AIMarkerGeneratorWrapper = ({
  selectedText,
  onSubmit,
  onDelete,
  onClose,
  aiInstance,
}: {
  selectedText: string;
  onSubmit: (values: { question: string; answer: string }[]) => void;
  onDelete: () => void;
  onClose: () => void;
  aiInstance: SelectedAiInstance;
}) => {
  const router = useHistory();
  const { userId } = useAuthStore();
  const {
    data: integrations = [],
    isLoading: isLoadingIntegrations,
    isError: isErrorIntegrations,
    // TODO: parameterize this
  } = useGetConnectedIntegrations({ userId });

  if (isLoadingIntegrations) {
    return (
      <AILayout>
        <div className="flex flex-col gap-4 animate-pulse w-full">
          <div className="bg-backgroundColors-interactive-light dark:bg-backgroundColors-interactive-dark h-5 rounded w-4/5"></div>
          <div className="bg-backgroundColors-interactive-light dark:bg-backgroundColors-interactive-dark h-5 rounded w-2/5"></div>
          <div className="bg-backgroundColors-interactive-light dark:bg-backgroundColors-interactive-dark h-5 rounded w-full"></div>
        </div>
      </AILayout>
    );
  }

  const activeIntegration = getIsActiveIntegration({ integrations });

  if (!activeIntegration) {
    return (
      <AILayout>
        <div className="flex flex-col items-center gap-6 w-7/12">
          <div className="rounded-md text-center leading-6 flex flex-col gap-1">
            <span className="font-medium">No AI integration</span>
            <div className="text-textColors-muted-light dark:text-text-muted-dark text-sm">
              Plase connect an AI integration to continue
            </div>
          </div>

          <div className="flex flex-row items-center gap-2">
            <Button
              onClick={() => {
                router.push("/integrations");
              }}
              variant="outline"
            >
              Create new
            </Button>
          </div>
        </div>
      </AILayout>
    );
  }

  if (!isErrorIntegrations) {
    return (
      <OpenaiMarkerGenerator
        selectedText={selectedText}
        onDelete={onDelete}
        onSubmit={onSubmit}
        onClose={onClose}
        aiInstance={aiInstance}
      />
    );
  }

  return (
    <AILayout>
      <div className="flex flex-col items-center gap-6 w-7/12">
        <div className="rounded-md text-center leading-6 flex flex-col gap-1">
          <span className="font-medium">Something's wrong</span>
          <div className="text-textColors-muted-light dark:text-text-muted-dark text-sm">
            Failed to reach the servers. Please try again later
          </div>
        </div>
        <div className="flex flex-row items-center gap-2">
          <Button
            onClick={() => {
              onClose();
            }}
            variant="ghost"
            className="gap-1"
          >
            Cancel
          </Button>
        </div>
      </div>
    </AILayout>
  );
};
