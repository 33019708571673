import { generateObject } from "ai";
import { AICompletionParams } from "../hooks/ai/types";
import { OpenAIParams } from "../hooks/ai/useFetchAiCompletions";

export const getAiCompletions = async ({
  provider,
  params,
  instance,
}: AICompletionParams) => {
  if (provider === "openai") {
    const { model, prompt, schema } = params as OpenAIParams;

    const result = await generateObject({
      model: instance(model),
      prompt,
      schema,
    });

    return result;
  } else if (provider === "mistralai") {
    const { model, prompt, schema } = params as OpenAIParams;

    const result = await generateObject({
      model: instance(model) as any,
      prompt,
      schema,
    });

    return result;
  }

  return null;
};
