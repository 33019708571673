import { ChevronUp, RefreshCcw, ThumbsUp } from "lucide-react";
import { FC, useEffect, useRef, useState } from "react";
import { ReactTyped } from "react-typed";
import { SelectedAiInstance } from "../../../pages-v2/hooks/ai/types";
import { useUser } from "../../../pages-v2/hooks/queries/useUser";
import { integrationsMap } from "../../../pages-v2/integrations/Integrations";
import { useAuthStore } from "../../../store/auth";
import { Button } from "../../../ui-components/Button";
import { LucideIcon } from "../../../ui-components/LucideIcon";
import { getAvatarIcon } from "../../../utils/helpers";
import { cn } from "../../../utils/utils";
import { AIReflectIcon } from "./AIReflectIcon";
import { AILayout } from "./OpenaiMarkerGenerator";

const observer = new ResizeObserver((entries: ResizeObserverEntry[]) => {
  const scrollRef = document.getElementById("ai-scroll-ref");

  if (scrollRef) {
    scrollRef.scrollTop = scrollRef.scrollHeight;
  }
});

export const AIResultSingleView: FC<{
  aiRevisionMarkers: { question: string; answer: string }[];
  onSubmit: (results: { question: string; answer: string }[]) => void;
  onDelete: () => void;
  onRetry: () => void;
  aiInstance: SelectedAiInstance;
}> = ({ aiRevisionMarkers, onDelete, onSubmit, onRetry, aiInstance }) => {
  const [isFinishedAutocomplete, setIsFinishedAutocomplete] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef?.current) {
      observer.observe(containerRef?.current);
    }
  }, []);

  // there is one result
  const { question, answer } = aiRevisionMarkers?.[0];

  const integrationMeta = integrationsMap.find(
    (intg) => intg.id === aiInstance?.integration?.metadata?.integrationType
  );

  const { userId } = useAuthStore();

  const { data: [user] = [] } = useUser({ userId });

  return (
    <AILayout>
      <div
        className="w-full flex flex-col md:px-8 gap-4 text-left"
        ref={containerRef}
      >
        <div className="flex flex-col items-start gap-1">
          {getAvatarIcon({ avatar: user?.avatar as any, className: "size-5" })}

          <p className="text-sm leading-6">
            Create a reflection based on{" "}
            <span className="font-medium">{`{{ selectedText }}`}</span>
          </p>
        </div>
        <div className={cn("flex flex-col gap-2 items-start flex-wrap pt-1")}>
          <div className="flex flex-row">
            {integrationMeta?.icon ? (
              <Button
                variant="outline"
                size="icon"
                className="size-6 pointer-events-none"
              >
                {integrationMeta?.icon}
              </Button>
            ) : (
              <div className="[&>div]:size-5 z-10 [&>div>svg]:size-3 [&>div]:shadow-none dark:[&>div]:shadow-none [&>div]:rounded">
                <AIReflectIcon />
              </div>
            )}
          </div>
          <div className="flex flex-col gap-2">
            <span className="text-left w-fit text-textColors-muted-light dark:text-textColors-muted-dark leading-6">
              {question}
            </span>
            <div className="flex flex-col gap-4">
              <div className="text-textColors-secondary-light dark:text-textColors-secondary-dark">
                <ReactTyped
                  backSpeed={50}
                  onBegin={() => {
                    setIsFinishedAutocomplete(false);
                  }}
                  onComplete={() => {
                    setIsFinishedAutocomplete(true);
                  }}
                  onDestroy={function noRefCheck() {}}
                  onLastStringBackspaced={function noRefCheck() {}}
                  onReset={() => {
                    setIsFinishedAutocomplete(false);
                  }}
                  onStart={function noRefCheck() {}}
                  onStop={function noRefCheck() {}}
                  onStringTyped={() => {
                    setIsFinishedAutocomplete(false);
                  }}
                  onTypingPaused={function noRefCheck() {}}
                  onTypingResumed={function noRefCheck() {}}
                  startWhenVisible
                  strings={[answer]}
                  typeSpeed={10}
                  showCursor={false}
                  typedRef={function noRefCheck() {}}
                  className="leading-6 text-sm"
                />
                {isFinishedAutocomplete && (
                  <div className="flex flex-row items-center gap-2 mt-4 justify-between">
                    <div className="flex flex-row items-center gap-2">
                      <Button
                        onClick={() => {
                          onSubmit([{ question, answer }]);
                        }}
                        variant="hoverLink"
                        size="sm"
                        className={cn(
                          "text-text-textColors-muted-light pl-0 dark:text-textColors-muted-dark hover:no-underline gap-1",
                          "group"
                        )}
                      >
                        <LucideIcon
                          icon={ThumbsUp}
                          className={cn(
                            "size-3 stroke-[2.5] stroke-textColors-muted-light dark:stroke-textColors-muted-dark",
                            "group-hover:stroke-textColors-background-light group-hover:dark:stroke-textColors-background-dark"
                          )}
                        />
                      </Button>
                      <Button
                        onClick={onRetry}
                        variant="hoverLink"
                        className="gap-1 group"
                      >
                        <LucideIcon
                          icon={RefreshCcw}
                          className={cn(
                            "size-3 stroke-[2.5] stroke-textColors-muted-light dark:stroke-textColors-muted-dark",
                            "group-hover:stroke-textColors-background-light group-hover:dark:stroke-textColors-background-dark"
                          )}
                        />
                      </Button>
                    </div>
                    <Button
                      onClick={() => {
                        const scrollRef =
                          document.getElementById("ai-scroll-ref");

                        if (scrollRef) {
                          scrollRef.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          });
                        }
                      }}
                      variant="hoverLink"
                      className="gap-1 group"
                    >
                      <LucideIcon
                        icon={ChevronUp}
                        className={cn(
                          "size-3 stroke-[2.5] stroke-textColors-muted-light dark:stroke-textColors-muted-dark",
                          "group-hover:stroke-textColors-background-light group-hover:dark:stroke-textColors-background-dark"
                        )}
                      />
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AILayout>
  );
};
