import { motion } from "framer-motion";
import { Activity, Atom, Code, Earth } from "lucide-react";
import { FC, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "../../../ui-components/Button";
import { LucideIcon } from "../../../ui-components/LucideIcon";
import { cn } from "../../../utils/utils";

const flashcardsData = [
  {
    question: "Differentiate between the inline and block elements.",
    answer: `inline elements do not start from a new
      line every time and take up content's width, 
      whereas block elements start from a new line and takes up the whole view-port width.`,
    header: (
      <div className="flex flex-row flex-wrap items-center gap-2">
        <div className="flex flex-row gap-2 bg-[var(--hl-purple)] size-fit p-2 rounded-md">
          <LucideIcon
            icon={Code}
            className="size-3 stroke-[2.5] stroke-[var(--color-purple)]"
          />
        </div>
        <span className="capitalize text-sm">HTML</span>
      </div>
    ),
  },
  {
    question: "Define gravitational force.",
    answer:
      "gravity is a force that attracts an object towards the centre of the earth or any other physical body having mass. It makes all the planets orbit around the sun.",
    header: (
      <div className="flex flex-row flex-wrap items-center gap-2">
        <div className="flex flex-row gap-2 bg-[var(--hl-orange)] size-fit p-2 rounded-md">
          <LucideIcon
            icon={Earth}
            className="size-3 stroke-[2.5] stroke-[var(--color-orange)]"
          />
        </div>
        <span className="capitalize text-sm">physics</span>
      </div>
    ),
  },
  {
    question:
      "What term describes a system where power is vested in the hands of a small, privileged group ?",
    answer:
      "High-order functions are functions that take other functions as arguments. These functions help abstract common patterns of code.",
    header: (
      <div className="flex flex-row flex-wrap items-center gap-2">
        <div className="flex flex-row gap-2 bg-[var(--hl-gray)] size-fit p-2 rounded-md">
          <LucideIcon
            icon={Atom}
            className="size-3 stroke-[2.5] stroke-[var(--color-gray)]"
          />
        </div>
        <span className="capitalize text-sm">system design</span>
      </div>
    ),
  },
  {
    question: `What is the form of power structure in which power rests with a small number of people ?`,
    answer: `Oligarchy is a form of government where a small number of people rule over a country.`,
    header: (
      <div className="flex flex-row flex-wrap items-center gap-2">
        <div className="flex flex-row gap-2 bg-[var(--hl-green)] size-fit p-2 rounded-md">
          <LucideIcon
            icon={Activity}
            className="size-3 stroke-[2.5] stroke-[var(--color-green)]"
          />
        </div>
        <span className="capitalize text-sm">political science</span>
      </div>
    ),
  },
];

export const Flashcards = () => {
  const router = useHistory();

  return (
    <div className="revise text-left md:px-24 overflow-x-clip">
      <div className="grid grid-cols-[320px_auto] md:grid-cols-[440px_auto] items-start gap-4">
        <div className="col-span-1 justify-self-end lg:justify-center flex flex-col gap-6">
          <p className="-mt-4 text-textColors-muted-light dark:text-textColors-secondary-dark sm:text-md md:text-xl !leading-relaxed w-[70%]">
            <span className="text-textColors-background-light dark:text-textColors-background-dark">
              Don't let your notes disappear into the void
            </span>
            . Quickly skim through your revision notes, refresh your memory, and
            save time with neatly organized flashcards.
          </p>
          <Button
            variant="outline"
            className="w-fit"
            onClick={() => router.push("/signup")}
          >
            Get started
          </Button>
        </div>
        <div className="col-span-1 size-full mt-16 md:-ml-20 hidden sm:block">
          <FlashcardsUI />
        </div>
      </div>
    </div>
  );
};

export const FlashcardsUI: FC = () => {
  const [cards, setCards] = useState(flashcardsData);

  const triggerAnimation = useCallback(() => {
    const lastCard = cards.at(-1);
    const shiftedCards = cards.slice(0, 3);
    const newCards = [lastCard].concat(shiftedCards);

    setCards(newCards);
  }, [cards]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      triggerAnimation();
    }, 4000); // Adjust the interval timing for smooth animation

    return () => clearInterval(intervalId); // Clean up the interval on unmount
  }, [triggerAnimation]);

  return (
    <div className="relative">
      {cards.map((flashcard, index) => {
        return (
          <motion.div
            key={flashcard.question}
            animate={{
              scale: 1 - 0.05 * index,
              zIndex: 5000 - 5 * index,
              top: -32 * index,
              left: -50 * index,
            }}
            transition={{
              duration: 1,
              ease: "easeInOut",
            }}
            style={{
              transformOrigin: "top center",
            }}
            className={cn("flex flex-col absolute gap-4 cursor-default")}
            onClick={() => triggerAnimation()}
          >
            <div
              className={cn(
                "flex flex-col size-full p-4 w-[258px] md:w-[450px] h-56 pb-12 gap-4 [&>*]:select-none",
                "border rounded-xl border-border-primary-light dark:border-border-primary-dark",
                index !== 0
                  ? "bg-backgroundColors-gray-light/10 dark:bg-backgroundColors-gray-dark/20 backdrop-blur-xl"
                  : "bg-backgroundColors-gray-light/70 dark:bg-backgroundColors-gray-dark/50 backdrop-blur-xl"
              )}
              style={{
                transform: `skewY(-2deg) rotateX(-12deg) rotateY(20deg) translateZ(100px) translateX(7%) translateY(-15%)`,
                transformStyle: "preserve-3d",
              }}
            >
              {flashcard.header}

              <div>{flashcard.question}</div>
              <div>{flashcard.answer}</div>
            </div>
          </motion.div>
        );
      })}
    </div>
  );
};
