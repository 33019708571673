import { ListTree, Plus, X } from "lucide-react";
import { useState } from "react";
import { toast } from "sonner";
import { z } from "zod";
import useWindowDimensions from "../../hooks/utility/useWindowDimensions";
import { useAuthStore } from "../../store/auth";
import { SnippetsMenu } from "../../text-editor/components/SnippetsMenu";
import { AppExtensions, TextEditor } from "../../text-editor/TextEditor";
import { Button } from "../../ui-components/Button";
import { Dialog, DialogContent } from "../../ui-components/Dialog";
import { DropdownMenuSeparator } from "../../ui-components/DropdownMenu";
import { LucideIcon } from "../../ui-components/LucideIcon";
import { CHEATSHEET_LEVEL } from "../../ui-components/templates/cheatsheet/Cheatsheet";
import { CheatsheetEditorDialog } from "../../ui-components/templates/cheatsheet/CheatsheetEditor";
import { Prose } from "../../ui-components/templates/Prose";
import { ERROR_MESSAGE } from "../../utils/constants";
import { TemplateEnum } from "../../utils/types";
import { cn } from "../../utils/utils";
import { useCreateNote } from "../hooks/mutations/useCreateNote";
import {
  TNotesResponseWithChildren,
  useNotesList,
} from "../hooks/queries/useNotesList";
import { noteCreationFormSchema } from "../notes-sidebar/NoteCreationForm";

export const SnippetsManager = () => {
  const [openSnippetPreview, setOpenSnippetPreview] =
    useState<TNotesResponseWithChildren>(null);
  const [openSnippetCreator, setOpenSnippetCreator] = useState(false);
  const { mutateAsync: createNote, isLoading: isLoadingCreateNote } =
    useCreateNote();
  const { userId } = useAuthStore();
  const { data: notes } = useNotesList({
    userId,
  });

  const { width } = useWindowDimensions();

  const snippets =
    notes?.filter((note) => note.template === TemplateEnum.SNIPPET) || [];

  const handleCreateSnippet = async ({
    values,
    body,
  }: {
    values: z.infer<typeof noteCreationFormSchema>;
    body: string;
  }) => {
    const response = await createNote(
      {
        title: values?.title,
        body,
        template: TemplateEnum.SNIPPET,
        description: ``,
        level: CHEATSHEET_LEVEL,
        userId,
        isContentNote: false,
        icon: null,
      },
      {
        onError: () => {
          toast.error(ERROR_MESSAGE);
        },
        onSuccess: () => {
          toast.success(
            `Your snippet ${values?.title} has been created successfully`
          );
        },
      }
    );

    if (Boolean(response) && response?.[0]?.id) {
      setOpenSnippetCreator(false);
    }
  };

  const NotePreview = (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col">
        <span className="font-medium">Preview</span>
        <span className="text-sm text-textColors-muted-light dark:text-textColors-muted-dark">
          Inspect your saved snippets
        </span>
      </div>
      <DropdownMenuSeparator className="-mx-3" />
      <Prose classNames="p-4">
        <TextEditor
          readOnly
          key={openSnippetPreview?.id}
          initialData={openSnippetPreview?.body || `{}`}
          onUpdate={() => {}}
          appExtensions={[AppExtensions.REVISE_MARK, AppExtensions.HYPERLINK]}
        />
      </Prose>
    </div>
  );

  return (
    <div className="flex flex-row w-full h-full gap-4">
      <div
        className={cn(
          "flex flex-col gap-y-4 h-full",
          Boolean(openSnippetPreview) ? "md:w-8/12" : "w-full"
        )}
      >
        <div className="flex flex-row gap-x-4 items-center justify-between">
          <div className="flex flex-col">
            <div className="text-xl font-medium">Snippets</div>
            <div className="text-textColors-muted-light dark:text-textColors-muted-dark text-sm">
              View and manage your snippets
            </div>
          </div>
          <Button
            variant="outline"
            onClick={() => {
              setOpenSnippetCreator(true);
            }}
            className="gap-1"
          >
            <LucideIcon icon={Plus} size={16} />
            New
          </Button>
        </div>
        {Boolean(snippets.length) ? (
          <>
            <SnippetsMenu
              onSelect={(snippet) => setOpenSnippetPreview(snippet)}
              showActions
            />
          </>
        ) : (
          <div
            className={cn(
              "grid place-items-center justify-center h-[calc(100%-128px)]"
              // APP_PAGE_HEIGHT
            )}
          >
            <div className="flex flex-col gap-y-1.5 items-center text-center">
              <LucideIcon
                icon={ListTree}
                size={64}
                strokeWidth="1px"
                className="text-text-tertiary-light dark:text-text-tertiary-dark"
              />
              <span className="text-textColors-muted-light dark:text-textColors-muted-dark">
                No snippets to show. Please <br /> create a new one
              </span>
            </div>
          </div>
        )}
        <CheatsheetEditorDialog
          title={`New Snippet`}
          cta={`Create`}
          note={null}
          defaultValues={null}
          open={openSnippetCreator}
          setOpen={setOpenSnippetCreator}
          description={<></>}
          onSubmit={({ values, body }) => {
            //
            handleCreateSnippet({ values, body });
          }}
          isLoading={isLoadingCreateNote}
        />
      </div>
      <div
        className={cn(
          Boolean(openSnippetPreview)
            ? "relative -mt-4 md:block hidden w-4/12 animate-in pt-2 pl-3 transition-all slide-in-from-right-24 duration-400 border-l border-border-primary-light dark:border-border-primary-dark h-[calc(100%+32px)]"
            : "hidden"
        )}
      >
        <Button
          variant="ghost"
          size="icon"
          className="absolute top-1 -right-2 z-10"
          onClick={() => setOpenSnippetPreview(null)}
        >
          <LucideIcon icon={X} size={12} />
        </Button>
        {NotePreview}
      </div>
      <div
        className={cn(
          Boolean(openSnippetPreview) ? "block md:hidden" : "hidden"
        )}
      >
        <Dialog
          defaultOpen={Boolean(openSnippetPreview) && width < 768}
          open={Boolean(openSnippetPreview) && width < 768}
          onOpenChange={() => {
            if (Boolean(openSnippetPreview)) {
              setOpenSnippetPreview(null);
            }
          }}
        >
          <DialogContent>{NotePreview}</DialogContent>
        </Dialog>
      </div>
    </div>
  );
};
