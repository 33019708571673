import { zodResolver } from "@hookform/resolvers/zod";
import { DropdownMenuSeparator } from "@radix-ui/react-dropdown-menu";
import { useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "../../../ui-components/Button";
import { DialogFooter } from "../../../ui-components/Dialog";
import { Input } from "../../../ui-components/Input";
import { Prose } from "../../../ui-components/templates/Prose";
import { cn } from "../../../utils/utils";
import {
  DEFAULT_REVISION_CARD_COLOR,
  RevisionCardColorsType,
} from "../../extensions/revise-marks/Revise.mark";
import { TextEditor } from "../../TextEditor";

const revisionSchema = z.object({
  question: z.string(),
  answer: z.string(),
  color: z.string().default(DEFAULT_REVISION_CARD_COLOR),
});

export const MarkerForm = ({
  onSubmit,
  onDelete,
  selectedText,
}: {
  onSubmit: SubmitHandler<{
    question: string;
    answer: string;
    color: RevisionCardColorsType;
  }>;
  onDelete: () => void;

  selectedText: string;
  onRefetch?: () => void;
  defaultValues?: {
    questions: string;
    answers: string;
    color: RevisionCardColorsType;
  };
}) => {
  const editorRef = useRef();

  const form = useForm<{
    question: string;
    answer: string;
    color: RevisionCardColorsType;
  }>({
    resolver: zodResolver(revisionSchema),
    defaultValues: {
      question: "",
      answer: "",
      color: DEFAULT_REVISION_CARD_COLOR,
    },
  });
  const { register, handleSubmit } = form;
  const [noteContentState, setNoteContentState] = useState<string>("");

  const { question } = form.watch();

  return (
    <form
      onSubmit={handleSubmit(({ question }) =>
        onSubmit({
          question,
          // by-pass answer as texteditor's content.
          answer: noteContentState,
          color: "blue",
        })
      )}
    >
      <div
        className={cn(
          "flex flex-col gap-y-3 px-1 relative font-medium"
          // "after:content=[''] after:absolute after:h-[12px] after:w-[1px] after:bg-border-primary-light dark:after:bg-gray-600 after:top-[32px] after:left-[15px] after:rounded-full"
        )}
      >
        <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-2 pr-6">
            <Input
              placeholder="Revision title"
              className="text-textColors-secondary-light border-0 pl-0 text-lg dark:text-textColors-secondary-dark shadow-none focus:shadow-none focus:ring-0 focus-visible:shadow-none focus-visible:ring-0"
              {...register("question")}
              autoComplete="off"
            />
          </div>
          <div className={cn("flex flex-col gap-2")}>
            <div className="h-[108px] p-4 pt-0 pl-0 overflow-y-auto resize-none font-normal leading-6">
              <Prose classNames="pl-0">
                <TextEditor
                  // HACK: This is a hack to make the editor re-render when the note changes
                  initialData={""}
                  onUpdate={({ data }: { data: string }) =>
                    setNoteContentState(data)
                  }
                  readOnly={false}
                  appExtensions={[]}
                  ref={editorRef}
                />
              </Prose>
            </div>
          </div>
        </div>
      </div>
      <DropdownMenuSeparator className="my-6" />
      <DialogFooter className="gap-2 !justify-end flex flex-row pr-6">
        <Button type="submit" disabled={!noteContentState || !question}>
          Create revision
        </Button>
      </DialogFooter>
    </form>
  );
};
