import { Editor } from "@tiptap/react";
import { FC, useState } from "react";
import { TodoIcon } from "../../../../assets/svg/todo.svg";
import { TNotesList } from "../../../../pages-v2/api/fetchNotes";
import { useReflections } from "../../../../text-editor/extensions/reflect-extension/queries";
import { Button } from "../../../Button";
import { ReflectCardsDialog } from "./ReflectCardsDialog";

export const ReflectCards: FC<{
  note: TNotesList[number];
  editor: Editor;
}> = ({ note, editor }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { data: reflections } = useReflections(editor);

  if (!reflections?.length) return null;

  return (
    <>
      <Button
        variant="outline"
        className="gap-1 group px-3 transition-all duration-300"
        onClick={() => setIsOpen(!isOpen)}
      >
        <TodoIcon className="size-3.5 ease-out stroke-[3]" />
        <div className="flex gap-4 items-center">
          <span>Reflect</span>
        </div>
      </Button>
      <ReflectCardsDialog
        key={`${isOpen}`}
        open={isOpen}
        setOpen={setIsOpen}
        reflections={reflections.map((reflection) => ({
          data: (() => {
            try {
              return JSON.parse(reflection?.node?.attrs?.data);
            } catch (err) {
              return "";
            }
          })(),
          reflectId: reflection.node?.attrs?.reflectId,
        }))}
        editor={editor}
      />
    </>
  );
};
