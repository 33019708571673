import { EditorContent, useEditor } from "@tiptap/react";
import { motion } from "framer-motion";
import {
  createContext,
  forwardRef,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  transformHeirarchicalNotesToHyperlinks,
  useNotesList,
} from "../pages-v2/hooks/queries/useNotesList";
import { useAuthStore } from "../store/auth";
import { DEFAULT_EDITOR_CONTENT } from "../utils/constants";
import { BubbleMenu } from "./components/BubbleMenu";
import { TextEditorCommandsPlugin } from "./extensions/commands/TextEditorCommandsPlugin";
import { DragAndDrop } from "./extensions/drag-and-drop";
import { getBasicPlugins, getNoteHyperlinkPlugin } from "./extensions/Plugins";
import { ReflectExtension } from "./extensions/reflect-extension/extension";
import { ReviseMark } from "./extensions/revise-marks/Revise.mark";

export enum AppExtensions {
  HYPERLINK = "hyperlink",
  REVISE_MARK = "revise-mark",
  DRAG_AND_DROP = "drag-handle",
}

export const TextEditor = forwardRef(
  (
    props: {
      placeholder?: string;
      onUpdate: (data: { data: string }) => void;
      initialData?: string;
      readOnly?: boolean;
      onBlur?: (data: { data: string }) => void;
      appExtensions: AppExtensions[];
    },
    ref
  ) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const { userId } = useAuthStore();
    const {
      placeholder = 'Type "/"  for commands',
      onUpdate,
      onBlur,
      initialData,
      readOnly = false,
      appExtensions,
    } = props;

    const editorContent = initialData
      ? JSON.parse(initialData)
      : JSON.parse(DEFAULT_EDITOR_CONTENT);

    const {
      data: noteHyperlinkQueryResults = [],
      isLoading: isLoadingNoteHyperlinkQueryResults,
      isError: isErrorNoteHyperlinkQueryResults,
    } = useNotesList({
      userId,
      enabled: appExtensions.includes(AppExtensions.HYPERLINK),
    });

    const reloadEditor =
      isLoadingNoteHyperlinkQueryResults || isErrorNoteHyperlinkQueryResults;

    const editor = useEditor(
      {
        extensions: [
          ReflectExtension,

          appExtensions.includes(AppExtensions.REVISE_MARK) ? ReviseMark : null,
          appExtensions.includes(AppExtensions.DRAG_AND_DROP)
            ? DragAndDrop
            : null,
          ...getBasicPlugins({ placeholder }),

          TextEditorCommandsPlugin,
          appExtensions.includes(AppExtensions.HYPERLINK)
            ? getNoteHyperlinkPlugin({
                queryResults: transformHeirarchicalNotesToHyperlinks({
                  notesResponse: noteHyperlinkQueryResults,
                }),
              })
            : null,
        ].filter(Boolean),
        onUpdate: ({ editor }) => {
          if (onUpdate) {
            onUpdate({
              data: JSON.stringify({ ...editor.getJSON() }),
            });
          }
        },
        content: editorContent,
        editorProps: {
          attributes: {
            spellcheck: "false",
          },
        },
        onBlur: ({ editor }) => {
          if (onBlur) {
            onBlur({
              data: JSON.stringify({ ...editor.getJSON() }),
            });
          }
        },
      },
      [reloadEditor]
    );

    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    useImperativeHandle(ref, () => ({
      getJSON() {
        return editor?.getJSON();
      },
      getEditorInstance() {
        return editor;
      },
    }));

    const [showBubbleMenu, setShowBubbleMenu] = useState(false);

    useLayoutEffect(() => {
      if (editor) {
        editor.setOptions({
          editable: !readOnly,
        });
      }

      setTimeout(() => {
        setShowBubbleMenu(true);
      }, 0);
    }, [readOnly, editor, initialData]);

    if (
      isLoadingNoteHyperlinkQueryResults &&
      appExtensions.includes(AppExtensions.HYPERLINK)
    ) {
      return <div>Loading...</div>;
    }

    return (
      <TextEditorContext.Provider
        value={{
          editorRef: containerRef,
        }}
      >
        <motion.div
          ref={containerRef}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.4, ease: "easeInOut" }}
          className="selection:bg-backgroundColors-interactive-light dark:selection:bg-backgroundColors-interactive-dark/70"
        >
          {editor && showBubbleMenu && (
            <BubbleMenu
              editor={editor}
              containerRef={containerRef}
              appExtensions={appExtensions}
              readOnly={readOnly}
            />
          )}
          <EditorContent editor={editor} />
        </motion.div>
      </TextEditorContext.Provider>
    );
  }
);

export const TextEditorContext = createContext<{
  editorRef: React.MutableRefObject<HTMLDivElement>;
}>({
  editorRef: null,
});
