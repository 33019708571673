import { PostHogConfig } from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import ReactDOM from "react-dom";
import App from "./App";

import { Buffer } from "buffer";

declare global {
  interface Window {
    Buffer: typeof Buffer;
  }
}

// Polyfill Buffer for the browser
if (typeof window !== "undefined") {
  window.Buffer = Buffer;
}

const options: Partial<PostHogConfig> = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  opt_out_capturing_by_default: process.env.NODE_ENV === "development",
};

// import './fonts/Inter.tff';

ReactDOM.render(
  <PostHogProvider
    apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
    options={options}
  >
    <App />
  </PostHogProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
