import { FC } from "react";
import { AiIcon } from "../../../assets/svg/ai.svg";
import { cn } from "../../../utils/utils";

export const AIReflectIcon: FC = () => {
  return (
    <div
      className={cn(
        "border border-border-primary-light dark:border-border-primary-dark shrink-0",
        "size-12 rounded-lg flex flex-col items-center justify-center",
        "dark:border-purple-500 bg-gradient-to-b dark:from-purple-300 dark:to-purple-800",
        "border-purple-600 from-[#9870E9] to-[#6D47CA]",
        "transition-all duration-400",
        "shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_6px_#d8b4fe,0_0_20px_#d8b4fe,0_0_30px_#c084fc] dark:shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#9333ea,0_0_15px_#9333ea,0_0_30px_#9333ea]"
      )}
    >
      <AiIcon className="size-6 dark:fill-[#D5D3FF] fill-white" />
    </div>
  );
};
