import { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "sonner";
import { RootLayout } from "../../layouts/Root.layout";
import { BASE_URL } from "../../pages-v2/NotesDashboardWrapper";
import { useAuthStore } from "../../store/auth";
import { Button } from "../../ui-components/Button";
import { ERROR_MESSAGE } from "../../utils/constants";
import Logo from "../../utils/logo";
import supabase from "../../utils/supabase";

const ProviderSignin = () => {
  const router = useHistory();
  const { updateUser } = useAuthStore();

  const hydrateStoreInitially = useCallback(
    async (userId: string, email: string) => {
      const { data, error } = await supabase
        .from("users")
        .select("username")
        .eq("id", userId);
      if (error) {
        toast.error(ERROR_MESSAGE, {
          duration: 5000,
        });
      }
      if (data) {
        if (data.length > 0) {
          updateUser({ userId, email, username: data[0].username });
          router.push(`${BASE_URL}/notes`);
        } else {
          // move to complete profile
          router.push("/complete-profile");
        }
      }
    },
    [updateUser, router]
  );

  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      hydrateStoreInitially(session?.user?.id, session?.user?.email);
    });

    return () => {
      subscription?.unsubscribe();
    };
  }, [hydrateStoreInitially]);

  return (
    <RootLayout>
      <div className="grid place-items-center justify-center h-[calc(100vh-200px)]">
        <div className="flex flex-col gap-12 items-center text-center">
          <Logo className="[&>svg]:size-12" />
          <div className="flex flex-col gap-6">
            <div className="flex flex-col flex-wrap gap-4">
              <span>Creating your profile and redirecting to the app ...</span>
              <p className="text-textColors-muted-light dark:text-textColors-muted-dark">
                You can click the link below if that does not work
              </p>
            </div>
            <Button
              variant="outline"
              onClick={() => {
                router.push(BASE_URL);
              }}
              className="w-fit mx-auto"
            >
              Go to the app
            </Button>
          </div>
        </div>
      </div>
    </RootLayout>
  );
};

export default ProviderSignin;
