import { Editor, findChildren, NodeWithPos } from "@tiptap/react";
import { ReflectAttributes } from "./extension";

export const findAllReflections = (editor: Editor): Promise<NodeWithPos[]> => {
  if (!editor) {
    return null;
  }

  return new Promise((resolve, reject) => {
    resolve(
      findChildren(editor.state.doc, (node) => node.type.name === "reflect")
    );
  });
};

export const getConfidenceScore = (retained: number, forgotten: number) => {
  if (retained === 0 && forgotten === 0) {
    return NaN;
  }

  if (forgotten === 0) return 100;

  const percent = (retained / (retained + forgotten)) * 100;

  return percent % 1 === 0 ? Math.floor(percent) : percent.toFixed(1);
};

export const findReflectionById = (
  editor: Editor,
  id: ReflectAttributes["reflectId"]
) => {
  if (!editor) {
    return null;
  }

  return findChildren(
    editor.state.doc,
    (node) => node.type.name === "reflect" && node.attrs["reflectId"] === id
  );
};

export const handleUpdateRetainedReflectAttributes = ({
  reflectId,
  newRetainedCount,
  editor,
  reflectionData,
}: {
  reflectId: string;
  newRetainedCount: number;
  editor: Editor;
  reflectionData: {
    reflectId: ReflectAttributes["reflectId"];
    data: Exclude<ReflectAttributes["data"], { type: "summary" }>;
  };
}) => {
  try {
    const question = Object.keys(reflectionData?.data?.data)?.[0];
    const reflectionObject =
      reflectionData?.data?.data[Object.keys(reflectionData?.data?.data)?.[0]];

    let newData: typeof reflectionData = {
      ...reflectionData,
      data: {
        ...reflectionData.data,
        data: {
          [question]: {
            ...reflectionObject,
            retained: `${newRetainedCount}`,
          },
        },
      },
    };

    return findAndUpdateReflectionById(editor, reflectId, {
      reflectId: newData.reflectId,
      data: JSON.stringify(newData.data),
    });
  } catch (err) {
    console.log(err);
  }
};

export const resetConfidenceReflectAttributes = ({
  reflectId,
  editor,
  reflectionData,
}: {
  reflectId: string;
  editor: Editor;
  reflectionData: {
    reflectId: ReflectAttributes["reflectId"];
    data: Exclude<ReflectAttributes["data"], { type: "summary" }>;
  };
}) => {
  try {
    const question = Object.keys(reflectionData?.data?.data)?.[0];
    const reflectionObject =
      reflectionData?.data?.data[Object.keys(reflectionData?.data?.data)?.[0]];

    const newData: typeof reflectionData = {
      ...reflectionData,
      data: {
        ...reflectionData.data,
        data: {
          [question]: {
            ...reflectionObject,
            retained: `0`,
            forgotten: `0`,
          },
        },
      },
    };

    return findAndUpdateReflectionById(editor, reflectId, {
      reflectId: newData.reflectId,
      data: JSON.stringify(newData.data),
    });
  } catch (err) {
    console.log(err);
  }
};

export const handleUpdateForgottenReflectAttributes = ({
  reflectId,
  newForgottenCount,
  editor,
  reflectionData,
}: {
  reflectId: string;
  newForgottenCount: number;
  editor: Editor;
  reflectionData: {
    reflectId: ReflectAttributes["reflectId"];
    data: Exclude<ReflectAttributes["data"], { type: "summary" }>;
  };
}) => {
  try {
    const question = Object.keys(reflectionData?.data?.data)?.[0];
    const reflectionObject =
      reflectionData?.data?.data[Object.keys(reflectionData?.data?.data)?.[0]];

    const newData: typeof reflectionData = {
      ...reflectionData,
      data: {
        ...reflectionData.data,
        data: {
          [question]: {
            ...reflectionObject,
            forgotten: `${newForgottenCount}`,
          },
        },
      },
    };

    return findAndUpdateReflectionById(editor, reflectId, {
      reflectId: newData.reflectId,
      data: JSON.stringify(newData.data),
    });
  } catch (err) {}
};

export const findAndUpdateReflectionById = (
  editor: Editor,
  id: ReflectAttributes["reflectId"],
  newAttributes: Record<string, any>
): void => {
  if (!editor) {
    return null;
  }

  try {
    const node = findChildren(
      editor.state.doc,
      (node) => node.type.name === "reflect" && node.attrs["reflectId"] === id
    );

    if (node && Boolean(node?.length)) {
      editor
        .chain()
        .command(({ tr }) => {
          const position = node[0]?.pos;
          const currentNode = tr.doc.nodeAt(position);

          tr.setNodeMarkup(position, undefined, {
            ...currentNode?.attrs,
            ...newAttributes,
          });
          return true;
        })
        .run();
    }

    return null;
  } catch (err) {
    console.log({ err });
  }
};

export function escapeForHTML(inputString: string) {
  return inputString
    .replace(/&/g, "&amp;") // Escape ampersands
    .replace(/</g, "&lt;") // Escape less than
    .replace(/>/g, "&gt;") // Escape greater than
    .replace(/"/g, "&quot;") // Escape double quotes
    .replace(/'/g, "&#39;"); // Escape single quotes
}
