export const TodoIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    className="sc-iuOOrT kfAEwx color-override"
    width="16"
    height="16"
    viewBox="1 1 14 14"
    fill="#F2C94C"
    role="img"
    focusable="false"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.3779 4.74233C8.14438 4.60607 7.85562 4.60607 7.6221 4.74233L5.37209 6.05513C5.14168 6.18957 5 6.4363 5 6.70311V9.34216C5 9.60897 5.14168 9.85573 5.37209 9.99016L7.6221 11.303C7.85562 11.4392 8.14438 11.4392 8.3779 11.303L10.6279 9.99016C10.8583 9.85573 11 9.60897 11 9.34216V6.70311C11 6.4363 10.8583 6.18957 10.6279 6.05513L8.3779 4.74233Z"
      mask="url(#:r1t:-hole-50)"
    ></path>
    <mask id=":r1t:-hole-50">
      <rect width="100%" height="100%" fill="white"></rect>
      <circle
        r="4"
        cx="7.5"
        cy="8"
        fill="black"
        stroke="white"
        strokeWidth="8"
        strokeDasharray="calc(12.56) 25.12"
        transform="rotate(-90) translate(-16)"
      ></circle>
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.24419 1.44066C7.71124 1.16822 8.28876 1.16822 8.75581 1.44066L13.2558 4.06566C13.7166 4.33448 14 4.82783 14 5.36133V10.6382C14 11.1717 13.7166 11.6651 13.2558 11.9339L8.75581 14.5589C8.28876 14.8313 7.71124 14.8313 7.24419 14.5589L2.74419 11.9339C2.28337 11.6651 2 11.1717 2 10.6382V5.36133C2 4.82783 2.28337 4.33448 2.74419 4.06566L7.24419 1.44066ZM8 2.73633L12.5 5.36133V10.6382L8 13.2632L3.5 10.6382V5.36133L8 2.73633Z"
    ></path>
  </svg>
);
