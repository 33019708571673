import { FC } from "react";
import { cn } from "../../../../utils/utils";
import { Button } from "../../../Button";

export const Pagination: FC<{
  totalLength: number;
  activeIndex: number;
  onNavigate: (index: number) => void;
}> = ({ totalLength, activeIndex, onNavigate }) => {
  const derivedData = Array(totalLength).fill(0);

  return (
    <div className="flex flex-row items-center">
      {derivedData.map((d, index) => {
        const hide = getShouldHideElement({
          index,
          activeIndex,
          totalLength,
        });

        const isActive = index === activeIndex;

        return (
          <Button
            key={index}
            variant="hoverLink"
            size="icon"
            className={cn(
              "w-fit size-5",
              isActive && "border",
              isActive &&
                "text-textColors-background-light dark:text-textColors-background-dark",
              "transition-colors duration-500",
              hide && "hidden"
            )}
            onClick={() => onNavigate(index)}
          >
            {index + 1}
          </Button>
        );
      })}
    </div>
  );
};

function getShouldHideElement({
  index,
  activeIndex,
  totalLength,
}: {
  index: number;
  activeIndex: number;
  totalLength: number;
}) {
  if (activeIndex === 0) {
    return index > activeIndex + 2;
  }

  if (activeIndex <= totalLength - 3) {
    // all the middle positioned elements
    return index < activeIndex - 1 || index > activeIndex + 1;
  }

  // for extreme right elements
  return (
    index <
    totalLength -
      (totalLength - activeIndex) -
      (3 - (totalLength - activeIndex))
  );
}
