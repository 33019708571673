import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { ChevronRight, Loader } from "lucide-react";
import * as React from "react";
import { cn } from "../utils/utils";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50",
  // 'focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
  {
    variants: {
      variant: {
        default: `bg-backgroundColors-foreground-light text-white  hover:bg-backgroundColors-foreground-light/90
        dark:bg-backgroundColors-foreground-dark dark:hover:bg-backgroundColors-foreground-dark/90 dark:text-white`,
        destructive: `bg-bg-destructive-light dark:bg-bg-destructive-light
        text-white dark:text-textColors-background-dark
        hover:bg-bg-destructive-light/90 dark:hover:bg-bg-error-dark/90`,
        outline: `border border-border-primary-light
        bg-backgroundColors-background-light hover:bg-bg-secondary-light 
        dark:hover:bg-bg-secondary-dark dark:bg-backgroundColors-background-dark dark:border-border-primary-dark focus-visible:bg-bg-secondary-light`,
        secondary: `bg-bg-secondary-light hover:bg-bg-secondary-light/80
        text-textColors-secondary-light dark:text-textColors-secondary-dark
        dark:bg-bg-secondary-dark dark:hover:bg-bg-secondary-dark/80`,
        ghost: `hover:bg-bg-secondary-light dark:hover:bg-bg-secondary-dark`,
        spirit: `hover:bg-bg-tertiary-light dark:hover:bg-bg-tertiary-dark`,
        link: `text-textColors-background-light dark:text-textColors-background-dark underline-offset-4 hover:underline`,
        hoverLink: `text-textColors-secondary-light dark:text-textColors-secondary-dark hover:text-textColors-background-light dark:hover:text-textColors-background-dark`,
      },
      size: {
        default: "h-8 px-3 py-2",
        xs: "h-5 rounded-md text-xs",
        sm: "h-8 rounded-md px-3 text-xs",
        lg: "h-10 rounded-md px-8",
        icon: "h-8 w-8",
      },
      loading: {
        true: "gap-1 opacity-50",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      loading = false,
      children,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : "button";

    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className, loading }))}
        ref={ref}
        {...props}
      >
        {loading && (
          <Loader
            size={14}
            className={cn(
              "animate-spin transition-all duration-1200 shrink-0",
              variant === "default" || !variant
                ? "stroke-textColors-background-dark dark:stroke-textColors-background-dark"
                : "stroke-textColors-background-light dark:stroke-textColors-background-dark"
            )}
          />
        )}
        {children}
      </Comp>
    );
  }
);
Button.displayName = "Button";

const ButtonVariants = () => {
  return (
    <div className="flex flex-row gap-x-2 items-center">
      <Button variant="default">Default</Button>
      <Button variant="destructive">Destructive</Button>
      <Button variant="outline">Outline</Button>
      <Button variant="secondary">Secondary</Button>
      <Button variant="ghost">Ghost</Button>
      <Button variant="link">Link</Button>
      <Button variant="outline" size="icon">
        <ChevronRight size={16} />
      </Button>
    </div>
  );
};

export { Button, buttonVariants, ButtonVariants };
