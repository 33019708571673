import { useState } from "react";
import { GptIcon } from "../../../pages-v2/integrations/gpt.svg";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../ui-components/Accordion";
import { Button } from "../../../ui-components/Button";
import { Checkbox } from "../../../ui-components/Checkbox";
import { Input } from "../../../ui-components/Input";
import { Textarea } from "../../../ui-components/Textarea";
import { cn } from "../../../utils/utils";

export const AIResults = ({
  aiRevisionMarkers,
  onSubmit,
  onDelete,
}: {
  aiRevisionMarkers: { question: string; answer: string }[];
  onSubmit: (results: { question: string; answer: string }[]) => void;
  onDelete: () => void;
}) => {
  const [aiResults, setAiResults] = useState<
    { question: string; answer: string; checked: boolean }[]
  >(
    aiRevisionMarkers.map((result) => ({
      ...result,
      checked: false,
    }))
  );

  return (
    <div className="flex flex-col gap-4">
      <GptIcon />
      <Accordion
        type="single"
        collapsible
        className={cn(
          "border border-border-primary-light rounded-md last:[&>div]:border-0",
          "[&>div>h3>div>svg]:-mt-0.5 [&>div>h3>div>svg]:cursor-pointer"
        )}
      >
        {aiResults.map(({ question, answer }, index) => {
          return (
            <AIResult
              key={index}
              index={index}
              question={question}
              answer={answer}
              onQuestionChange={(question, idx) => {
                setAiResults(
                  aiResults.map((result, i) =>
                    i === idx ? { ...result, question } : result
                  )
                );
              }}
              onAnswerChange={(answer, idx) => {
                setAiResults(
                  aiResults.map((result, i) =>
                    i === idx ? { ...result, answer } : result
                  )
                );
              }}
              onCheckedChange={(checked, index) => {
                setAiResults(
                  aiResults.map((result, i) =>
                    i === index ? { ...result, checked } : result
                  )
                );
              }}
              aiResults={aiResults}
            />
          );
        })}
      </Accordion>
      <div className="flex items-center justify-end gap-4 mt-4">
        <Button onClick={onDelete} variant="outline">
          Delete
        </Button>
        <Button
          onClick={() => {
            const selectedResults = aiResults.filter(
              (result) => result.checked
            );

            onSubmit(selectedResults);
          }}
        >
          Add selected
        </Button>
      </div>
    </div>
  );
};

export const AIResult = ({
  question,
  answer,
  onQuestionChange,
  onAnswerChange,
  onCheckedChange,
  aiResults,
  index,
}: {
  question: string;
  answer: string;
  onQuestionChange: (question: string, index: number) => void;
  onAnswerChange: (answer: string, index: number) => void;
  onCheckedChange: (checked: boolean, index: number) => void;
  aiResults: { question: string; answer: string }[];
  index: number;
}) => {
  return (
    <AccordionItem value={question} className="px-4">
      <AccordionTrigger asChild>
        <div className="flex items-start gap-2">
          <Checkbox
            id={question}
            onClick={(e) => e.stopPropagation()}
            onCheckedChange={(checked) => {
              if (checked) {
                onCheckedChange(true, index);
              } else {
                onCheckedChange(false, index);
              }
            }}
            className="mt-0.5"
          />
          <Input
            value={question}
            onChange={(e) => onQuestionChange(e.target.value, index)}
            className="truncate text-textColors-secondary-light p-0 -mt-2 dark:text-textColors-secondary-dark border-0 shadow-none focus:shadow-none focus:ring-0 focus-visible:shadow-none focus-visible:ring-0"
            onClick={(e) => e.stopPropagation()}
          />
        </div>
      </AccordionTrigger>
      <AccordionContent>
        <Textarea
          value={answer}
          onChange={(e) => onAnswerChange(e.target.value, index)}
          className="flex items-start w-96 ml-6 flex-wrap gap-1 text-textColors-secondary-light p-0 dark:text-textColors-secondary-dark border-0 shadow-none focus:shadow-none focus:ring-0 focus-visible:shadow-none focus-visible:ring-0"
          onClick={(e) => e.stopPropagation()}
        />
      </AccordionContent>
    </AccordionItem>
  );
};
