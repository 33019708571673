import { Database } from "../../utils/database.types";
import supabase from "../../utils/supabase";

export async function createIntegration({
  metadata,
  user_id,
  secret,
  name,
}: Partial<Database["public"]["Tables"]["connected-integrations"]["Row"]>) {
  const { data, error } = await supabase
    .from("connected-integrations")
    .insert({
      metadata,
      user_id,
      secret,
      name,
    })
    .select();

  if (error) {
    throw new Error(error.message);
  }

  return data;
}
