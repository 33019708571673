import dayjs from "dayjs";
import matter from "gray-matter";
import { useEffect, useMemo, useState } from "react";

declare module NodeJS {
  interface Require {
    context(...args: any): any;
  }
}

declare const require: NodeJS.Require;

const postsCtx = require.context("../content", false, /\.md$/);
const postsPaths = postsCtx.keys().map(postsCtx);

export const usePosts = () => {
  const [posts, setPosts] = useState<
    {
      frontmatter:
        | {
            date: string;
            slug: string;
            tag: string;
            title: string;
          }
        | Record<string, any>;
      content: string;
    }[]
  >([]);

  useEffect(() => {
    async function loadPosts() {
      const posts = await Promise.all(
        postsPaths.map((postFilePath: string) => {
          return fetch(postFilePath)
            .then((res) => res.text())
            .then((data) => data)
            .catch((err) => []);
        })
      );

      const parsedPosts = posts
        .map((fileContent) => {
          const { data: frontmatter, content } = matter(fileContent); // Parse frontmatter and markdown content
          return { frontmatter, content };
        })
        .sort((a, b) =>
          dayjs(a.frontmatter.date).isBefore(dayjs(b.frontmatter.date)) ? 1 : -1
        );

      setPosts(parsedPosts);
    }

    loadPosts();
  }, []);

  return useMemo(
    () => ({
      posts,
      loading: posts.length === 0,
    }),
    [posts]
  );
};
