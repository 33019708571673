import dayjs from "dayjs";
import { ArrowLeft, Check, Inbox, Link as LinkIcon, Plus } from "lucide-react";
import { useCallback, useMemo } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { noteIconsMap } from "../components/visual/NoteIconSelect";
import { useFetchFollowers } from "../hooks/queries/useFetchFollowers";
import { useFetchFollowing } from "../hooks/queries/useFetchFollowing";
import {
  FollowRelationType,
  useFetchFollowRelation,
} from "../hooks/queries/useFetchFollowRelation";
import { Layout } from "../pages-v2";
import {
  TNotesResponseWithChildren,
  useNotesList,
} from "../pages-v2/hooks/queries/useNotesList";
import { useUpdateFollow } from "../pages-v2/hooks/queries/useUpdateFollow";
import { useUser } from "../pages-v2/hooks/queries/useUser";
import { BASE_URL } from "../pages-v2/NotesDashboardWrapper";
import { useAuthStore } from "../store/auth";
import { BlockItem } from "../ui-components/BlockItem";
import { Button } from "../ui-components/Button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogOverlay,
  DialogTitle,
  DialogTrigger,
} from "../ui-components/Dialog";
import { DropdownMenuSeparator } from "../ui-components/DropdownMenu";
import { LucideIcon } from "../ui-components/LucideIcon";
import { AvatarsMap } from "../utils/constants";
import { getAvatarIcon } from "../utils/helpers";
import { DAYJS_MINI_FORMAT } from "../utils/utils";

export const UserProfile = () => {
  const { userId } = useParams<{ userId: string }>();
  const { userId: currentUserId } = useAuthStore();

  const isCurrentUser = userId === currentUserId.replaceAll("-", "");

  const { data: [user] = [], isLoading: isLoadingUser } = useUser({
    userId: userId,
  });
  const router = useHistory();

  if (isLoadingUser) return <UserProfileLoader />;

  return (
    <Layout>
      <div className="flex max-w-[32rem] flex-col gap-y-4 mx-auto p-8 pb-24 lg:mt-16 mt-6 rounded-xl border border-border-secondary-light dark:border-border-secondary-dark bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark text-textColors-background-light dark:text-textColors-background-dark shadow">
        <div className="flex flex-col gap-1">
          <Button
            variant="link"
            size="icon"
            className="justify-start"
            onClick={() => router.goBack()}
          >
            <LucideIcon
              icon={ArrowLeft}
              className="stroke-text-textColors-muted-light dark:stroke-text-textColors-muted-dark"
            />
          </Button>
          <div className="flex flex-row gap-x-2 text-xl items-center">
            {getAvatarIcon({
              className: "w-10 h-10",
              avatar: user.avatar as keyof typeof AvatarsMap,
            })}
            <div className="flex flex-row items-center gap-x-4 justify-between w-full">
              <div className="flex flex-col">
                {user.name}
                <span className="text-textColors-muted-light dark:text-textColors-muted-dark text-sm">
                  @{user.username}
                </span>
              </div>
              {Boolean(user.url) && (
                <span className="text-textColors-muted-light dark:text-textColors-muted-dark">
                  <a href={user.url} target="_blank" rel="noreferrer">
                    <LucideIcon icon={LinkIcon} size={14} className="-mt-1" />
                  </a>
                </span>
              )}
            </div>
          </div>
        </div>
        {isCurrentUser ? (
          <div className="flex flex-row gap-6">
            <UserSocials userId={userId} />
          </div>
        ) : (
          <div className="flex flex-row">
            <Follow currentUserId={currentUserId} userId={userId} />
          </div>
        )}
        {user.bio && (
          <p className="text-textColors-muted-light dark:text-textColors-muted-dark">
            {user.bio}
          </p>
        )}
        <DropdownMenuSeparator className="-mx-8" />
        <div className="font-medium flex flex-col gap-y-2">
          <PublishedNotes userId={userId} />
        </div>
      </div>
    </Layout>
  );
};

const Follow = ({
  currentUserId,
  userId,
}: {
  currentUserId: string;
  userId: string;
}) => {
  const { data: follow, isLoading: isFollowLoading } = useFetchFollowRelation({
    sourceUser: currentUserId,
    targetUser: userId,
    relationType: FollowRelationType.FOLLOWER,
  });

  const { mutateAsync: addFollow, isLoading: isAddFollowLoading } =
    useUpdateFollow({
      sourceUserId: userId,
      targetUserId: currentUserId,
      relationType: "add",
    });

  const { mutateAsync: removeFollow, isLoading: isRemoveFollowLoading } =
    useUpdateFollow({
      sourceUserId: userId,
      targetUserId: currentUserId,
      relationType: "remove",
    });

  if (isFollowLoading) {
    return null;
  }

  if (Boolean(follow?.count)) {
    return (
      <Button
        className="gap-2"
        variant="outline"
        loading={isAddFollowLoading || isRemoveFollowLoading}
        onClick={async () => {
          await removeFollow();
        }}
      >
        {!(isAddFollowLoading || isRemoveFollowLoading) && (
          <Check
            size={16}
            className="stroke-backgroundColors-background-light dark:stroke-backgroundColors-background-light"
          />
        )}
        Following
      </Button>
    );
  }

  return (
    <Button
      className="gap-2"
      variant="outline"
      loading={isAddFollowLoading || isRemoveFollowLoading}
      onClick={async () => {
        await addFollow();
      }}
    >
      {!(isAddFollowLoading || isRemoveFollowLoading) && (
        <Plus
          size={16}
          className="stroke-text-textColors-background-dark dark:stroke-text-textColors-background-light"
        />
      )}
      Follow
    </Button>
  );
};

const UserSocials = ({ userId }: { userId: string }) => {
  return (
    <div className="flex flex-row items-center gap-4">
      {/* Dialog */}
      <FollowersList userId={userId} />

      <FollowingList userId={userId} />
    </div>
  );
};

const PublishedNotes = ({ userId }: { userId: string }) => {
  const { data: allNotes = [], isLoading: isLoadingNotesList } = useNotesList({
    userId,
  });

  if (isLoadingNotesList) {
    return <PublishedNotesLoader />;
  }

  const publishedNotes = allNotes.filter(
    (note) => note.template !== "snippet" && note.status === "published"
  );

  if (!publishedNotes.length) {
    return (
      <div className="flex flex-col gap-y-4 mt-8 justify-center items-center">
        <LucideIcon icon={Inbox} className="size-12 stroke-1" />
        <div className="leading-6 text-textColors-muted-light dark:text-textColors-muted-dark text-sm font-normal flex items-center gap-1">
          No published notes
        </div>
      </div>
    );
  }

  const getPublishedNotesList = ({
    notesList,
  }: {
    notesList: TNotesResponseWithChildren[];
  }) => (
    <>
      {notesList.map((note) => {
        if (
          note.is_content_note ||
          note.template === "snippet" ||
          note.status !== "published"
        )
          return null;
        return (
          <div key={note.id} className="flex flex-row items-start gap-x-2">
            <LucideIcon
              icon={noteIconsMap[note.icon]}
              size={18}
              className="mt-1"
            />
            <div className="flex flex-col">
              <Link to={`${BASE_URL}/notes/${note.id}`}>
                <div className="flex flex-row items-center gap-x-1 group">
                  <span className="font-medium text-textColors-secondary-light dark:text-textColors-secondary-dark group-hover:text-textColors-background-light dark:group-hover:text-textColors-background-dark">
                    {note.title}
                  </span>
                </div>
              </Link>
              <span className="text-textColors-muted-light dark:text-textColors-muted-dark text-xs tracking-tight">
                {dayjs(note.created_at).format(DAYJS_MINI_FORMAT)}
              </span>
            </div>
          </div>
        );
      })}
    </>
  );

  const previewNotesList = publishedNotes.slice(0, 3);

  return (
    <div className="flex flex-col gap-y-4">
      {getPublishedNotesList({ notesList: previewNotesList })}

      {previewNotesList.length > 3 && (
        <Dialog>
          <DialogTrigger asChild>
            <Button
              variant="link"
              className="justify-start w-fit p-0 text-textColors-muted-light dark:text-textColors-muted-dark"
            >
              View more
            </Button>
          </DialogTrigger>
          <DialogOverlay />
          <DialogContent className="pr-0">
            <DialogHeader>
              <DialogTitle>Published Notes</DialogTitle>
            </DialogHeader>
            <div className="h-96 overflow-auto flex flex-col gap-4">
              {getPublishedNotesList({ notesList: publishedNotes })}
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

const PublishedNotesLoader = () => {
  return (
    <div className="flex flex-col gap-y-4">
      {Array(3)
        .fill(0)
        .map((_, idx) => {
          return (
            <div key={idx} className="flex flex-row items-center gap-x-2">
              <div className="bg-bg-tertiary-light dark:bg-bg-tertiary-dark h-8 w-8 rounded-sm"></div>
              <div className="flex flex-col w-full gap-y-2">
                <div className="bg-bg-tertiary-light dark:bg-bg-tertiary-dark h-4 w-2/5 rounded-sm"></div>
                <div className="bg-bg-tertiary-light dark:bg-bg-tertiary-dark h-4 w-1/3 rounded-sm"></div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

const UserProfileLoader = () => {
  return (
    <Layout>
      <div className="flex max-w-[32rem] flex-col gap-y-4 mx-auto p-8 border border-border-secondary-light dark:border-border-secondary-dark rounded-sm lg:mt-16 mt-6">
        <div className="flex flex-row gap-x-2 text-xl">
          <span className="text-textColors-muted-light dark:text-textColors-muted-dark">
            <div className="bg-bg-tertiary-light dark:bg-bg-tertiary-dark w-5 h-5 rounded-full"></div>
          </span>
          <div className="bg-bg-tertiary-light dark:bg-bg-tertiary-dark h-5 w-2/5 rounded-sm"></div>
        </div>
        <div className="bg-bg-tertiary-light dark:bg-bg-tertiary-dark h-5 w-full rounded-sm"></div>
        <div className="bg-bg-tertiary-light dark:bg-bg-tertiary-dark h-5 w-full rounded-sm"></div>
      </div>
    </Layout>
  );
};

const FollowersList = ({ userId }: { userId: string }) => {
  const { data: followers, isLoading: isLoadingFollowers } =
    useFetchFollowers(userId);
  const router = useHistory();

  const followerUsersData = useMemo(
    () => followers?.data?.map((follower) => follower.users),
    [followers]
  );

  const handleNavigateToUser = useCallback(
    (userId: string) => {
      router.push(`/user/${userId.replaceAll("-", "")}`);
    },
    [router]
  );

  if (!followerUsersData?.length) {
    return null;
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline" size="sm" loading={isLoadingFollowers}>
          <span>{followers?.count} followers</span>
        </Button>
      </DialogTrigger>
      <DialogOverlay />
      <DialogContent>
        <DialogHeader className="px-4">
          <DialogTitle>Followers</DialogTitle>
          <p className="text-textColors-muted-light dark:text-text-textColors-muted-light">
            List of users that follow you
          </p>
        </DialogHeader>
        <div className="h-56 overflow-auto">
          {isLoadingFollowers && (
            <div className="flex flex-col gap-y-4 animate-pulse">
              {Array(4)
                .fill(0)
                .map((_, i) => {
                  return (
                    <div
                      key={i}
                      className="h-4 bg-bg-tertiary-light dark:bg-bg-tertiary-dark w-full rounded-sm"
                    ></div>
                  );
                })}
            </div>
          )}
          {!followerUsersData?.length && !isLoadingFollowers && (
            <div className="text-textColors-muted-light dark:text-textColors-muted-dark">
              No users found
            </div>
          )}
          {followerUsersData?.length && !isLoadingFollowers && (
            <div className="flex flex-col gap-y-2">
              {followerUsersData.map((follower: any) => {
                return (
                  <BlockItem
                    key={follower.id}
                    onClick={() => handleNavigateToUser(follower.id)}
                    className="w-auto justify-between group"
                  >
                    <div className="gap-2 flex flex-row items-center">
                      {getAvatarIcon({
                        className: "w-7 h-7 ring-2",
                        avatar: follower?.avatar as keyof typeof AvatarsMap,
                      })}
                      <span>{follower.name}</span>
                    </div>
                  </BlockItem>
                );
              })}
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

const FollowingList = ({ userId }: { userId: string }) => {
  const { data: following, isLoading: isLoadingFollowing } =
    useFetchFollowing(userId);
  const router = useHistory();

  const followingUsersData = useMemo(
    () => following?.data?.map((following) => following.users),
    [following]
  );

  const handleNavigateToUser = useCallback(
    (userId: string) => {
      router.push(`/user/${userId.replaceAll("-", "")}`);
    },
    [router]
  );

  if (!followingUsersData?.length) {
    return null;
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          variant="outline"
          className="gap-2"
          size="sm"
          loading={isLoadingFollowing}
        >
          <span>{following?.count} following</span>
        </Button>
      </DialogTrigger>
      <DialogOverlay />
      <DialogContent>
        <DialogHeader className="px-4">
          <DialogTitle>Following</DialogTitle>
          <p className="text-textColors-muted-light dark:text-text-textColors-muted-light">
            List of users you follow
          </p>
        </DialogHeader>
        <div className="h-56 overflow-auto">
          {isLoadingFollowing && (
            <div className="flex flex-col gap-y-4 animate-pulse">
              {Array(4)
                .fill(0)
                .map((_, i) => {
                  return (
                    <div
                      key={i}
                      className="h-4 bg-bg-tertiary-light dark:bg-bg-tertiary-dark w-full rounded-sm"
                    ></div>
                  );
                })}
            </div>
          )}
          {!followingUsersData?.length && !isLoadingFollowing && (
            <div className="text-textColors-muted-light dark:text-textColors-muted-dark">
              No users found
            </div>
          )}
          {followingUsersData?.length && !isLoadingFollowing && (
            <div className="flex flex-col gap-y-2">
              {followingUsersData.map((follower: any) => {
                return (
                  <BlockItem
                    key={follower.id}
                    onClick={() => handleNavigateToUser(follower.id)}
                    className="w-auto justify-between group transition-all duration-100 ease-in-out"
                  >
                    <div className="gap-2 flex flex-row items-center">
                      {getAvatarIcon({
                        className: "w-7 h-7 ring-2",
                        avatar: follower?.avatar as keyof typeof AvatarsMap,
                      })}
                      <span>{follower.name}</span>
                    </div>
                  </BlockItem>
                );
              })}
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
