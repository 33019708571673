export const ConfidenceSvg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 125"
      enable-background="new 0 0 100 100"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M33.7973633,16.4541016h32.4052734v2.6875  c0,1.4785156-1.2089844,2.6875-2.6875,2.6875H62.050293c0,18.7802734,0,37.5615234,0,56.3417969h1.4648438  c1.4785156,0,2.6875,1.2089844,2.6875,2.6865234v2.6875H33.7973633v-2.6875c0-1.4775391,1.2089844-2.6865234,2.6875-2.6865234  h1.4648438c0-18.7802734,0-37.5615234,0-56.3417969h-1.4648438c-1.4785156,0-2.6875-1.2089844-2.6875-2.6875V16.4541016z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.5454102,9.3310547h42.9091797v2.6875  c0,1.4775391-1.2089844,2.6865234-2.6865234,2.6865234H31.2329102c-1.4785156,0-2.6875-1.2089844-2.6875-2.6865234V9.3310547z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.5454102,90.6689453h42.9091797v-2.6875  c0-1.4775391-1.2089844-2.6865234-2.6865234-2.6865234H31.2329102c-1.4785156,0-2.6875,1.2089844-2.6875,2.6865234V90.6689453z"
      />
    </svg>
  );
};
