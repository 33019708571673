import { useQueryClient } from "react-query";
import { toast } from "sonner";
import { useAuthStore } from "../../store/auth";
import { Button } from "../../ui-components/Button";
import { Database } from "../../utils/database.types";
import { AIProviders } from "../hooks/ai/useFetchAiCompletions";
import { useCreateIntegration } from "../hooks/mutations/useCreateIntegration";
import { useUpdateIntegration } from "../hooks/mutations/useUpdateIntegration";
import { getIntegrationsQueryKey } from "../hooks/query-keys";
import { GptIntegrationForm } from "./forms/GptIntegrationForm";
import { MistralIntegrationForm } from "./forms/MistralIntegrationForm";
import { integrationsMap } from "./Integrations";

export const IntegrationPreview = ({
  integration,
  onClose,
}: {
  integration: Omit<
    Database["public"]["Tables"]["connected-integrations"]["Row"],
    "created_at"
  >;
  onClose: () => void;
}) => {
  const queryClient = useQueryClient();
  const { userId } = useAuthStore();
  const { mutate: createIntegration, isLoading: isCreatingIntegration } =
    useCreateIntegration();
  const { mutate: updateIntegration, isLoading: isUpdatingIntegration } =
    useUpdateIntegration();

  const integrationMeta = integrationsMap.find(
    (intg) => intg.id === integration.metadata?.integrationType
  );

  const isUpdate = Boolean(integration.id);

  if (!integration) return null;

  if (integration.metadata?.integrationType === AIProviders.openai) {
    return (
      <div className="flex flex-col gap-y-4 h-full">
        <div className="flex flex-row items-center gap-x-1">
          <div className="flex flex-col gap-2">
            <Button
              variant="outline"
              size="icon"
              className="pointer-events-none"
            >
              {integrationMeta.icon}
            </Button>
            <div className="text-lg font-medium flex flex-row gap-1 items-center">
              {integration.name || integrationMeta.label}
            </div>
            <div className="text-textColors-muted-light dark:text-textColors-muted-dark text-sm pr-6 mt-2">
              {integrationMeta.description}
            </div>
          </div>
        </div>
        <div className="text-textColors-muted-light dark:text-textColors-muted-dark text-sm mr-4">
          <a
            href="https://platform.openai.com/docs/api-reference/authentication"
            target="_blank"
            rel="noreferrer"
            className="underline dark:text-textColors-muted-dark hover:text-textColors-muted-light dark:hover:text-textColors-muted-dark underline-offset-2 cursor-ne-resize"
          >
            API reference
          </a>
        </div>
        <GptIntegrationForm
          defaultValues={{
            apiKey: integration.secret,
            model: (integration.metadata as any)?.model,
            name: integration.name,
          }}
          isUpdate={isUpdate}
          isLoading={isCreatingIntegration || isUpdatingIntegration}
          onSubmit={({ integrationType, metadata, name }) => {
            const payload = {
              metadata: {
                integrationType,
                model: metadata?.model,
              } as any,
              secret: metadata.apiKey,
              name,
            };
            if (isUpdate) {
              updateIntegration(
                { ...payload, id: integration.id },
                {
                  onSuccess: () => {
                    toast.success("Integration updated successfully");
                    onClose();

                    queryClient.invalidateQueries(
                      getIntegrationsQueryKey({
                        userId,
                      })
                    );
                  },
                }
              );
            } else {
              createIntegration(payload, {
                onSuccess: () => {
                  toast.success("Integration created successfully");
                  onClose();

                  queryClient.invalidateQueries(
                    getIntegrationsQueryKey({
                      userId,
                    })
                  );
                },
              });
            }
          }}
        />
      </div>
    );
  }

  if (integration.metadata?.integrationType === AIProviders.mistral) {
    if (!integrationMeta) return null;

    return (
      <div className="flex flex-col gap-y-4 h-full">
        <div className="flex flex-row items-center gap-x-1">
          <div className="flex flex-col gap-2">
            <Button
              variant="outline"
              size="icon"
              className="pointer-events-none"
            >
              {integrationMeta.icon}
            </Button>
            <div className="text-lg font-medium flex flex-row gap-1 items-center">
              {integration.name || integrationMeta.label}
            </div>
            <div className="text-textColors-muted-light dark:text-textColors-muted-dark text-sm pr-6 mt-2">
              {integrationMeta.description}
            </div>
          </div>
        </div>
        <div className="text-textColors-muted-light dark:text-textColors-muted-dark text-sm mr-4">
          <a
            href="https://docs.mistral.ai/api/"
            target="_blank"
            rel="noreferrer"
            className="underline dark:text-textColors-muted-dark hover:text-textColors-muted-light dark:hover:text-textColors-muted-dark underline-offset-2 cursor-ne-resize"
          >
            API reference
          </a>
        </div>
        <MistralIntegrationForm
          defaultValues={{
            apiKey: integration.secret,
            model: (integration.metadata as any)?.model,
            name: integration.name,
          }}
          isLoading={isCreatingIntegration || isUpdatingIntegration}
          isUpdate={isUpdate}
          onSubmit={({ integrationType, metadata, name }) => {
            const payload = {
              metadata: {
                integrationType,
                model: metadata?.model,
              } as any,
              secret: metadata.apiKey,
              name,
            };
            if (isUpdate) {
              updateIntegration(
                { ...payload, id: integration.id },
                {
                  onSuccess: () => {
                    toast.success("Integration updated successfully");
                    onClose();

                    queryClient.invalidateQueries(
                      getIntegrationsQueryKey({
                        userId,
                      })
                    );
                  },
                }
              );
            } else {
              createIntegration(payload, {
                onSuccess: () => {
                  toast.success("Integration created successfully");
                  onClose();

                  queryClient.invalidateQueries(
                    getIntegrationsQueryKey({
                      userId,
                    })
                  );
                },
              });
            }
          }}
        />
      </div>
    );
  }

  return null;
};
