import { Archive, Combine, ListTree, Star } from "lucide-react";
import { useHotkeys } from "react-hotkeys-hook";
import { useHistory, useLocation } from "react-router-dom";
import { useAuthStore } from "../../store/auth";
import { BlockItem } from "../../ui-components/BlockItem";
import { Chip } from "../../ui-components/Chip";
import { DropdownMenuSeparator } from "../../ui-components/DropdownMenu";
import { APP_PAGE_HEIGHT } from "../../utils/constants";
import {
  transformHeirarchicalNotesInFlatList,
  useNotesList,
} from "../hooks/queries/useNotesList";
import { BASE_URL } from "../NotesDashboardWrapper";
import { NotesList } from "./NotesList";
import { constructUrlForNote } from "./services";

export const NotesSidebar = ({ onSelect }: { onSelect?: () => void }) => {
  const location = useLocation();
  const router = useHistory();
  const { userId } = useAuthStore();

  const { data: notes = [], isLoading: isLoadingNotes } = useNotesList({
    userId,
  });

  const archivedNotes = transformHeirarchicalNotesInFlatList({
    notesResponse: notes,
  }).filter((note) => note.status === "archived");

  useHotkeys("F1", () => {
    const firstNote = notes?.[0];

    if (Boolean(firstNote)) {
      router.push(`${constructUrlForNote({ note: firstNote })}`);
    }
  });

  return (
    <div
      className={`bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark ${APP_PAGE_HEIGHT} relative flex flex-col`}
    >
      <div className="flex flex-col gap-y-0.5 p-1">
        <BlockItem
          variant={
            location.pathname.includes("favorites") ? "active" : "default"
          }
          onClick={() => {
            router.push(`${BASE_URL}/favorites`);
            onSelect && onSelect();
          }}
          className="px-3"
        >
          <Star size={16} />
          Favorites
        </BlockItem>
        <BlockItem
          variant={
            location.pathname.includes("archived") ? "active" : "default"
          }
          onClick={() => {
            router.push(`${BASE_URL}/archived`);
            onSelect && onSelect();
          }}
          className="px-3 gap-x-6 relative"
        >
          <div className="flex flex-row items-center gap-x-1">
            <Archive size={16} />
            Archived
          </div>
          {isLoadingNotes ? (
            <div className="absolute left-28 text-xs top-1.5 bg-[#EBEBEB] animate-pulse w-6 h-5 rounded"></div>
          ) : (
            <Chip className="absolute px-1.5 py-0.5 rounded-md left-28 text-xs">
              {archivedNotes.length}
            </Chip>
          )}
        </BlockItem>
        <BlockItem
          variant={
            location.pathname.includes("snippets") ? "active" : "default"
          }
          onClick={() => {
            router.push(`${BASE_URL}/snippets`);
            onSelect && onSelect();
          }}
          className="px-3"
        >
          <ListTree size={16} />
          Snippets
        </BlockItem>
        <BlockItem
          variant={
            location.pathname.includes("integrations") ? "active" : "default"
          }
          onClick={() => {
            router.push(`${BASE_URL}/integrations`);
            onSelect && onSelect();
          }}
          className="px-3"
        >
          <Combine size={16} />
          Integrations
        </BlockItem>
      </div>
      <DropdownMenuSeparator className="bg-border-secondary-light dark:border-secondary-dark my-2" />
      <div className="h-full flex flex-col justify-between">
        <NotesList onSelect={onSelect} />
      </div>
    </div>
  );
};
