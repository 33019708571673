import { MailWarning } from "lucide-react";
import { useHistory, useLocation } from "react-router-dom";
import { RootLayout } from "../../layouts/Root.layout";
import { Layout } from "../../pages-v2";
import { Button } from "../../ui-components/Button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../ui-components/Card";
import { DropdownMenuSeparator } from "../../ui-components/DropdownMenu";

const EmailVerification = () => {
  const location = useLocation();
  const history = useHistory();

  if (!location.state) {
    return (
      <RootLayout>
        <div className="h-full grow grid place-items-center">
          <Card className="w-96 text-center">
            <CardHeader>
              <CardTitle className="flex flex-row gap-2 items-center justify-center">
                <MailWarning size={16} />
                <span>Could not verify email</span>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div>
                We were unable to verify your email due to an unexpected error,
                please try again later.
              </div>
              <DropdownMenuSeparator className="my-4" />
              <CardFooter className="justify-center pb-0">
                <Button onClick={() => history.push("/")}>Continue</Button>
              </CardFooter>
            </CardContent>
          </Card>
        </div>
      </RootLayout>
    );
  }

  return (
    <Layout>
      <div className="h-full grid place-items-center">
        <Card className="w-[28rem] text-center">
          <CardContent className="pt-6 text-left">
            <p className="text-lg">Verify email</p>
            <p className="mt-1 text-textColors-muted-light dark:text-textColors-muted-dark text-sm leading-6">
              We just sent an email to your inbox. Click the link in the email
              to verify your account.
            </p>
            <CardFooter className="pb-0 mt-12 pl-0">
              <Button variant="outline" onClick={() => history.push("/")}>
                Back to home
              </Button>
            </CardFooter>
          </CardContent>
        </Card>
      </div>
    </Layout>
  );
};

export default EmailVerification;
