import { Editor } from "@tiptap/react";
import { ChevronDown, Plus, Triangle } from "lucide-react";
import { FC, MutableRefObject, useState } from "react";
import { TodoIcon } from "../../../assets/svg/todo.svg";
import { SelectedAiInstance } from "../../../pages-v2/hooks/ai/types";
import { Button } from "../../../ui-components/Button";
import { LucideIcon } from "../../../ui-components/LucideIcon";
import {
  Popover,
  PopoverContentWithoutPortal,
  PopoverTrigger,
} from "../../../ui-components/Popover";
import { Separator } from "../../../ui-components/Separator";
import { cn } from "../../../utils/utils";
import { SelectionMenuType } from "../BubbleMenu";
import { AiRevisionOptions } from "./AiRevisionOptions";

interface RevisionPopoverProps {
  editor: Editor;
  containerRef: MutableRefObject<HTMLElement>;
  setDialog: (dialog: "create" | "preview" | "ai" | null) => void;
  setSelectionType: (type: SelectionMenuType) => void;
  onClose: () => void;
  onSelectAiInstance: (selectedAiInstance: SelectedAiInstance) => void;
}

export const RevisionPopover: FC<RevisionPopoverProps> = ({
  editor,
  containerRef,
  setDialog,
  setSelectionType,
  onClose,
  onSelectAiInstance,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Popover open={isOpen} onOpenChange={setIsOpen} modal>
        <PopoverTrigger asChild>
          <Button variant="ghost" className="gap-1">
            Reflect
            <ChevronDown
              className={cn(
                "h-4 w-4 transition-all duration-400",
                isOpen ? "rotate-180" : ""
              )}
            />
          </Button>
        </PopoverTrigger>
        <PopoverContentWithoutPortal
          align="end"
          className="duration-400 z-[99999] my-1 flex max-h-80 w-64 flex-col overflow-hidden overflow-y-auto rounded border border-border-primary-light dark:border-border-primary-dark bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark p-1 shadow-xl animate-in fade-in slide-in-from-top-1"
          alignOffset={-8}
          containerRef={containerRef.current}
          onOpenAutoFocus={(e) => e.preventDefault()}
          onInteractOutside={() => {
            editor.chain().focus().setTextSelection(-1);
          }}
        >
          <div className="flex flex-col gap-0.5">
            <div
              className="px-3 py-2 text-sm flex flex-row items-center gap-2"
              onClick={(e) => {
                editor.chain().focus().setTextSelection(-1);
              }}
            >
              <TodoIcon className="size-4 ease-out stroke-[3]" />
              <LucideIcon
                icon={Triangle}
                className="rotate-180 fill-textColors-muted-light dark:fill-textColors-muted-dark stroke-textColors-muted-light dark:stroke-textColors-muted-dark"
                size={8}
              />
            </div>
            <Separator />
            <div className="w-full [&>*]:w-full">
              <Button
                className="flex items-center gap-2 justify-start"
                variant="ghost"
                type="button"
                onClick={() => {
                  editor.chain().focus().setTextSelection(-1);
                  setDialog("create");
                  setSelectionType("reviseMark");
                }}
              >
                <div className="size-5">
                  <LucideIcon icon={Plus} className="size-3 stroke-[2.5px]" />
                </div>
                Add
              </Button>
              {/* <Button
                className="flex items-center gap-2 justify-between"
                variant="ghost"
                onClick={() => {
                  onClose();
                  setDialog("ai");
                  setSelectionType("reviseMark");
                }}
              >
                Generate
                <AiIcon className="size-3 fill-backgroundColors-background-dark dark:fill-backgroundColors-background-light" />
              </Button> */}
              <AiRevisionOptions
                onSelectAiInstance={(instance) => {
                  onClose();
                  setDialog("ai");
                  setSelectionType("reviseMark");

                  onSelectAiInstance(instance);
                }}
              />
            </div>
          </div>
        </PopoverContentWithoutPortal>
        {/* </div> */}
      </Popover>
    </>
  );
};
